import NiceModal from '@ebay/nice-modal-react';
import styled from '@emotion/styled';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { AutoAwesomeOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

import { useTranslate } from '../../../Hooks/useTranslate';
import { trackQuickSetupOpened } from '../../../tracking/quick-setup/global-events';
import { OnboardingDialog } from '../../onboarding/onboarding-dialog';
import { useOnboardingWidgetContext } from './onboarding-widget/use-onboarding-widget-context';

export const QuickSetupSection = () => {
  const flags = useFlags();
  const { t } = useTranslate('onboarding.quickSetup');

  const { items } = useOnboardingWidgetContext();

  const showQuickSetup = useMemo(
    () =>
      flags.featureOnboardingFlow &&
      items?.find((step) => step.key === 'experience')?.completed === false,
    [flags.featureOnboardingFlow, items]
  );

  const handleClick = () => {
    NiceModal.show(OnboardingDialog);
    trackQuickSetupOpened('click');
  };

  if (!showQuickSetup) return null;

  return (
    <Stack
      sx={{
        paddingTop: { xs: 2, md: 4 },
        paddingBottom: { xs: 2, md: 3 },
        paddingX: { xs: 3, md: 5 },
        backgroundColor: lightTheme.palette.action.a100,
        borderRadius: 2,
      }}
    >
      <Stack sx={{ flexDirection: { md: 'row' }, gap: { xs: 2, md: 9 } }}>
        <Stack gap={2} marginBottom={3}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Text variant="medium" fontSize="xlarge">
              {t('title')}
            </Text>
            <AutoAwesomeOutlined />
          </Stack>
          <Text>
            {t(
              'description',
              flags.featureAiSetupFlow
                ? 'onboarding.quickSetup'
                : 'onboarding.quickSetup.manual'
            )}
          </Text>
        </Stack>
        <StyledButton variant="primary" size="medium" onClick={handleClick}>
          {t('buttonLabel')}
        </StyledButton>
      </Stack>
    </Stack>
  );
};

const StyledButton = styled(Button)({
  alignSelf: 'flex-end',
});
