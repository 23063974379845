import { Skeleton, Stack, styled, TableBody, TableCell, TableRow } from '@mui/material';
import { InfoBox } from '../../../../../../../Components/InfoBox/InfoBox';
import useResponsive from '../../../../../../../Hooks/layout/useResponsive';
import { TranslateFunction, useTranslate } from '../../../../../../../Hooks/useTranslate';
import { useBookingDetailsContext } from '../../../../domain/use_booking_details_context';
import { StyledTable } from '../BookingDetailsSummaryTable';
import { Launch } from '@mui/icons-material';
import { Order } from '@holdbar-com/utils-types';
import { lightTheme } from '@holdbar-com/pixel';
import { useMemo } from 'react';

const LaunchIcon = styled(Launch)({
  
    cursor: 'pointer',
    color: lightTheme.palette.neutral.n400,
    fontSize: lightTheme.tokens.font.presets['small'].fontSize,
    transition: 'all 0.1s ease-in-out',
    '&:hover': { color: lightTheme.palette.neutral.n500 },
    marginLeft: lightTheme.tokens.spaces[1],
 
})

export const BookingDetailsSummaryDocuments = () => {
  const { t } = useTranslate('dialogs.booking');

  const documentRows: {key: string, value: (receipt: Order | undefined, t: TranslateFunction) => JSX.Element | null}[] = [
    {
      key: 'order.documents.invoice',
      value: (receipt, t) => {
        if(receipt?.metadata.accounting?.customerInvoiceUrl){
          return <a href={receipt?.metadata.accounting?.customerInvoiceUrl} target="_blank">{t('order.documents.openInvoice', { invoiceNumber: ''})}<LaunchIcon/></a>;
        }
        if(!receipt?.invoices?.length) {
          return null;
        }

        return <>{receipt?.invoices.map((invoice) => 
          (<a key={`invoice-${invoice.id}`} href='' target='_blank'>{t('order.documents.openInvoice', {invoiceNumber: invoice.invoiceNumber})}<LaunchIcon/></a>)).join(', ')}</>;
      }
    },

    {
      key: 'order.documents.receipt',
      value: (receipt, t) => {
        if(!receipt?.metadata.accounting?.customerReceiptUrl) return null;
        return (<a href={receipt?.metadata.accounting?.customerReceiptUrl} target="_blank">{t('order.documents.openReceipt')}<LaunchIcon/></a>);
      }
    },
    {
      key: 'order.documents.creditNotes',
      value: (receipt, t) => {
        if(!receipt?.creditNotes?.length) return null;

        if(receipt?.metadata?.accounting?.creditNoteUrl) return (<a href={receipt?.metadata.accounting?.creditNoteUrl} target="_blank">{t('order.documents.openCreditNote', {creditNoteNumber: ''})}<LaunchIcon/></a>)

        return (<a href={receipt?.metadata.accounting?.creditNoteUrl} target="_blank">{t('order.documents.openCreditNote', {creditNoteNumber: receipt.creditNotes[0].creditNoteNumber})}<LaunchIcon/></a>);
      }
    },
    {
      key: 'order.documents.holdbarFee',
      value: (receipt, t) => {
        if(!receipt?.metadata.accounting?.holdbarFeeUrl) return null;

        return (<a href={receipt?.metadata.accounting?.holdbarFeeUrl} target="_blank">{t('order.documents.openHoldbarFee')}<LaunchIcon/></a>);
      }
    }
  ]

  const { booking, receipt } = useBookingDetailsContext();
  const _documentRows = useMemo(() => {
    return documentRows.map(x => ({
      key: x.key,
      value: x.value(receipt.data, t)
    }))
  }, [receipt]);

  if (!booking) return null;

  if (!receipt.isLoading && receipt.error) {
    return null;
  }

  if(!_documentRows.some(x => x.value)) return null;

  return (
    <InfoBox title={t('sections.documents')}>
      <StyledTable>
        <TableBody>
          {_documentRows.map((row) => {
            const key = row.key;
            const value = receipt.isLoading ? (
              <Skeleton width={80} />
            ) : (
              row.value
            );
            if (!value) return null;

            return (
              <StyledTableRow
                key={key}
              >
                <TableCell
                  component="th"
                  scope="row"
                >
                  {t(key)}
                </TableCell>
                <StyledTableCell>
                  {value}
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </StyledTable>
    </InfoBox>
  );
};

const StyledTableRow = styled(TableRow)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 16,
});

const StyledTableCell = styled(TableCell)({
  textAlign: 'right',
  display: 'flex',
  justifyContent: 'flex-end',
  flexGrow: 1,
});
