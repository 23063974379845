import { CheckIn, Customer } from '@holdbar-com/utils-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as api from '../Api';
import { TShallowBooking } from '../Utils/eventHelpers';
import { IExperience, useExperience } from './useExperience';

type Localized = { [k: string]: string };

export interface TBooking {
  paymentId?: string;
  experienceHeadline?: string;
  variants?: { name: string | Localized; id: string }[];
  id: string;
  companyId: string;
  distributorCompanyId?: string;
  created: string;
  status: 'active' | 'cancelled' | 'moved' | 'unpaid' | 'checked-in';
  cancellationReason?: string;
  eventId: string;
  movedToEvent?: string;
  movedFromEvent?: string;
  experienceId: string;
  items: {
    [id: string]: number;
  };
  parentId?: string;
  internalNote?: string;
  startDateTime: string;
  endDateTime: string;
  customer: Customer;
  customDataInputs?: {
    guestName: string;
    inputs: { name: string; id: string; value: string }[];
  }[];
  channel?: string;
  source?: string;
  receiptId?: string;
  language?: string;
}

export type TCreateBookingPayload = {
  status: 'active' | 'cancelled' | 'unpaid';
  eventId: string;
  experienceId: string;
  shouldNotify?: boolean;
  items: {
    [id: string]: number;
  };
  internalNote?: string;
  startDateTime: string;
  endDateTime: string;
  language?: string;
  customer: {
    name: string;
    email: string;
    phone?: string;
    location?: {
      address: string;
      zipCode: string;
      city: string;
    };
    vatNumber?: string;
    companyName?: string;
  };
  channel: 'manual' | 'integration' | 'checkout' | 'widget';
  source: 'holdbar' | 'truestory' | string;
  metaData?: {
    skipBookingConfirmation: boolean;
  };
  customDataInputs?: {
    inputs: { name: string; value: string }[];
  }[];
  paymentMethod: string;
};

export function getLocalized(
  name: string | Localized | undefined | null,
  language: string
): string | undefined {
  if (typeof name === 'string') {
    return name;
  }
  if (name === undefined || name === null) {
    return undefined;
  }
  try {
    if (name[language]) {
      return name[language];
    }
    const [value] = Object.values(name);
    return value;
  } catch {
    return undefined;
  }
}

const getVariants = (experience: IExperience | undefined, language: string) => {
  return (
    experience?.price?.variants?.reduce<{ name: any; id: string }[]>(
      (acc, { addons, name, id }) => {
        return [
          ...acc,
          ...(addons ?? []).map((el) => ({
            name: getLocalized(el.name, language),
            id: el.id,
          })),
          { name: getLocalized(name, language), id },
        ];
      },
      []
    ) ?? []
  );
};

export const useBookings = (
  eventId?: string,
  bookingId?: string,
  byDate?: string,
  eventBookings: TShallowBooking[] = []
) => {
  const queryClient = useQueryClient();

  const { experiences } = useExperience();

  const { i18n } = useTranslation('translation');

  const BookingsQueryKey = ['bookings'];
  const BookingsForEventQueryKey = [eventId, 'bookings'];
  const BookingsByDateQueryKey = [byDate, 'bookings'];
  const SingleBookingQueryKey = ['booking', bookingId];

  const mapBooking = (booking: TBooking) => {
    const foundExperience =
      experiences.data?.find(
        (ex) => ex.ownerExperienceId === booking.experienceId
      ) ?? ({} as IExperience);
    return {
      ...booking,
      variants: getVariants(foundExperience, i18n.language),
      experienceHeadline: getLocalized(
        foundExperience?.headline,
        i18n.language
      ),
    };
  };

  const booking = useQuery<TBooking>(
    SingleBookingQueryKey,
    async () => {
      await queryClient.cancelQueries(SingleBookingQueryKey);
      return api.getBooking(bookingId as string);
    },
    {
      enabled: Boolean(bookingId),
    }
  );

  const enrichedBooking = useMemo(() => {
    if (booking.data) {
      const foundExperience =
        experiences.data?.find((ex) => ex.id === booking.data.experienceId) ??
        ({} as IExperience);
      return {
        ...booking.data,
        variants: getVariants(foundExperience, i18n.language),
        experienceHeadline: getLocalized(
          foundExperience?.headline,
          i18n.language
        ),
      };
    }
  }, [booking]);

  const bookingsForEvent = useQuery<TBooking[]>(
    BookingsForEventQueryKey,
    async () => {
      await queryClient.cancelQueries(BookingsForEventQueryKey);
      return await api.getBookings(eventId);
    },
    {
      enabled: Boolean(eventId) && !experiences.isLoading,
      select: (data) =>
        data.filter((el) => el.eventId === eventId).map(mapBooking),
    }
  );

  const bookingsByDate = useQuery<TBooking[]>(
    BookingsByDateQueryKey,
    async () => {
      await queryClient.cancelQueries(BookingsByDateQueryKey);
      const date = BookingsByDateQueryKey[0];
      if (!date) {
        return [];
      }

      return await api.getBookingsByDate(date);
    },
    {
      enabled: Boolean(byDate) && !experiences.isLoading,
      select: (data) => data.map(mapBooking),
    }
  );

  const cancelBooking = useMutation(
    (shouldRefund: boolean = false) =>
      api.cancelBooking(bookingId!, shouldRefund),
    {
      onMutate: async (shouldRefund) => {
        await queryClient.cancelQueries(BookingsQueryKey);

        const previous = queryClient.getQueryData<TBooking>(
          SingleBookingQueryKey
        );

        queryClient.setQueryData<TBooking[]>(BookingsQueryKey, (prev) => {
          return (
            prev?.map((el) => {
              return el.id === bookingId
                ? {
                    ...el,
                    status: 'cancelled',
                  }
                : el;
            }) ?? []
          );
        });

        queryClient.setQueryData<TBooking[]>(
          [previous?.eventId, 'bookings'],
          (prev) => {
            return (
              prev?.map((el) => {
                return el.id === bookingId
                  ? {
                      ...el,
                      status: 'cancelled',
                    }
                  : el;
              }) ?? []
            );
          }
        );

        queryClient.setQueryData<TBooking>(SingleBookingQueryKey, (prev) => {
          return {
            ...prev!,
            status: 'cancelled',
          };
        });

        return {
          previous,
          eventId: previous?.eventId,
          experienceId: previous?.experienceId,
        };
      },
      onError: (err, variables, context: any) => {
        if (context?.previous) {
          queryClient.setQueryData<TBooking>(
            SingleBookingQueryKey,
            context.previous
          );
        }
      },
      onSettled: async (data, err, variables, context: any) => {
        await queryClient.invalidateQueries(BookingsQueryKey);
        await queryClient.invalidateQueries('events');
        queryClient.invalidateQueries([context?.eventId, 'bookings']);
        queryClient.invalidateQueries([
          'events',
          'experience',
          context?.experienceId,
        ]);
        queryClient.invalidateQueries(['vouchers', 'bought']);
        queryClient.invalidateQueries(['search']);
        setTimeout(() => {
          queryClient.invalidateQueries([
            'audits',
            { object: 'Booking', objectId: bookingId },
          ]);
        }, 2000);
      },
    }
  );

  const updateBooking = useMutation(
    ({
      id,
      ...payload
    }: {
      id: string;
      internalNote?: string;
      paymentId?: string;
      eventId?: string;
      items?: TCreateBookingPayload['items'];
      shouldSendConfirmation?: boolean;
      language?: string;
      customer?: Customer;
    }) => {
      const existing = queryClient.getQueryData(['booking', id]);

      return api.updateBooking(id, { ...(existing ?? {}), ...payload });
    },
    {
      onMutate: async ({ id, eventId, ...payload }) => {
        const singleQueryKey = ['booking', id];

        await queryClient.cancelQueries(BookingsQueryKey);

        const previous = queryClient.getQueryData<TBooking>(singleQueryKey);

        queryClient.setQueryData<TBooking[]>(BookingsQueryKey, (prev) => {
          return (
            prev?.map((el) => {
              return el.id === id ? { ...el, ...payload } : el;
            }) ?? []
          );
        });

        queryClient.setQueryData<TBooking[]>([eventId, 'bookings'], (prev) => {
          return (
            prev?.map((el) => {
              return el.id === id ? { ...el, ...payload } : el;
            }) ?? []
          );
        });

        queryClient.setQueryData<TBooking>(singleQueryKey, (prev) => {
          return { ...prev!, ...payload };
        });

        return { previous, eventId, experienceId: previous?.experienceId };
      },
      onError: (err, variables, context: any) => {
        if (context?.previous) {
          queryClient.setQueryData<TBooking>(
            ['booking', variables.id],
            context.previous
          );
        }
      },
      onSettled: async (data, err, variables, context: any) => {
        await Promise.all([
          queryClient.invalidateQueries(BookingsQueryKey),
          queryClient.invalidateQueries('events'),
          queryClient.invalidateQueries(['booking', variables?.id]),
          queryClient.invalidateQueries('receipt'),
        ]);
        await Promise.all([
          queryClient.invalidateQueries([context?.eventId, 'bookings']),
          queryClient.invalidateQueries([
            'events',
            'experience',
            context?.experienceId,
          ]),
          queryClient.invalidateQueries(['search']),
        ]);
      },
    }
  );

  const createBooking = useMutation(
    async ({ id, ...payload }: TCreateBookingPayload & { id: string }) => {
      const { eventId } = await api.createBooking<
        TCreateBookingPayload,
        { eventId: string }
      >(id, payload);
      return eventId;
    },
    {
      mutationKey: 'createBookingMutation',
      onMutate: async ({ id, eventId, ...payload }) => {
        queryClient.setQueryData(['booking', id], { ...payload, eventId });

        const forEventsKey = [eventId, 'bookings'];

        const prevBookingsForEvents = queryClient.getQueryData(forEventsKey);
        const prevBookings = queryClient.getQueryData(BookingsQueryKey);

        queryClient.setQueryData<TCreateBookingPayload[]>(
          forEventsKey,
          (prev) => {
            return [{ ...payload, id, eventId }, ...(prev ?? [])];
          }
        );

        queryClient.setQueryData<TCreateBookingPayload[]>(
          BookingsQueryKey,
          (prev) => {
            return [{ ...payload, id, eventId }, ...(prev ?? [])];
          }
        );

        return { prevBookingsForEvents, prevBookings };
      },
      onError: async (err, variables, context) => {
        if (context?.prevBookingsForEvents) {
          queryClient.setQueryData(
            [variables.eventId, 'bookings'],
            context.prevBookingsForEvents
          );
        }
        if (context?.prevBookings) {
          queryClient.setQueryData(BookingsQueryKey, context.prevBookings);
        }
        throw err;
      },
      onSettled: async (newEventId, err, variables, context: any) => {
        await Promise.all([
          queryClient.invalidateQueries(BookingsQueryKey),
          queryClient.invalidateQueries('events'),
          queryClient.invalidateQueries(['booking', variables?.id]),
          queryClient.invalidateQueries(['search']),
        ]);
      },
    }
  );

  const moveBooking = useMutation(
    async ({ id, eventId }: { id: string; eventId: string }) => {
      const { newBookingId, newEventId } = await api.moveBooking(id, eventId);
      return {
        newBookingId,
        newEventId,
      };
    },
    {
      mutationKey: 'moveBookingMutation',
      onMutate: async ({ id, eventId }) => {
        const prevBooking = queryClient.getQueryData<TBooking>(['booking', id]);

        const oldForEventsKey = [prevBooking?.eventId, 'bookings'];
        const newForEventsKey = [eventId, 'bookings'];

        const prevOldBookingsForEvents =
          queryClient.getQueryData(oldForEventsKey);
        const prevNewBookingsForEvents =
          queryClient.getQueryData(newForEventsKey);

        const prevBookings = queryClient.getQueryData(BookingsQueryKey);

        queryClient.setQueryData<TBooking[]>(oldForEventsKey, (prev) => {
          return (prev ?? []).map((el) => {
            return { ...el, status: 'moved', movedToEvent: eventId };
          });
        });

        queryClient.setQueryData<TBooking[]>(newForEventsKey, (prev) => {
          return [
            { ...((prevBooking ?? {}) as TBooking), id, eventId },
            ...(prev ?? []),
          ];
        });

        queryClient.setQueryData<TBooking[]>(BookingsQueryKey, (prev) => {
          return prev?.map((el) => ({ ...el, eventId })) ?? [];
        });

        queryClient.setQueryData<TBooking>(['booking', id], (prev) => {
          return {
            ...((prev ?? {}) as TBooking),
            movedToEvent: eventId,
            status: 'moved',
          };
        });

        return {
          prevOldBookingsForEvents,
          prevBooking,
          prevBookings,
          prevNewBookingsForEvents,
        };
      },
      onError: async (err, variables, context) => {
        if (context?.prevOldBookingsForEvents) {
          queryClient.setQueryData(
            [context?.prevBooking?.eventId, 'bookings'],
            context.prevOldBookingsForEvents
          );
        }
        if (context?.prevNewBookingsForEvents) {
          queryClient.setQueryData(
            [variables.eventId, 'bookings'],
            context.prevNewBookingsForEvents
          );
        }
        if (context?.prevBooking) {
          queryClient.setQueryData(
            ['booking', variables.id],
            context.prevBooking
          );
        }
        if (context?.prevBookings) {
          queryClient.setQueryData(BookingsQueryKey, context.prevBookings);
        }
        throw err;
      },
      onSettled: async (data, err, variables, context: any) => {
        await Promise.all([
          queryClient.invalidateQueries(BookingsQueryKey),
          queryClient.invalidateQueries('events'),
          queryClient.invalidateQueries(['booking', variables?.id]),
          queryClient.invalidateQueries(['booking', data?.newBookingId]),
        ]);
        await Promise.all([
          queryClient.invalidateQueries([data?.newEventId, 'bookings']),
          queryClient.invalidateQueries([
            context?.prevBooking?.eventId,
            'bookings',
          ]),
          queryClient.invalidateQueries([
            'events',
            'experience',
            context.prevBooking?.experienceId,
          ]),
        ]);
        setTimeout(() => {
          queryClient.invalidateQueries([
            'audits',
            { object: 'Booking', objectId: variables?.id },
          ]);
        }, 1000);
      },
    }
  );

  const checkInBooking = useMutation(
    ({ id, method }: { id: string; method: CheckIn['method'] }) => {
      return api.checkInBooking(id, method);
    },
    {
      onMutate: async ({ id }) => {
        queryClient.invalidateQueries(['booking', id]);
      },
      onSettled: async (data, err, variables) => {
        await queryClient.invalidateQueries(['booking', variables?.id]);
      },
    }
  );

  return {
    booking,
    enrichedBooking,
    bookingsForEvent,
    bookingsByDate,
    cancelBooking,
    moveBooking,
    createBooking,
    updateBooking,
    checkInBooking,
  };
};
