import styled from '@emotion/styled';
import { lightTheme, Text } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CopyLink } from '../../../../Components/CopyLink/CopyLink';
import { InfoBox } from '../../../../Components/InfoBox/InfoBox';

type CustomerInformationProps = {
  name: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  email: string;
  phone: string;
};

export const CustomerInformation = ({
  name,
  address,
  postalCode,
  city,
  country,
  email,
  phone,
}: CustomerInformationProps) => {
  const { t } = useTranslation();

  return (
    <InfoBox title={t('giftCard.customer.title')}>
      <Stack sx={{ gap: 3 }}>
        <Stack>
          <Text fontSize="small" variant="medium">
            {name}
          </Text>
          {address && <StyledAddressText>{address}</StyledAddressText>}
          {(!!postalCode || !!city) && (
            <StyledAddressText>
              {postalCode} {city}
            </StyledAddressText>
          )}
          {country && (
            <StyledAddressText>
              {t(`countryCodes.${country}`)}
            </StyledAddressText>
          )}
        </Stack>
        {(phone || email) && (
          <Stack sx={{ gap: 0.25 }}>
            {email && (
              <CopyLink
                href={`mailto:${email}`}
                isEmail
                fontSize="xsmall"
                label={email}
              />
            )}
            {phone && <Text fontSize="xsmall">{phone}</Text>}{' '}
          </Stack>
        )}
      </Stack>
    </InfoBox>
  );
};

const StyledAddressText = styled(Text)({
  fontSize: '12px',
  color: lightTheme.palette.neutral.n400,
});
