import { Button, lightTheme, LinkButton } from '@holdbar-com/pixel';
import { Text } from '@holdbar-com/pixel';
import { MoreVert } from '@mui/icons-material';
import { Stack, styled } from '@mui/material';
import { Popover } from '@radix-ui/themes';
import { useState } from 'react';
import { useLinkClickHandler } from 'react-router-dom';

import { StyledPopoverContent } from '../../../Components/Popover/radix_popover_styles';

export function ContextMenu({
  actions,
}: {
  actions: {
    href: string;
    label: string;
  }[];
}) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <Popover.Trigger>
        <StyledIconButton variant="secondary" size="small" onClick={() => {}}>
          <MoreVert
            fontSize="small"
            sx={{
              color: 'black',
              display: 'flex',
            }}
          />
        </StyledIconButton>
      </Popover.Trigger>
      <StyledPopoverContent sideOffset={5} align="end">
        <Stack gap={1} width="100%" py={1}>
          {actions.map((action) => (
            <ContextMenuItem key={action.href} {...action} />
          ))}
        </Stack>
      </StyledPopoverContent>
    </Popover.Root>
  );
}

function ContextMenuItem({ href, label }: { href: string; label: string }) {
  const handleClick = useLinkClickHandler(href);
  return (
    <StyledContextMenuItem
      size="medium"
      variant="text"
      href={href}
      onClick={handleClick}
    >
      <Text fontSize="small">{label}</Text>
    </StyledContextMenuItem>
  );
}

const StyledContextMenuItem = styled(LinkButton)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 8,
  padding: 8,
  borderRadius: 0,
  minWidth: 200,
  ':hover': { backgroundColor: lightTheme.palette.neutral.n50 },
});

const StyledIconButton = styled(Button)({
  padding: 2,
  aspectRatio: 1,
});
