import {
  EconomicConfiguration,
  GetEconomicAccountsResponse,
  GetEconomicJournalsResponse,
  GetEconomicVatAccountsResponse,
  HoldbarApiCollection,
  HoldbarApiSingleResource,
} from '@holdbar-com/utils-types';
import Axios from 'axios';
import { apiServices } from './apiServices';

import api from './index';

export const initEconomicSetup = async () => {
  if (typeof apiServices.economic === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ECONOMIC_URL is not defined'
    );
  }

  const url = `${apiServices.economic}/integrations/economic/setup`;

  const { data } = await api.get<{ url: string }>(url);

  return data;
};

export const finalizeEconomicSetup = async (token: string) => {
  if (typeof apiServices.economic === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ECONOMIC_URL is not defined'
    );
  }

  const url = `${apiServices.economic}/integrations/economic/setup`;

  const { data } = await api.post<{ url: string }>(url, {
    token,
  });

  return data;
};

export const disconnectEconomic = async () => {
  if (typeof apiServices.economic === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ECONOMIC_URL is not defined'
    );
  }

  const url = `${apiServices.economic}/integrations/economic/setup`;
  const { data } = await api.delete<{ url: string }>(url);

  return data;
};

export const getEconomicAccounts = async () => {
  if (typeof apiServices.economic === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ECONOMIC_URL is not defined'
    );
  }

  const url = `${apiServices.economic}/integrations/economic/accounts`;
  const { data } =
    await api.get<HoldbarApiCollection<GetEconomicAccountsResponse[number]>>(
      url
    );

  return data;
};

export const getEconomicJournals = async () => {
  if (typeof apiServices.economic === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ECONOMIC_URL is not defined'
    );
  }

  const url = `${apiServices.economic}/integrations/economic/journals`;
  const { data } =
    await api.get<HoldbarApiCollection<GetEconomicJournalsResponse[number]>>(
      url
    );

  return data;
};

export const getEconomicVatAccounts = async () => {
  if (typeof apiServices.economic === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ECONOMIC_URL is not defined'
    );
  }

  const url = `${apiServices.economic}/integrations/economic/vat-accounts`;
  const { data } =
    await api.get<HoldbarApiCollection<GetEconomicVatAccountsResponse[number]>>(
      url
    );

  return data;
};

export const getEconomicConfiguration = async () => {
  if (typeof apiServices.economic === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ECONOMIC_URL is not defined'
    );
  }

  try {
    const url = `${apiServices.economic}/integrations/economic/configuration`;
    const { data } =
      await api.get<HoldbarApiSingleResource<EconomicConfiguration>>(url);

    data.item.vatCodes = serializeNumberKeys(data.item.vatCodes);

    return data;
  } catch (error) {
    if (Axios.isAxiosError(error) && error.response?.status === 404) {
      return null;
    }

    throw error;
  }
};

export const saveEconomicConfiguration = async (
  configuration: EconomicConfiguration
) => {
  if (typeof apiServices.economic === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ECONOMIC_URL is not defined'
    );
  }

  configuration.vatCodes = deserializeNumberKeys(configuration.vatCodes);

  const url = `${apiServices.economic}/integrations/economic/configuration`;
  await api.put(url, configuration);
};

export const SERIALIZED_PREFIX = 'serialized_prefix_';
export const SERIALIZED_SYMBOL = '__';

/**
 * Since number keys are messing a bit with react form hook, we serialize them.
 * That way there is no confusion between arrays/objects and paths.
 */
const serializeNumberKeys = <T>(
  accounts: Record<string, T> | undefined
): Record<string, T> | undefined => {
  if (!accounts) return accounts;

  return Object.entries(accounts).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [SERIALIZED_PREFIX + key.replace('.', SERIALIZED_SYMBOL)]: value,
    }),
    {}
  );
};

const deserializeNumberKeys = <T>(
  accounts: Record<string, T> | undefined
): Record<string, T> | undefined => {
  if (!accounts) return accounts;

  return Object.entries(accounts).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key.replace(SERIALIZED_PREFIX, '').replace(SERIALIZED_SYMBOL, '.')]:
        value,
    }),
    {}
  );
};
