import NiceModal from '@ebay/nice-modal-react';
import { EditOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { InfoBox } from '../../../../../../../Components/InfoBox/InfoBox';
import useResponsive from '../../../../../../../Hooks/layout/useResponsive';
import { useBookingAccess } from '../../../../../../../Hooks/useBookingAccess';
import { useExperience } from '../../../../../../../Hooks/useExperience';
import { useTranslate } from '../../../../../../../Hooks/useTranslate';
import { EditBookingDialog } from '../../../../../booking-management/edit-booking/edit-booking-dialog';
import { useBookingDetailsContext } from '../../../../domain/use_booking_details_context';
import { bookingRows } from '../../domain/use_booking_details_summary';
import { BookingDetailsSummaryTable } from '../BookingDetailsSummaryTable';
import { BookingDetailsSummaryBookingMobile } from './BookingDetailsSummaryBookingMobile';

export const BookingDetailsSummaryBooking = () => {
  const { t } = useTranslate('dialogs.booking');
  const { isSm } = useResponsive();

  const { booking, event } = useBookingDetailsContext();
  const { bookingExperienceId, hasWriteAccess } = useBookingAccess({
    booking,
  });

  const {
    experience: { data: experience },
  } = useExperience(bookingExperienceId);

  const flags = useFlags();

  if (isSm) {
    return <BookingDetailsSummaryBookingMobile />;
  }

  const canEditTickets =
    flags.featureBookingManagement &&
    hasWriteAccess &&
    ['active', 'checked-in, unpaid'].includes(booking?.status ?? '') &&
    !event?.status.includes('cancel');

  return (
    <InfoBox
      title={t('sections.booking')}
      actions={
        canEditTickets && (
          <IconButton
            size="small"
            sx={{ padding: 0 }}
            onClick={() => {
              if (!booking || !experience) return;
              NiceModal.show(EditBookingDialog, {
                booking,
                experience,
              });
            }}
          >
            <EditOutlined fontSize="small" />
          </IconButton>
        )
      }
    >
      <BookingDetailsSummaryTable rows={bookingRows} />
    </InfoBox>
  );
};
