import { isPast } from 'date-fns';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as api from '../Api';
import { DiscountBadge } from '../Components/badge/badge-config';

export type TDiscount = {
  id: string;
  status: string;
  name: string;
  created: string;
  companyId: string;
  code: string;
  rate: number;
  expiry: null | {
    startDateTime: string;
    endDateTime: string;
  };
  percentage: boolean;
  scope: 'unit' | 'total';
  currency: string;
  overrides?: {
    type: 'experience';
    id: string;
    enabled?: boolean;
    rate?: number;
    percentage?: boolean;
  }[];
  timesRedeemed: number;
  limits?: {
    timesRedeemed?: number;
  };
  enabled: boolean;
};

export const useDiscount = (discountId?: string) => {
  const queryClient = useQueryClient();

  const DiscountsQueryKey = ['discounts'];
  const DiscountQueryKey = ['discount', discountId];

  const discounts = useQuery<TDiscount[]>(
    DiscountsQueryKey,
    async () => {
      await queryClient.cancelQueries(DiscountsQueryKey);
      return api.getDiscounts();
    },
    {
      enabled: true,
    }
  );

  const discount = useQuery<Partial<TDiscount>>(
    DiscountQueryKey,
    async () => {
      await queryClient.cancelQueries(DiscountQueryKey);
      const discounts =
        queryClient.getQueryData<TDiscount[]>(DiscountsQueryKey);
      const found = discounts?.find((el) => el.id === discountId);
      if (found) {
        return found;
      }
      return api.getDiscount(discountId as string);
    },
    {
      enabled: Boolean(discountId),
    }
  );

  const updateDiscount = useMutation(
    ({ id, ...data }: TDiscount) => api.updateDiscount(id ?? discountId, data),
    {
      onError: (err, variables, context: any) => {
        if (context?.previous) {
          queryClient.setQueryData<TDiscount>(
            DiscountQueryKey,
            context.previous
          );
        }
      },
      onSettled: async (data, error, variables, context) => {
        await queryClient.invalidateQueries(DiscountsQueryKey);
        await queryClient.invalidateQueries([
          'discount',
          variables?.id ?? discountId,
        ]);
      },
    }
  );

  const deleteDiscount = useMutation((id: string) => api.deleteDiscount(id), {
    onMutate: async (id) => {
      await queryClient.cancelQueries(DiscountsQueryKey);

      const previous = queryClient.getQueryData<TDiscount[]>(DiscountQueryKey);

      queryClient.setQueryData<TDiscount[]>(DiscountsQueryKey, (prev) => {
        return prev!.filter((el) => el.id !== id);
      });

      return { previous };
    },
    onError: (err, variables, context: any) => {
      if (context?.previous) {
        queryClient.setQueryData<TDiscount>(DiscountQueryKey, context.previous);
      }
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(DiscountsQueryKey);
      await queryClient.invalidateQueries(DiscountQueryKey);
    },
  });

  return {
    discount,
    discounts,
    updateDiscount,
    deleteDiscount,
  };
};

export const getDiscountStatus = (discount: TDiscount) => {
  const { limits, timesRedeemed, expiry, status } = discount;

  if (limits?.timesRedeemed && timesRedeemed >= limits.timesRedeemed)
    return 'expired';

  if (expiry?.endDateTime && isPast(new Date(expiry.endDateTime)))
    return 'expired';

  return status as DiscountBadge['state'];
};
