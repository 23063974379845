import styled from '@emotion/styled';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { renderDateTime } from '@holdbar-com/utils-date';
import { ArrowBackRounded } from '@mui/icons-material';
import { Divider, Skeleton, Stack } from '@mui/material';

import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useHistory } from '../../../../../Hooks/useHistory';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { useBookingDetailsContext } from '../../domain/use_booking_details_context';
import { BookingDetailsActions } from '../booking_details_actions/BookingDetailsActions';
import { useBookingDetailsActions } from '../booking_details_actions/domain/use_booking_details_actions';
import { BookingStatusBadge } from '../../../../events/event_details/BookingList/shared';

export const BookingDetailsHeader = () => {
  const { t } = useTranslate('dialogs.booking');
  const { isLg, isSm } = useResponsive();

  const { booking, checkInAvailable } = useBookingDetailsContext();
  const { handleCheckInBooking } = useBookingDetailsActions();

  const { id, customer, status, created: createdAt } = booking ?? {};

  const title = customer?.name;

  const { goBack } = useHistory();

  const handleBack = () => {
    const fallbackLink = booking ? `/event/${booking?.eventId}` : '/';
    goBack(fallbackLink);
  };

  return (
    <Stack gap={{ xs: 2, lg: 0 }}>
      <Stack direction="row" justifyContent="space-between">
        <Stack gap={isLg ? 1 : 2} direction={isLg ? 'column' : 'row'}>
          <StyledBackArrowRounded onClick={handleBack} />
          <Stack gap={1}>
            <Stack direction="row" gap={2} alignItems="baseline" mt={1}>
              <Text fontSize="large" variant="medium">
                {title ?? <Skeleton width={200} />}
              </Text>
              {status && <BookingStatusBadge status={status} />}
            </Stack>
            <Stack direction={isLg ? 'column' : 'row'} gap={isLg ? 0 : 1}>
              {createdAt ? (
                <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
                  {t('created', 'utils.generic')}: {renderDateTime(createdAt)}
                </Text>
              ) : (
                <Skeleton width={'80%'} />
              )}
              {createdAt && id && !isLg && (
                <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
                  •
                </Text>
              )}
              {id && (
                <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
                  {`ID: ${id}`}
                </Text>
              )}
            </Stack>
          </Stack>
        </Stack>
        <BookingDetailsActions />
      </Stack>
      {checkInAvailable && isLg ? (
        <Stack gap={4}>
          <Button
            size="large"
            variant="primary"
            style={isSm ? {} : { alignSelf: 'flex-start' }}
            onClick={() => handleCheckInBooking()}
          >
            {t('actions.checkIn')}
          </Button>
          {isSm && <Divider />}
        </Stack>
      ) : null}
    </Stack>
  );
};

const StyledBackArrowRounded = styled(ArrowBackRounded)({
  height: '33px',
  width: '33px',
  padding: '4px',
  cursor: 'pointer',
  borderRadius: '50%',
  marginTop: '3.5px',
  transition: 'background-color 0.1s',
  '&:hover': {
    backgroundColor: lightTheme.palette.neutral.n100,
  },
  '&:active': {
    backgroundColor: lightTheme.palette.neutral.n200,
  },
});
