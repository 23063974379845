import { Box, BoxProps, Grid, Skeleton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useExperience } from '../../../../Hooks/useExperience';
import { useLocalizedStringFormatter } from '../../../../Hooks/useLocalizedStringFormatter';
import { EconomicAccountAutocomplete } from './economic-account-autocomplete';
import { t } from '../../../../i18n/config';
import { ArrowRight, SubdirectoryArrowRight } from '@mui/icons-material';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { EconomicConfigurationSectionTitle } from '../economic-configuration-section-title';

const containerProps: BoxProps = {
  bgcolor: '#fafafb',
  borderRadius: 3,
  p: 3,
  position: 'relative',
  maxWidth: 750,
};

export const EconomicConfigurationExperienceAccounts: FC = () => {
  const { experiences } = useExperience();
  const localizer = useLocalizedStringFormatter();
  const { watch } = useFormContext();
  const { isSm } = useResponsive();
  const { t } = useTranslate('settings.integrations.economic.configuration');

  if (experiences.isLoading || !experiences.data) {
    return <Skeleton variant="rounded" width={300} height={55} />;
  }

  return (
    <Stack
      gridColumn={isSm ? 1 : 2}
      gap={4}
      pl={4}
      flexDirection="column"
      flexWrap="wrap"
    >
      {experiences.data.map((experience) => (
        <Box {...containerProps} key={experience.id}>
          <Stack flexDirection="column" gap={2}>
            <Typography variant={'body1'}>
              {localizer(experience.headline)}
            </Typography>
            <div>
              <EconomicAccountAutocomplete
                label={t('accounts.salesWithVat')}
                path={`experienceSales.${experience.id}`}
                defaultValue={watch(`accounts.salesWithVat`)}
              />
            </div>
            <Grid container spacing={3}>
              {experience.price.variants?.some(
                (variant) => (variant.addons ?? []).length > 0
              ) && (
                <Grid item xs={12}>
                  <EconomicConfigurationSectionTitle
                    title={t('addons.title')}
                    description={t('addons.description')}
                  />
                </Grid>
              )}
              {experience.price.variants?.map((variants) => {
                return (
                  <>
                    {variants.addons?.map((addon) => (
                      <Grid item xs={12} md={6} key={addon.id}>
                        <EconomicAccountAutocomplete
                          label={`${localizer(addon.name)}`}
                          path={`experienceSales.${addon.id}`}
                          defaultValue={watch(`accounts.salesWithVat`)}
                        />
                      </Grid>
                    ))}
                  </>
                );
              })}
            </Grid>
          </Stack>
        </Box>
      ))}
    </Stack>
  );
};
