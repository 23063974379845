import NiceModal from '@ebay/nice-modal-react';
import { EditOutlined } from '@mui/icons-material';
import { IconButton, Skeleton, Stack } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { InfoBox } from '../../../../../../../Components/InfoBox/InfoBox';
import useResponsive from '../../../../../../../Hooks/layout/useResponsive';
import { useBookingAccess } from '../../../../../../../Hooks/useBookingAccess';
import { useTranslate } from '../../../../../../../Hooks/useTranslate';
import { EditCustomerDialog } from '../../../../../booking-management/edit-booking/edit-customer-dialog';
import { useBookingDetailsContext } from '../../../../domain/use_booking_details_context';
import { customerRows } from '../../domain/use_booking_details_summary';
import { BookingDetailsSummaryCustomerMobile } from './BookingDetailsSummaryCustomerMobile';

export const BookingDetailsSummaryCustomer = () => {
  const { t } = useTranslate('dialogs.booking');
  const { isSm } = useResponsive();

  const { booking, receipt } = useBookingDetailsContext();

  const flags = useFlags();

  const { hasWriteAccess } = useBookingAccess({ booking });

  if (isSm) {
    return <BookingDetailsSummaryCustomerMobile />;
  }

  return (
    <InfoBox
      title={t('sections.customer')}
      actions={
        flags.featureBookingManagement &&
        hasWriteAccess && (
          <IconButton
            size="small"
            sx={{ padding: 0 }}
            onClick={() => {
              if (!booking) return;
              NiceModal.show(EditCustomerDialog, {
                booking,
              });
            }}
          >
            <EditOutlined fontSize="small" />
          </IconButton>
        )
      }
    >
      <Stack gap={2}>
        {booking
          ? customerRows.map((row) => row.value(booking, t, receipt))
          : customerRows.map((row) => <Skeleton key={row.key} width="60%" />)}
      </Stack>
    </InfoBox>
  );
};
