import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { useController, UseFormReturn } from 'react-hook-form';
import { WeekdayStr } from 'rrule';

import { FrequencyUntilSelect } from '../Components/FrequencyUntilSelect/FrequencyUntilSelect';
import { useTranslate } from '../Hooks/useTranslate';

const freqOptions = [
  {
    key: 'DAILY',
    label: 'dag',
  },
  {
    key: 'WEEKLY',
    label: 'uge',
  },
  {
    key: 'MONTHLY',
    label: 'måned',
  },
];

export const weekdaysOptions: { key: WeekdayStr; label: string }[] = [
  {
    key: 'MO',
    label: 'Man',
  },
  {
    key: 'TU',
    label: 'Tirs',
  },
  {
    key: 'WE',
    label: 'Ons',
  },
  {
    key: 'TH',
    label: 'Tors',
  },
  {
    key: 'FR',
    label: 'Fre',
  },
  {
    key: 'SA',
    label: 'Lør',
  },
  {
    key: 'SU',
    label: 'Søn',
  },
];

export const FrequencyDialog = NiceModal.create(
  ({ control }: { control: UseFormReturn['control'] }) => {
    const { t } = useTranslate(
      'events.create.details.recurring.frequency.customOptions'
    );

    const modal = useModal();

    const { field: weekdays } = useController({
      control,
      name: 'recurring.weekdays',
      defaultValue: ['MO', 'TU', 'WE', 'TH', 'FR'],
    });
    const { field: freq } = useController({
      control,
      name: 'recurring.freq',
      defaultValue: 'DAILY',
    });
    const { field: interval } = useController({
      control,
      name: 'recurring.interval',
      defaultValue: 1,
    });
    const { field: untilType } = useController({
      control,
      name: 'recurring.untilType',
      defaultValue: 'never',
    });
    const { field: until } = useController({
      control,
      name: 'recurring.until',
      defaultValue: '',
    });
    const { field: count } = useController({
      control,
      name: 'recurring.count',
      defaultValue: 100,
    });

    const handleClose = () => {
      modal.hide();
      modal.reject('FrequencyDialog');
    };

    const handleOk = () => {
      modal.resolve({
        weekdays: weekdays.value,
        freq: freq.value,
        untilType: untilType.value,
        interval: interval.value,
        until: until.value,
        count: count.value,
      });
      modal.hide();
    };

    const handleClickItem = (key: string) => () => {
      const _new = weekdays.value.includes(key)
        ? weekdays.value.filter((el: any) => el !== key)
        : [...weekdays.value, key];
      weekdays.onChange(_new);
    };

    const _weekdaysOptions = weekdaysOptions.map((el) => ({
      ...el,
      label: t(el.key.toLowerCase(), 'utils.abbDays'),
    }));

    const _freqOptions = freqOptions.map((el) => ({
      ...el,
      label: t(`freq.${el.key.toLowerCase()}`),
    }));

    return (
      <Dialog open={modal.visible} maxWidth={'md'} onClose={handleClose}>
        <Box p={4} pb={2}>
          <Typography variant={'h4'}>{t('title')}</Typography>

          <Stack spacing={3} mt={3}>
            <Stack
              direction={'row'}
              spacing={2}
              minHeight={48}
              alignItems={'center'}
            >
              <Typography variant={'h5'} sx={{ minWidth: 96 }}>
                {t('repeatEvery')}
              </Typography>
              <Select size={'small'} {...interval}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((el) => (
                  <MenuItem value={el}>{el}.</MenuItem>
                ))}
              </Select>
              <Select size={'small'} {...freq}>
                {_freqOptions.map((el) => (
                  <MenuItem value={el.key}>{el.label}</MenuItem>
                ))}
              </Select>
            </Stack>

            <Stack
              direction={'row'}
              spacing={2}
              minHeight={48}
              alignItems={'center'}
            >
              <Typography variant={'h5'} sx={{ minWidth: 96 }}>
                {t('repeatOn')}
              </Typography>
              <Stack direction={'row'} spacing={2} mt={2}>
                {_weekdaysOptions.map((el, i) => {
                  return (
                    <Box
                      component={ButtonBase}
                      sx={{
                        border: 'none',
                        borderRadius: 100,
                        height: 48,
                        width: 48,
                        p: 0,
                        backgroundColor: weekdays.value.includes(el.key)
                          ? 'primary.main'
                          : '#F2F2F7',
                        color: weekdays.value.includes(el.key)
                          ? '#ffffff'
                          : '#1C1C1E',
                      }}
                      onClick={handleClickItem(el.key)}
                      display={'flex'}
                      flexDirection={'column'}
                    >
                      <Typography mt={0} variant={'h6'}>
                        {el.label}
                      </Typography>
                    </Box>
                  );
                })}
              </Stack>
            </Stack>

            <FrequencyUntilSelect
              control={control}
              typeFormKey={'recurring.untilType'}
              valueFormKey={'recurring.until'}
            />
          </Stack>
        </Box>
        <DialogActions>
          <Button
            color={'secondary'}
            variant={'outlined'}
            onClick={handleClose}
          >
            {t('actions.secondary')}
          </Button>
          <Button variant={'contained'} onClick={handleOk}>
            {t('actions.primary')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
