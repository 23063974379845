import NiceModal from '@ebay/nice-modal-react';

import { ampli } from '../../../../../Ampli';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { IcalDialog } from '../../../../../Modals/ical-dialog/ical-dialog';
import { IntegrationObject } from '../use-integrations';

export const useIcalIntegration = (): IntegrationObject => {
  const { t } = useTranslate('settings.integrations');

  const handleCreateIcalFeed = (status: string) => async () => {
    if (status === 'connect') {
      NiceModal.show(IcalDialog, { onClick: async () => {} });
      ampli.integrationsCalendarSyncConnectFlowStarted();
    }
  };

  return {
    id: 'ical',
    title: t('calendars.ical.title'),
    description: t('calendars.ical.description'),
    extendedDescription: t('calendars.ical.extendedDescription'),
    status: 'connect',
    icon: '/integrations/calendars/ical.png',
    guide:
      'https://help.holdbar.com/en/articles/9158272-calendar-synchronization',
    onInstall: handleCreateIcalFeed,
    buttonLabel: (status: string) => t(`calendars.status.${status}`),
  };
};
