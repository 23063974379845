import { Customer } from '@holdbar-com/utils-types';
import { TShallowBooking } from '../../../../Utils/eventHelpers';
import { TStatus } from '../../../../Utils/types';

export type EventUpsertVariant = 'create' | 'edit';

type Status = 'active';

export type AddressOptionKey =
  | string
  | 'companyAddress'
  | 'experienceCustom'
  | 'custom';

// Type guard functions for address options
export function isCustomAddressOptionKey(
  key: AddressOptionKey
): key is 'custom' {
  return key === 'custom';
}

export function isExperienceCustomAddressOptionKey(
  key: AddressOptionKey
): key is 'experienceCustom' {
  return key === 'experienceCustom';
}

export function isCompanyAddressOptionKey(
  key: AddressOptionKey
): key is 'companyAddress' {
  return key === 'companyAddress';
}

export function isExperienceMultipleAddressOptionKey(
  key: AddressOptionKey
): key is string {
  return /^experienceMultiple-\d+$/.test(key);
}

export const isIntervalSelected = (
  intervals: IntervalSelected | IntervalNotSelected
): intervals is IntervalSelected => {
  return intervals.selectedOptionKey === 'yes';
};

export type RecurringNotSelected = {
  selectedOptionKey?: 'no';
  frequency: {
    value: undefined | null;
  };
};

// Recurring type build on intersection types
export type RecurringSelectedBase = {
  selectedOptionKey: 'yes';
  rrulestr: string;
};

export type RecurringDaily = {
  frequency: {
    value: 'daily';
  };
} & RecurringSelectedBase;

export type RecurringWeekly = {
  frequency: {
    value: 'weekly';
  };
} & RecurringSelectedBase;

export type RecurringAllBusinessDays = {
  frequency: {
    value: 'allBusinessDays';
  };
} & RecurringSelectedBase;

export type RecurringAllWeekends = {
  frequency: {
    value: 'allWeekends';
  };
} & RecurringSelectedBase;

export type RecurringCustom = {
  frequency: {
    value: 'custom';
  };
} & RecurringSelectedBase;

export type Recurring =
  | RecurringNotSelected
  | RecurringDaily
  | RecurringWeekly
  | RecurringAllBusinessDays
  | RecurringAllWeekends
  | RecurringCustom;

type IntervalNotSelected = {
  selectedOptionKey: 'no';
};

type IntervalSelected = {
  selectedOptionKey: 'yes';
  value: {
    fromTime: string;
    toTime: string;
  }[];
};

type Interval = IntervalNotSelected | IntervalSelected;

type EventVisibility = 'public' | 'private';

export type EventAddresses = {
  selectedOptionKey: AddressOptionKey;
  value?: string;
};

export type Selection = 'yes' | 'no' | 'custom' | 'experienceDefault';

// Reserved type for internal use and should be omitted from the public API
export type EventTypeAdditionalProps = {
  variant: EventUpsertVariant;
  step: EventStepType;
  startEndTimeDiff: number;
  booking?: {
    guests?: { [id: string]: number };

    /**
     * @deprecated use customer instead
     */
    name?: string;

    /**
     * @deprecated use customer instead
     */
    email?: string;

    /**
     * @deprecated use customer instead
     */
    phone?: string;
    language?: string;
    internalNotes?: string;
    shouldSendNotification?: boolean;
    paymentMethod?: string;
    vatNumber?: string;
    companyName?: string;
    location?: {
      address: string;
      city: string;
      zipCode: string;
      country: string;
    }
  };
  endDateTimeDisabled: boolean;
};

export interface EventType extends EventTypeAdditionalProps {
  id: string;
  parentId?: string;
  experienceId: string;
  visibility: EventVisibility;
  status: TStatus;
  bookings?: TShallowBooking[];
  intervals: Interval;
  locationId?: string;
  recurring: Recurring;
  dates: {
    created: string;
    modified: string;
  };
  languages: string[];
  startDateTime: string;
  endDateTime: string;
  seatCount?: {
    selectedOptionKey: string;
    value: number;
  };
  assignedGuides: string[];
  addresses: EventAddresses;
}

export type InitializeEventType<T extends EventType> = {
  (event?: T): void;
};

type ValueOf<T, K extends keyof T> = T[K] extends infer R ? R : never;

export type UpdateEventFieldType = {
  <K extends keyof EventType>(field: K, value: ValueOf<EventType, K>): void;
  <K extends keyof EventType, L extends keyof EventType[K]>(
    field: [K, L],
    value: ValueOf<EventType[K], L>
  ): void;
};

export const EventStepTypes = ['details', 'booking', 'time'] as const;

export type EventStepType = (typeof EventStepTypes)[number];

export const getEventSteps = (omitBooking: boolean): EventStepType[] => {
  return [
    'details',
    ...(omitBooking ? [] : (['booking'] as EventStepType[])),
    'time',
  ];
};
