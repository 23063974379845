import { Button } from '@holdbar-com/pixel';
import { CheckRounded } from '@mui/icons-material';
import { CircularProgress, Stack } from '@mui/material';

import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { useOnboardingWidgetContext } from '../use-onboarding-widget-context';
import { useOnboardingWidgetStepper } from '../use-onboarding-widget-stepper';

export const OnboardingWidgetItemActions = () => {
  const { t } = useTranslate('onboardingSetup');
  const { isLg } = useResponsive();

  const { handleClick, isStepActive } = useOnboardingWidgetStepper();

  const { items, step, finishing, handleFinishOnboarding } =
    useOnboardingWidgetContext();

  return (
    <Stack direction={'row'} gap={1}>
      {!isLg && (
        <Button
          size={'medium'}
          variant={'primary'}
          disabled={!isStepActive(step) || items[step]?.completed === true}
          onClick={handleClick(step)}
          leftIcon={
            items[step]?.completed === true && (
              <CheckRounded color={'success'} sx={{ mr: 1 }} />
            )
          }
          style={{ alignSelf: 'flex-end', marginTop: 'auto' }}
        >
          {items[step]?.completed === true
            ? t('completedButtonLabel')
            : t('actionButton')}
        </Button>
      )}
      {!isLg && items[step]?.key === 'implementation' && (
        <Button
          size={'medium'}
          variant={'secondary'}
          onClick={handleFinishOnboarding}
          style={{ alignSelf: 'flex-end', marginTop: 'auto' }}
          disabled={finishing}
          rightIcon={
            finishing && (
              <CircularProgress size={16} color="inherit" sx={{ ml: 1 }} />
            )
          }
        >
          {t('finishButton')}
        </Button>
      )}
    </Stack>
  );
};
