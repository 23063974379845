/**
 * This module includes mapping from process.env to app specific environment values.
 * The purpose is to make it possible to change process.env names easier + make it type safe across multiple files.
 */

export type Environment = 'production' | 'development';

export const environment: Environment = (process.env.REACT_APP_ENVIRONMENT ??
  'development') as Environment;

export const isProduction = environment === 'production';
