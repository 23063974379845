import NiceModal from '@ebay/nice-modal-react';
import styled from '@emotion/styled';
import { Button } from '@holdbar-com/pixel';
import { AssessmentOutlined } from '@mui/icons-material';

import { ampli } from '../../../../Ampli';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { RequestReportDialog } from '../../../../Modals/request-report/request-report-dialog';
import { useDashboardPageContext } from '../../use-dashboard-page-context';

export const DashboardRequestReport = () => {
  const { t } = useTranslate('dashboard');
  const { onboardingCompleted } = useProfile();
  const { isSm } = useResponsive();

  const { overviewTrackingData } = useDashboardPageContext();

  if (!onboardingCompleted) return null;

  return (
    <StyledButton
      variant="secondary"
      size="medium"
      leftIcon={!isSm && <AssessmentOutlined sx={{ mr: 1 }} />}
      onClick={() => {
        ampli.overviewRequestReportStarted(overviewTrackingData);
        NiceModal.show(RequestReportDialog);
      }}
    >
      {isSm ? <AssessmentOutlined /> : t('requestReport', 'buttons')}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  padding: 16px;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
