import styled from '@emotion/styled';
import { lightTheme, Text } from '@holdbar-com/pixel';
import { ExperienceStatus } from '@holdbar-com/utils-types';
import { HandshakeRounded } from '@mui/icons-material';
import { Skeleton, Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { getPublicCompanyProfile } from '../../../Api';
import { StatusBadge } from '../../../Components/badge/status-badge';
import { StatusChip } from '../../../Components/CustomCard/CustomCard';
import { useExperienceEvents } from '../../../Hooks/events/useExperienceEvents';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useAuth } from '../../../Hooks/useAuth';
import { getLocalized } from '../../../Hooks/useBookings';
import { IExperience, useExperience } from '../../../Hooks/useExperience';
import { useProfile } from '../../../Hooks/useProfile';
import { useTranslate } from '../../../Hooks/useTranslate';
import { isSharedExperience } from '../utils/is-shared-experience';

export const ExperienceItem = ({ experience }: { experience: IExperience }) => {
  const { t, i18n } = useTranslate('experience.list.item');
  const { isMd } = useResponsive();
  const { companyProfileUrl } = useProfile();
  const navigate = useNavigate();

  const [ownerCompanyName, setOwnerCompanyName] = useState<
    string | null | undefined
  >();

  useEffect(() => {
    // Return if value is already set
    if (typeof ownerCompanyName !== 'undefined') return;

    const isShared = isSharedExperience(experience);

    // If the experience is not shared, we explicitly set the name to `null`
    // so the early return above is triggered
    if (!isShared) return setOwnerCompanyName(null);

    // Fetch owner company profile and set owner company name
    const { ownerCompanyId } = experience;
    getPublicCompanyProfile(ownerCompanyId)
      .then(({ name }) => setOwnerCompanyName(name))
      .catch(() => setOwnerCompanyName(null));
  }, [experience, ownerCompanyName]);

  const { id, status, media, headline, visibility } = experience;

  const storefrontUrl = `${companyProfileUrl()}/experience/${id}`;
  const { updateExperience } = useExperience(id);
  const {
    eventsForExperience: { data: eventsForExperience },
  } = useExperienceEvents(experience.ownerExperienceId, {
    state: 'future',
    status: 'statusIsActive',
  });
  const { canAccess } = useAuth();

  const seats = eventsForExperience?.events?.reduce(
    (seatCounts, event) => {
      return {
        totalSeats: (seatCounts['totalSeats'] += event.slots.total),
        seatsBooked: (seatCounts['seatsBooked'] += event.slots.booked ?? 0),
      };
    },
    { totalSeats: 0, seatsBooked: 0 }
  );

  const upcomingEventsLabel = t('upcomingEvents', {
    count: eventsForExperience?.totalCount,
  });
  const bookingCountLabel = t('guestCount', {
    seatsBooked: seats?.seatsBooked,
    totalSeats: seats?.totalSeats,
  });

  const href = `/experience/${id}`;

  const handleStatusChange = (
    newStatus: Exclude<ExperienceStatus, 'draft' | 'archived'>
  ) => {
    if (!experience) return;

    updateExperience.mutateAsync({
      ...experience,
      status: newStatus,
    });
  };

  const canChangeStatus = useMemo(
    () =>
      canAccess('experience.write') && ['active', 'inactive'].includes(status),
    [canAccess, status]
  );

  if (isMd)
    return (
      <Link to={href}>
        <MobileCard>
          <MobileImage src={media?.primaryImage?.url ?? ''} alt="" />
          <Stack padding={2} paddingBottom={5} flexGrow={1} position="relative">
            <Stack
              direction="row"
              alignItems="center"
              alignSelf="flex-end"
              marginBottom="4px"
              gap={1}
            >
              {visibility && (
                <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
                  {t(visibility, 'experience.card.visibility')}
                </Text>
              )}
              <StatusChip status={status} size="small" />
            </Stack>
            <Text fontSize="small" variant="medium">
              {getLocalized(headline, i18n.language)}
            </Text>
            {seats ? (
              <Text fontSize="small">{upcomingEventsLabel}</Text>
            ) : (
              <Skeleton />
            )}
            {seats ? (
              <Text fontSize="small">{bookingCountLabel}</Text>
            ) : (
              <Skeleton />
            )}
            {ownerCompanyName && (
              <SharedExperienceIcon companyName={ownerCompanyName} />
            )}
          </Stack>
        </MobileCard>
      </Link>
    );

  return (
    <DesktopCard onClick={() => navigate(href)}>
      <Stack
        sx={{ position: 'relative', aspectRatio: '80 / 67', width: '100%' }}
      >
        <DesktopImage src={media?.primaryImage?.url ?? ''} alt="" />
        {ownerCompanyName && (
          <SharedExperienceIcon companyName={ownerCompanyName} />
        )}
      </Stack>
      <GridText
        span={3}
        style={{
          justifySelf: 'flex-start',
          marginLeft: '24px',
        }}
        fontSize="xsmall"
      >
        {getLocalized(headline, i18n.language)}
      </GridText>
      {seats ? (
        <GridText fontSize="xsmall" textAlign="center">
          {upcomingEventsLabel}
        </GridText>
      ) : (
        <Skeleton height={19.2} width={100} sx={{ gridColumn: 'span 2' }} />
      )}
      {seats ? (
        <GridText fontSize="xsmall" textAlign="center">
          {bookingCountLabel}
        </GridText>
      ) : (
        <Skeleton height={19.2} width={100} sx={{ gridColumn: 'span 2' }} />
      )}
      <StyledTextLink href={storefrontUrl} onClick={(e) => e.stopPropagation()}>
        {t('storefrontLink')}
      </StyledTextLink>
      <Stack
        gridColumn="span 2"
        direction="row"
        justifySelf="flex-end"
        alignItems="center"
        gap={2}
      >
        {visibility && (
          <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
            {t(visibility, 'experience.card.visibility')}
          </Text>
        )}
        <StatusBadge
          type="experience"
          size="small"
          state={status}
          options={
            canChangeStatus
              ? [
                  {
                    label: t(
                      status === 'active' ? 'deactivate' : 'activate',
                      'experience.card.options'
                    ),
                    onClick: () =>
                      handleStatusChange(
                        status === 'active' ? 'inactive' : 'active'
                      ),
                  },
                ]
              : undefined
          }
        />
      </Stack>
    </DesktopCard>
  );
};

const SharedExperienceIcon = ({ companyName }: { companyName: string }) => {
  const { t } = useTranslation();

  return (
    <Stack
      title={t('connect.sharedExperienceIcon.label', { companyName })}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        width: 40,
        backgroundColor: lightTheme.palette.action.a400,
        color: 'white',
        borderRadius: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'white',
        position: 'absolute',
        right: { xs: 16, md: -16 },
        bottom: { xs: 8, md: -4 },
        animation: 'fade-in 0.15s ease-out',
        '@keyframes fade-in': {
          '0%': {
            opacity: 0,
          },
          '100%': {
            opacity: 1,
          },
        },
      }}
    >
      <HandshakeRounded color="inherit" fontSize="small" />
    </Stack>
  );
};

export const DesktopCard = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, minmax(0, 1fr));',
  gap: '8px',
  borderRadius: '16px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: lightTheme.palette.neutral.n100,
  boxShadow: 'none',
  padding: '8px 16px 8px 16px',
  alignItems: 'center',
  justifyItems: 'center',
  overflow: 'hidden',
  background: 'white',
  transition: 'background 0.1s ease-in-out',
  '& > span': {
    maxWidth: '100%',
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
  cursor: 'pointer',
  '&:hover': {
    background: lightTheme.palette.neutral.n50,
  },
});

const GridText = styled(Text)<{ span?: number }>`
  grid-column: span ${(props) => props.span || 2};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const DesktopImage = styled.img({
  display: 'block',
  width: '100%',
  aspectRatio: 80 / 67,
  objectFit: 'cover',
  borderRadius: '8px',
  gridColumn: 'span 1',
  justifySelf: 'flex-start',
  height: '100%',
});

export const MobileCard = styled(Stack)({
  flexDirection: 'row',
  background: 'white',
  borderRadius: '16px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: lightTheme.palette.neutral.n100,
  overflow: 'hidden',
  cursor: 'pointer',
});

const MobileImage = styled.img({
  display: 'block',
  width: '108px',
  objectFit: 'cover',
});

const StyledTextLink = styled.a({
  gridColumn: 'span 2',
  padding: 0,
  fontSize: '12px',
  fontWeight: 'normal',
  textDecoration: 'underline',
  transition: 'opacity 0.1s ease-in-out',
  '&:hover': {
    opacity: 0.75,
  },
});
