import { lightTheme, Text } from '@holdbar-com/pixel';
import { Event } from '@holdbar-com/utils-types';
import {
  BlockOutlined,
  ChevronRightOutlined,
  EditOutlined,
  EventRepeatRounded,
  LanguageOutlined,
  LockOutlined,
  PauseCircleOutlined,
  PlayCircleFilledWhiteOutlined,
} from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  redirect,
  useNavigate,
} from 'react-router';
import { useSubmit } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getEvent, updateEvent } from '../../../../Api';
import { BadgeOptionType } from '../../../../Components/badge/options-badge';
import { StatusBadge } from '../../../../Components/badge/status-badge';
import { useEventAccess } from '../../../../Hooks/useEventAccess';
import { IExperience } from '../../../../Hooks/useExperience';
import { t } from '../../../../i18n/config';
import { languagesOptionValues } from '../../../../Utils/config';
import { TEvent } from '../../../../Utils/eventHelpers';
import { getEventStatus } from '../../../events/get-event-status';
import { User } from '../experience-details-page';
import { EditCapacity } from './edit-capacity';
import { EVENT_LIST_COLUMNS } from './event-list';
import { EventTime } from './event-list-item-shared';

export const EventListItemDesktop = ({
  experience,
  event,
  users,
}: {
  event: Event;
  experience: IExperience;
  users: User[];
}) => {
  const { t } = useTranslation();
  const isCancelled = event.states.statusIsCancelled;

  const languageFlags = event.languages.map(
    (langCode) => languagesOptionValues[langCode]
  );

  const bookingCount =
    event.bookings?.reduce((total, booking) => (total += booking.slots), 0) ??
    0;

  const { hasWriteAccess } = useEventAccess({
    event: event as unknown as TEvent,
  });

  const [isEditingCapacity, setIsEditingCapacity] = useState(false);

  const submit = useSubmit();
  const navigate = useNavigate();

  const options = useMemo(() => {
    // If the user does not have access to edit the event, or
    // the event is completed, there are no options available
    if (!hasWriteAccess || event.states.isCompleted) return undefined;

    const { statusIsActive, statusIsInactive } = event.states;

    const _options: BadgeOptionType[] = [];

    if (
      (statusIsActive || statusIsInactive) &&
      experience.status !== 'inactive'
    ) {
      // Add activate/deactivate option
      _options.push({
        label: t(
          `events.detailsPage.card.options.${statusIsActive ? 'deactivate' : 'activate'}`
        ),
        onClick: () =>
          submit(
            {
              eventId: event.id,
              newStatus: statusIsActive ? 'inactive' : 'active',
            },
            { method: 'post', action: 'update-event-status' }
          ),
        icon: statusIsActive ? (
          <PauseCircleOutlined />
        ) : (
          <PlayCircleFilledWhiteOutlined />
        ),
      });

      // Add edit option
      _options.push({
        label: t('events.detailsPage.card.options.edit'),
        onClick: () => navigate(`/event/${event.id}/edit`),
        icon: <EditOutlined />,
      });
    }

    return _options.length ? _options : undefined;
  }, [
    event.id,
    event.states,
    experience.status,
    hasWriteAccess,
    navigate,
    submit,
    t,
  ]);

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        gap: 3,
        padding: 2,
        transition: 'background 0.1s ease-in-out',
        '&:hover': {
          background: lightTheme.palette.neutral.n50,
        },
        display: { xs: 'none', md: 'flex' },
      }}
    >
      <Grid container columns={8} columnSpacing={3} sx={{ flexGrow: 1 }}>
        <Grid item xs={EVENT_LIST_COLUMNS[0].span}>
          <Stack>
            <Stack
              sx={{
                height: '100%',
                flexDirection: 'row',
                alignItems: 'flex-end',
                gap: 1,
              }}
            >
              <EventTypeIcon
                visibility={event.visibility}
                states={event.states}
                recurring={event.recurring}
              />
              <Text fontSize="xsmall" color={lightTheme.palette.neutral.n400}>
                {isCancelled
                  ? t(`utils.statusOptions.cancelled`)
                  : t(`experience.card.visibility.${event.visibility}`)}
              </Text>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={EVENT_LIST_COLUMNS[1].span}>
          <Stack sx={{ height: '100%', justifyContent: 'center' }}>
            <EventTime
              startDateTime={event.startDateTime}
              endDateTime={event.endDateTime}
              isCancelled={isCancelled}
            />
          </Stack>
        </Grid>
        <Grid item xs={EVENT_LIST_COLUMNS[2].span}>
          {isEditingCapacity ? (
            <EditCapacity
              event={event}
              onClose={() => setIsEditingCapacity(false)}
              bookedCount={bookingCount}
            />
          ) : (
            <Stack
              sx={{
                height: '100%',
                alignItems: 'center',
                flexDirection: 'row',
                gap: 0.5,
              }}
              onClick={(e) => {
                if (!hasWriteAccess) return;
                e.preventDefault();
                setIsEditingCapacity(true);
              }}
            >
              <Text fontSize="xsmall">{bookingCount}</Text>
              <Text fontSize="xsmall">/</Text>
              {hasWriteAccess ? (
                <Text fontSize="xsmall" variant="medium" role="button">
                  {event.seatCount.value}
                </Text>
              ) : (
                <Text fontSize="xsmall">{event.seatCount.value}</Text>
              )}
            </Stack>
          )}
        </Grid>
        <Grid item xs={EVENT_LIST_COLUMNS[3].span}>
          <Stack sx={{ height: '100%', justifyContent: 'center' }}>
            <Text
              fontSize="xsmall"
              style={{
                color: isCancelled
                  ? lightTheme.palette.neutral.n300
                  : undefined,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {event.assignedGuides
                .map((userId) => {
                  const guide = users.find((user) => user.id === userId);
                  return guide?.name ?? guide?.name ?? '';
                })
                .join(', ')}
            </Text>
          </Stack>
        </Grid>
        <Grid item xs={EVENT_LIST_COLUMNS[4].span}>
          <Stack sx={{ height: '100%', justifyContent: 'center' }}>
            <Text
              fontSize="large"
              style={{
                opacity: isCancelled ? 0.5 : 'unset',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                gap: 4,
                display: 'flex',
              }}
              title={languageFlags.map((lang) => lang.label).join(' ')}
            >
              {languageFlags.map((lang) => (
                <img
                  key={`event-list-item-desktop-lang-${lang.label}`}
                  src={lang.icon}
                  alt={lang.label}
                  width={20}
                  height={20}
                />
              ))}
            </Text>
          </Stack>
        </Grid>
        <Grid item xs={EVENT_LIST_COLUMNS[5].span}>
          <StatusBadge
            type="event"
            size="small"
            state={getEventStatus(
              event.status,
              event.states,
              experience.status
            )}
            options={options}
          />
        </Grid>
      </Grid>
      <ChevronRightOutlined sx={{ color: lightTheme.palette.neutral.n200 }} />
    </Stack>
  );
};

const iconStyle = {
  color: lightTheme.palette.neutral.n300,
};

const EventTypeIcon = ({
  visibility,
  states,
  recurring,
}: Pick<Event, 'visibility' | 'states' | 'recurring'>) => {
  if (states.statusIsCancelled) return <BlockOutlined sx={iconStyle} />;
  if (visibility === 'private') return <LockOutlined sx={iconStyle} />;

  if (recurring?.selectedOptionKey === 'yes') {
    return <EventRepeatRounded sx={iconStyle} />;
  }

  return <LanguageOutlined sx={iconStyle} />;
};

export async function updateEventStatusLoader({ params }: LoaderFunctionArgs) {
  const id = params.id;

  if (!id) {
    return redirect('/experiences');
  }

  return redirect(`/experience/${id}`);
}

export async function updateEventStatusAction({
  request,
  params,
}: ActionFunctionArgs) {
  try {
    const id = params.id;
    if (!id) throw new Error('Missing id');

    const formData = await request.formData();
    const eventId = formData.get('eventId');

    if (typeof eventId === 'object') {
      throw new Error('Missing eventId');
    }

    const newStatus = formData.get('newStatus');

    if (typeof newStatus === 'object') {
      throw new Error('Missing new status');
    }

    if (!['active', 'inactive'].includes(newStatus)) {
      throw new Error('Status is invalid');
    }

    const event = await getEvent(eventId);

    await updateEvent(
      {
        ...event,
        status: newStatus,
      },
      event.id
    );

    const url = new URL(request.url);

    // Preserve query filters in the URL
    return redirect(`/experience/${id}${url.search}`);
  } catch (error) {
    captureException(error);

    toast.error(t('eventUpsert.toast.error.editEvent'));
    const url = new URL(request.url);

    return redirect(url.toString().replace('/update-event-status', ''));
  }
}
