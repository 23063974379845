import { useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';

import { ampli } from '../../../../Ampli';
import {
  INTERCOM_SETUP_TOURS,
  isStepComplete,
  useOnBoarding,
} from '../../../../Hooks/useOnBoarding';
import { useProfile } from '../../../../Hooks/useProfile';
import { useStripeIntegration } from '../../../../Sections/SettingsSections/Integrations/domain/integrations/use-stripe-integration';

export const useDashboardHeaderActions = () => {
  const { startTour } = useIntercom();

  const { companyProfileUrl } = useProfile();

  const {
    onBoarding: { data: onBoarding },
  } = useOnBoarding();

  const stripeIntegration = useStripeIntegration();

  const handleStripeClick = useCallback(() => {
    ampli.overviewStripeLinkClicked();

    if (!isStepComplete('payment', onBoarding?.items)) {
      return stripeIntegration?.onInstall?.(stripeIntegration.status)();
    }

    window.open('https://dashboard.stripe.com/dashboard', '_blank');
  }, [onBoarding?.items, stripeIntegration]);

  const handlePageClick = useCallback(() => {
    ampli.overviewMyHoldbarPageClicked();

    if (!isStepComplete('experience', onBoarding?.items)) {
      return startTour(INTERCOM_SETUP_TOURS.experience);
    }

    window.open(companyProfileUrl(), '_blank');
  }, [onBoarding?.items, companyProfileUrl, startTour]);

  return {
    handleStripeClick,
    handlePageClick,
  };
};
