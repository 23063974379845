import { useTranslate } from '../../Hooks/useTranslate';
import { Badge, BadgeProps } from './badge';
import { badgeConfigs, StatusBadgeType } from './badge-config';
import { BadgeOptionType, OptionsBadge } from './options-badge';

type StatusBadgeProps = StatusBadgeType & {
  size?: BadgeProps['size'];
  options?: BadgeOptionType[];
};

export const StatusBadge = ({
  type,
  state,
  options,
  ...props
}: StatusBadgeProps) => {
  const { t } = useTranslate(getTranslationBase(type));
  const badge = badgeConfigs[type][state];

  if (options?.length) {
    return (
      <OptionsBadge {...props} appearance={badge.appearance} options={options}>
        {badge.icon}
        {t(state)}
      </OptionsBadge>
    );
  }

  return (
    <Badge appearance={badge.appearance} {...props}>
      {badge.icon}
      {t(state)}
    </Badge>
  );
};

const getTranslationBase = (type: StatusBadgeType['type']) => {
  switch (type) {
    case 'connection':
      return 'connect';
    case 'giftcard':
      return 'giftcards.statusOptions';
    case 'integration':
      return 'utils.statusOptions.integrations';
    default:
      return 'utils.statusOptions';
  }
};
