import { Receipt } from '@holdbar-com/utils-types';
import { differenceInCalendarDays } from 'date-fns';

import { ampli } from '../../Ampli';
import { countVariants } from '../../features/bookings/booking_details/ui/booking_details_summary/domain/use_booking_details_summary';
import { TBooking } from '../../Hooks/useBookings';
import { getFormattedDate } from '../helpers';

export const eventProperties = (
  booking: TBooking,
  receipt?: Receipt | null
) => {
  const {
    id: booking_id,
    eventId: event_id,
    experienceId: experience_id,
    experienceHeadline: experience_name = '',
    startDateTime,
    created,
    items,
    source: booking_source = 'holdbar',
    channel: booking_channel = 'holdbar',
    language = '',
  } = booking;

  const bookingAmount = receipt?.financials?.grandTotalCents ?? 0;
  const currency = receipt?.financials?.currency;

  const days_until_upcoming_booking = differenceInCalendarDays(
    new Date(startDateTime),
    new Date()
  );

  const marketing_consent =
    (receipt?.metadata.marketingConsentGiven as boolean) ?? false;

  const number_of_guests = countVariants(items);

  const booking_date = getFormattedDate(new Date(created));
  const event_date = getFormattedDate(new Date(startDateTime));

  return {
    booking_id,
    event_id,
    experience_id,
    experience_name,
    days_until_upcoming_booking,
    booking_source,
    booking_channel,
    marketing_consent,
    language,
    booking_amount: bookingAmount,
    currency,
    booking_date,
    event_date,
    number_of_guests,
  };
};

export const trackBookingDetailsPageOpened = (booking: TBooking) => {
  const {
    id: booking_id,
    eventId: event_id,
    experienceId: experience_id,
    experienceHeadline: experience_name = '',
  } = booking;

  ampli.bookingDetailsPageOpened({
    booking_id,
    event_id,
    experience_id,
    experience_name,
  });
};

export const trackBookingGuestEmailCopied = (
  booking: TBooking,
  receipt?: Receipt | null
) => {
  const properties = eventProperties(booking, receipt);

  ampli.bookingGuestEmailCopied(properties);
};

export const trackBookingGuestEmailStarted = (
  booking: TBooking,
  receipt?: Receipt | null
) => {
  const properties = eventProperties(booking, receipt);

  ampli.bookingGuestEmailStarted(properties);
};

export const trackBookingInternalNoteCompleted = (
  booking: TBooking,
  text_content: string,
  receipt?: Receipt | null
) => {
  const properties = eventProperties(booking, receipt);

  ampli.bookingInternalNoteCompleted({ ...properties, text_content });
};

export const trackBookingInternalNoteStarted = (
  booking: TBooking,
  receipt?: Receipt | null
) => {
  const properties = eventProperties(booking, receipt);

  ampli.bookingInternalNoteStarted(properties);
};

export const trackBookingMoveCompleted = (
  booking: TBooking,
  source: 'popover' | 'button',
  receipt?: Receipt | null
) => {
  const properties = eventProperties(booking, receipt);

  ampli.bookingMoveCompleted({ ...properties, source });
};

export const trackBookingMoveStarted = (
  booking: TBooking,
  source: 'popover' | 'button',
  receipt?: Receipt | null
) => {
  const properties = eventProperties(booking, receipt);

  ampli.bookingMoveStarted({ ...properties, source });
};

export const trackBookingRefundCompleted = (
  booking: TBooking,
  source: 'popover' | 'button',
  receipt?: Receipt | null
) => {
  const properties = eventProperties(booking, receipt);

  ampli.bookingRefundCompleted({ ...properties, source });
};

export const trackBookingRefundExited = (
  booking: TBooking,
  source: 'popover' | 'button',
  receipt?: Receipt | null
) => {
  const properties = eventProperties(booking, receipt);

  ampli.bookingRefundExited({ ...properties, source });
};

export const trackBookingRefundStarted = (
  booking: TBooking,
  source: 'popover' | 'button',
  receipt?: Receipt | null
) => {
  const properties = eventProperties(booking, receipt);

  ampli.bookingRefundStarted({ ...properties, source });
};

export const trackBookingResendNotificationFlowCompleted = (
  booking?: TBooking,
  receipt?: Receipt | null,
  days_since_confirmation_sent?: number
) => {
  if (!booking) return;

  const properties = eventProperties(booking, receipt);

  ampli.bookingResendNotificationFlowCompleted({
    ...properties,
    days_since_confirmation_sent,
  });
};

export const trackBookingResendNotificationFlowExited = (
  booking?: TBooking,
  receipt?: Receipt | null,
  days_since_confirmation_sent?: number
) => {
  if (!booking) return;

  const properties = eventProperties(booking, receipt);

  ampli.bookingResendNotificationFlowExited({
    ...properties,
    days_since_confirmation_sent,
  });
};

export const trackBookingResendNotificationFlowStarted = (
  booking?: TBooking,
  receipt?: Receipt | null,
  days_since_confirmation_sent?: number
) => {
  if (!booking) return;

  const properties = eventProperties(booking, receipt);

  ampli.bookingResendNotificationFlowStarted({
    ...properties,
    days_since_confirmation_sent,
  });
};

export const trackBookingCheckInStarted = (
  check_in_method: 'manual' | 'qr_code',
  booking?: TBooking,
  receipt?: Receipt | null
) => {
  const properties = booking ? eventProperties(booking, receipt) : {};

  ampli.bookingCheckInStarted({
    ...properties,
    check_in_method,
  });
};

export const trackBookingCheckInCompleted = (
  check_in_method: 'manual' | 'qr_code',
  booking?: TBooking,
  receipt?: Receipt | null
) => {
  if (!booking) return;

  const properties = eventProperties(booking, receipt);

  ampli.bookingCheckInCompleted({
    ...properties,
    check_in_method,
  });
};

export const trackBookingCheckInCancelled = (
  check_in_method: 'manual' | 'qr_code',
  booking?: TBooking,
  receipt?: Receipt | null
) => {
  const properties = booking ? eventProperties(booking, receipt) : {};

  ampli.bookingCheckInCancelled({
    ...properties,
    check_in_method,
  });
};
