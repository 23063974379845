import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { CheckRounded } from '@mui/icons-material';
import { Box, CircularProgress, Dialog, Link, Stack } from '@mui/material';
import { useMemo, useState } from 'react';

import { ampli } from '../../../../Ampli';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import { getLocalizedString } from '../../../../Hooks/useLocalizedStringFormatter';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { CloseIcon } from '../../../../Icons';
import { Opportunity } from '../domain/types';

interface OpportunityDialogProps {
  opportunity: Opportunity;
  onInterest: () => Promise<void>;
}

export const OpportunityDialog = NiceModal.create<OpportunityDialogProps>(
  ({ opportunity, onInterest }) => {
    const { t, i18n } = useTranslate('opportunities');
    const { isSm } = useResponsive();
    const modal = useModal();
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
      modal.reject('opportunity-dialog');
      modal.hide();
    };

    const handleRequest = async () => {
      setLoading(true);
      await onInterest();
      setLoading(false);
      handleClose();
    };

    const buttonLabel = useMemo(() => {
      if (opportunity.isRequested) return t('request.successTitle');
      if (opportunity.destinationUrl) return t('getStarted', 'utils.generic');
      return `${t('request', 'utils.generic')} ${opportunity.name}`;
    }, [opportunity, t]);

    return (
      <Dialog
        open={modal.visible}
        onClose={handleClose}
        fullWidth
        maxWidth={'sm'}
        fullScreen={isSm}
      >
        <Stack
          gap={4}
          p={4}
          height={'100%'}
          sx={{ justifyContent: { xs: 'space-between', md: 'initial' } }}
        >
          <Stack gap={4}>
            <Stack gap={2}>
              <CloseIcon
                onClick={handleClose}
                sx={{ alignSelf: 'flex-end', cursor: 'pointer', fontSize: 32 }}
              />
              <Box component="img" src={opportunity.graphic} />
            </Stack>
            <Stack gap={3}>
              <Stack gap={1}>
                <Text fontSize="xlarge" variant="medium">
                  {opportunity.name}
                </Text>
                <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
                  {opportunity.description}
                </Text>
              </Stack>
              {opportunity.resource && (
                <Link
                  href={getLocalizedString(opportunity.resource, i18n.language)}
                  fontSize={'small'}
                  target="_blank"
                  width={'fit-content'}
                  underline="always"
                  color={lightTheme.palette.action.a300}
                  onClick={() => ampli.growthCaseStudyViewed()}
                >
                  {t('dialog.helpedOthers')}
                </Link>
              )}
            </Stack>
          </Stack>
          <Button
            variant="primary"
            size="medium"
            type="submit"
            disabled={opportunity.isRequested || loading}
            leftIcon={
              opportunity.isRequested && (
                <CheckRounded
                  htmlColor={lightTheme.palette.success.s400}
                  sx={{ mr: 1 }}
                />
              )
            }
            onClick={handleRequest}
          >
            {loading ? (
              <CircularProgress size={16} color="inherit" />
            ) : (
              buttonLabel
            )}
          </Button>
        </Stack>
      </Dialog>
    );
  }
);
