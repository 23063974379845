import { lightTheme, Text } from '@holdbar-com/pixel';
import { CloseOutlined } from '@mui/icons-material';
import { Dialog, Fade, Slide, Stack } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, PropsWithChildren } from 'react';

import useResponsive from '../../../../Hooks/layout/useResponsive';

type TagDialogWrapperProps = PropsWithChildren & {
  open: boolean;
  handleClose: () => void;
  title: string;
  description: string;
  mobileFullscreen?: boolean;
};

export const TagDialogWrapper = ({
  open,
  handleClose,
  title,
  description,
  children,
  mobileFullscreen = true,
}: TagDialogWrapperProps) => {
  const { isSm } = useResponsive();

  const shouldShowDialogInFullscreen = mobileFullscreen && isSm;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      TransitionComponent={shouldShowDialogInFullscreen ? Transition : Fade}
      fullScreen={shouldShowDialogInFullscreen}
      disableRestoreFocus
      PaperProps={{
        style: shouldShowDialogInFullscreen
          ? {
              height: '90%',
              overflow: 'auto',
              position: 'fixed',
              bottom: 0,
              width: '100%',
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }
          : { width: '100%' },
      }}
    >
      <Stack
        sx={{
          paddingTop: { xs: 5.5, sm: 4 },
          paddingInline: { xs: 2, sm: 4 },
          paddingBottom: 4,
          gap: 4,
          flexGrow: 1,
        }}
      >
        <CloseOutlined
          sx={{
            position: 'absolute',
            top: { xs: 24, sm: 32 },
            right: { xs: 24, sm: 32 },
            zIndex: 1,
            ':hover': { cursor: 'pointer' },
          }}
          onClick={handleClose}
        />
        <Stack gap={1}>
          <Text variant="medium" fontSize="xlarge">
            {title}
          </Text>
          <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
            {description}
          </Text>
        </Stack>
        {children}
      </Stack>
    </Dialog>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
