import { Button, Skeleton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { FieldError, FieldErrors, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  GetInvitationResult,
  InvitationStatus,
  useAuth,
} from '../../Hooks/useAuth';
import type { TInput } from './AuthForm';
import { AuthForm } from './AuthForm';
import { AuthLayout } from './AuthLayout';
import { SignupType } from '../../Hooks/useProfile';

interface ILoginFormValues {
  email: string;
  password: string;
  repeatPassword: string;
}

const _inputs: Array<TInput<ILoginFormValues>> = [
  {
    key: 'email',
    type: 'email',
    isRequired: false,
    autoFocus: true,
    variant: 'outlined',
    InputLabelProps: { shrink: true },
  },
  {
    key: 'password',
    type: 'password',
    isRequired: false,
    variant: 'outlined',
  },
  {
    key: 'repeatPassword',
    type: 'password',
    isRequired: false,
    autoFocus: false,
    variant: 'outlined',
  },
];

export const CreateAccountPage = () => {
  const { t } = useTranslation();

  const { hash, search } = useLocation();

  const { setValue, reset } = useFormContext();

  const navigate = useNavigate();
  const [inputs, setInputs] = useState(_inputs);
  const { createAccount, getInvitation, acceptInvitation } = useAuth();
  const [errors, setErrors] = useState<FieldErrors>({});
  const [inviteEmail, setInviteEmail] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [invitationStatus, setInvitationStatus] = useState<
    InvitationStatus | undefined
  >(undefined);
  const [flow, setFlow] = useState<'create' | 'invitation' | undefined>(
    undefined
  );

  async function getEmail() {
    if (hash) {
      const invitation = await getInvitation(hash.substring(1)).catch((err) => {
        const e = err as GetInvitationResult;
        if (e) {
          setInvitationStatus(e.status);
          switch (e.status) {
            case InvitationStatus.EXPIRED:
              setDescription(t('auth.errors.invitationExpired'));
              break;
            case InvitationStatus.NOT_FOUND:
              setDescription(t('auth.errors.invitationNotFound'));
              break;
            default:
              setDescription(t('auth.errors.invitation'));
              break;
          }
        } else {
          setInvitationStatus(InvitationStatus.INVALID);
        }
      });
      if (invitation?.email) {
        setInvitationStatus(InvitationStatus.VALID);
        setValue('email', invitation.email);
        setInviteEmail(invitation.email);
      }

      if (invitation?.companyName && invitation?.inviterName) {
        setDescription(
          t('auth.acceptInvitation.description', {
            companyName: invitation.companyName,
            inviterName: invitation.inviterName,
          })
        );
      }
    }

    setInputs((p) =>
      p.map((input) => {
        return input.key === 'email' ? { ...input, isRequired: true } : input;
      })
    );
  }

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    if (hash) {
      // Load an invitation
      setFlow('invitation');
      getEmail();
    } else {
      // Create new account
      setFlow('create');
    }
  }, [hash]);

  // TODO: Check if an account already exists when typing the email, and if so, hide the second password field and change title of form to "Login"

  const onSubmit = async (data: ILoginFormValues) => {
    const { email, password, repeatPassword } = data;
    if (password !== repeatPassword) {
      return setErrors({
        repeatPassword: {
          message: t('auth.errors.passwordShouldMatch') as FieldError,
        },
      });
    }
    try {
      if (hash) {
        // If the email value is set, we use that one instead of the inviteEmail.
        // This is because the user is allowed to actually change the email, which
        // they want to accept the invitation with.
        await acceptInvitation(
          email ?? inviteEmail,
          hash.substring(1),
          password
        );
      } else {
        // We should remove this as this page is most likely not used anymore
        await createAccount(email, password, SignupType.SelfSignup);
      }

      // If coming from an invitation from the preview onboarding flow, we skip the welcome and use the product-tour instead
      if (hash && search.includes('preview')) {
        navigate('/dashboard#product-tour', { replace: true });
        return;
      }

      navigate('/welcome', { replace: true });
    } catch (err: any) {
      // Possible errors from err.message:
      switch (err.message) {
        case 'INVALID_PASSWORD':
          setErrors({
            password: {
              message: t('auth.errors.invalidPassword'),
            } as FieldError,
          });
          break;

        case 'INVITATION_INVALID':
          setErrors({
            email: {
              message: t('auth.errors.invitationInvalid'),
            } as FieldError,
          });
          break;

        case 'INVALID_EMAIL':
          setErrors({
            email: { message: t('auth.errors.invalidEmail') } as FieldError,
          });
          break;

        case 'INVITATION_EXPIRED':
          setErrors({
            email: {
              message: t('auth.errors.invitationExpired'),
            } as FieldError,
          });
          break;
        case 'USER_ALREADY_EXISTS': // Happens during the create flow, if a user with the same email already exists
          setErrors({
            email: { message: t('auth.errors.userExists') } as FieldError,
          });
          break;

        default:
          setErrors({
            email: { message: t('auth.errors.cannotAccept') } as FieldError,
          });
          break;
      }
    }
  };

  const handleGoToLogin = () => {
    navigate('/login');
  };

  if (!flow || (flow === 'invitation' && invitationStatus === undefined)) {
    return (
      <AuthLayout title="" description="">
        <Skeleton width={100} />
      </AuthLayout>
    );
  }

  if (
    flow === 'invitation' &&
    invitationStatus !== undefined &&
    invitationStatus !== InvitationStatus.VALID
  ) {
    return (
      <AuthLayout
        title={t('auth.errors.invalidInvitationTitle')}
        description={description}
      >
        <Stack direction={'column'} mt={{ xs: 4, md: 8 }} spacing={0.5}>
          <Button onClick={handleGoToLogin}>{t('auth.buttons.login')}</Button>
        </Stack>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout
      title={t('auth.titles.createAccount')}
      description={description}
    >
      <AuthForm
        inputs={inputs}
        shouldAcceptTerms={true}
        onSubmit={onSubmit}
        errors={errors}
      />
      <Stack direction={'column'} mt={{ xs: 4, md: 8 }} spacing={0.5}>
        <Button onClick={handleGoToLogin}>{t('auth.buttons.login')}</Button>
      </Stack>
    </AuthLayout>
  );
};
