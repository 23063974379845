import { Button } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';
import { useTranslate } from '../../../../../../../../Hooks/useTranslate';
import { useBookingDetailsContext } from '../../../../../domain/use_booking_details_context';

export const BookingDetailsSummaryReceiptError = () => {
  const { receipt } = useBookingDetailsContext();

  const { t } = useTranslate('dialogs.errorRetry');

  return (
    <Stack gap={1}>
      {t('generic', 'utils.errors')}
      <Button
        variant="secondary"
        size="small"
        aria-label={t('confirmLabel')}
        width="fit-content"
        onClick={() => receipt.refetch()}
      >
        {t('confirmLabel')}
      </Button>
    </Stack>
  );
};
