import { CompanyProfile } from '@holdbar-com/utils-types';
import { useMediaQuery } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactNode, useMemo } from 'react';

import {
  BookingsIcon,
  CalendarIcon,
  DashboardIcon,
  ExperienceIcon,
  GiftcardIcon,
  GrowthIcon,
  SettingsIcon,
  StorefrontIcon,
} from '../Icons';
import { useAuth } from './useAuth';
import { useProfile } from './useProfile';

export type MenuItem = {
  icon: ReactNode;
  path: string;
  allowedOnMobile: boolean;
  matches?: string[];
  flag?: string;
  scopes?: string[];
  onFilter?: (companyProfile: CompanyProfile) => boolean;
  isFrontPage?: boolean;
};

export const _menuItems: MenuItem[] = [
  {
    icon: <DashboardIcon />,
    path: 'dashboard',
    allowedOnMobile: true,
    scopes: ['company'],
    isFrontPage: true,
  },
  {
    icon: <ExperienceIcon />,
    path: 'experiences',
    matches: ['experience', 'event'],
    allowedOnMobile: true,
    scopes: ['experience'],
    isFrontPage: true,
  },
  {
    icon: <CalendarIcon />,
    path: 'calendar',
    allowedOnMobile: true,
    scopes: ['event'],
    isFrontPage: true,
  },
  {
    icon: <BookingsIcon />,
    path: 'bookings',
    allowedOnMobile: false,
    scopes: ['booking'],
  },
  {
    icon: <GiftcardIcon />,
    path: 'giftcards',
    allowedOnMobile: false,
    scopes: ['giftCard'],
    onFilter: (companyProfile: CompanyProfile) => {
      return companyProfile?.features?.giftCards?.enabled ?? false;
    },
  },
  {
    icon: <StorefrontIcon />,
    path: 'storefront',
    flag: 'menuItemStoreFront',
    allowedOnMobile: true,
    scopes: ['company.write'],
  },
  {
    icon: <GrowthIcon />,
    path: 'growth',
    flag: 'menuItemMarketing',
    allowedOnMobile: true,
    scopes: ['marketing'],
  },
  {
    icon: <SettingsIcon />,
    path: 'settings',
    matches: ['settings'],
    allowedOnMobile: true,
    scopes: ['profile'],
  },
];

export const useMenu = () => {
  const flags = useFlags();

  const { canAccess, userScopes } = useAuth();
  const { company } = useProfile();
  const isMdOrHigher = useMediaQuery('(min-width: 960px)');

  // Filter menu items based on access level,
  // mobile UI and feature flags
  const menuItems = useMemo(() => {
    return (
      _menuItems
        // Access Level filter
        .filter(({ scopes, onFilter }) => {
          if (onFilter && company.data && !onFilter(company.data)) {
            return false;
          }

          // If a user does not have any write access, the dashboard will be hidden from the menu
          if (
            !scopes &&
            !userScopes.some((scope) => scope.includes('company'))
          ) {
            return false;
          }

          if (!scopes) {
            return true;
          }

          // Check if the user has read or write access to the given scope
          return scopes.some((scope) => canAccess(scope));
        })

        // Feature flags
        .filter((el) => el.flag === undefined || (flags && flags[el.flag]))

        // Mobile filter
        .filter((el) => isMdOrHigher || el.allowedOnMobile)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userScopes, company, flags, isMdOrHigher]);

  const frontPageBasedOnScopes = useMemo(
    () =>
      menuItems.find(
        (el) =>
          el.isFrontPage &&
          (!el.scopes || el.scopes.some((scope) => canAccess(scope)))
      )?.path,
    [menuItems, canAccess]
  );

  return {
    menuItems,
    frontPageBasedOnScopes,
  };
};
