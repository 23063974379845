import styled from '@emotion/styled';
import { Button, lightTheme, LinkButton, Text } from '@holdbar-com/pixel';
import { EventStatus, ExperienceStatus } from '@holdbar-com/utils-types';
import { ArrowBackOutlined, MoreVert } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { Popover } from '@radix-ui/themes';
import { useState } from 'react';
import {
  useHref,
  useLinkClickHandler,
  useLocation,
  useNavigate,
  useNavigation,
  useSubmit,
} from 'react-router-dom';

import { ShowForScope } from '../../../Components/AllowForScope/AllowForScope';
import { StatusBadge } from '../../../Components/badge/status-badge';
import {
  StyledContextMenuItem,
  StyledPopoverContent,
} from '../../../Components/Popover/radix_popover_styles';
import { useAuth } from '../../../Hooks/useAuth';
import { useTranslate } from '../../../Hooks/useTranslate';
import {
  ExperienceAction,
  ExperienceActionLink,
} from './experience-details-actions';

type ExperienceDetailsHeaderProps = {
  title: string;
  subtitle: string;
  status: ExperienceStatus;
  actions: ExperienceAction[];
  experienceId: string;
};

export const ExperienceDetailsHeader = ({
  title,
  subtitle,
  status,
  actions,
  experienceId,
}: ExperienceDetailsHeaderProps) => {
  const submit = useSubmit();
  const { state } = useNavigation();
  const isSubmitDisabled = state !== 'idle';

  return (
    <Stack direction="row" justifyContent="space-between">
      <ExperienceDetails title={title} subtitle={subtitle} status={status} />
      <Stack
        component="form"
        sx={{
          flexDirection: 'row',
          gap: 1,
        }}
      >
        <Stack
          gap={1}
          sx={{
            flexDirection: 'row',
            gap: 1,
            display: { xs: 'none', md: 'flex' },
          }}
        >
          {actions?.map((actionButton) => {
            if (actionButton.type === 'button') {
              const onClick = () => {
                actionButton.trackingFn?.();
                submit(
                  { action: actionButton.key },
                  {
                    method: actionButton.method,
                    action: actionButton.action,
                  }
                );
              };

              return (
                <ShowForScope
                  key={actionButton.key}
                  scopes={actionButton.scopes}
                >
                  <Button
                    type="button"
                    variant={actionButton.variant}
                    size="medium"
                    style={{ color: actionButton.textColor ?? undefined }}
                    onClick={onClick}
                    disabled={isSubmitDisabled}
                    data-intercom-target={actionButton['data-intercom-target']}
                  >
                    {actionButton.label}
                  </Button>
                </ShowForScope>
              );
            }

            return (
              <ShowForScope key={actionButton.key} scopes={actionButton.scopes}>
                <ActionLinkButton
                  key={actionButton.key}
                  button={actionButton}
                  experienceId={experienceId}
                />
              </ShowForScope>
            );
          })}
        </Stack>
        <ContextMenu experienceId={experienceId} actions={actions} />
      </Stack>
    </Stack>
  );
};

const ContextMenu = ({
  experienceId,
  actions,
}: {
  experienceId: string;
  actions: ExperienceAction[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const submit = useSubmit();
  const navigate = useNavigate();
  const location = useLocation();

  const { canAccess } = useAuth();

  const canAccessContextMenu = actions.some((action) =>
    action.scopes.every(canAccess)
  );

  if (!canAccessContextMenu) return null;

  return (
    <Box sx={{ display: { md: 'none' } }}>
      <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
        <Popover.Trigger data-intercom-target="experience-actions">
          <StyledIconButton variant="secondary" size="medium">
            <MoreVert
              fontSize="small"
              sx={{
                color: 'black',
                display: 'flex',
              }}
            />
          </StyledIconButton>
        </Popover.Trigger>
        <StyledPopoverContent sideOffset={5} align="end">
          <Stack gap={1} width="100%" py={1}>
            {actions.map((action) => {
              return (
                <ShowForScope key={action.key} scopes={action.scopes}>
                  <StyledContextMenuItem
                    type="button"
                    size="medium"
                    variant="text"
                    onClick={() => {
                      setIsOpen(false);
                      if (action.type === 'button') {
                        submit(
                          { action: action.key },
                          {
                            method: action.method,
                            action: action.action,
                          }
                        );
                      } else {
                        navigate(action.to, {
                          state: {
                            experienceId,
                            returnUrl: location.pathname + location.search,
                          },
                        });
                      }
                    }}
                    style={{ color: action.textColor ?? 'unset' }}
                  >
                    <Text
                      fontSize="small"
                      style={{ color: action.textColor ?? 'unset' }}
                    >
                      {action.label}
                    </Text>
                  </StyledContextMenuItem>
                </ShowForScope>
              );
            })}
          </Stack>
        </StyledPopoverContent>
      </Popover.Root>
    </Box>
  );
};

const StyledIconButton = styled(Button)({
  width: '32px',
  height: '32px',
  padding: 'unset',
});

const ActionLinkButton = ({
  experienceId,
  button,
}: {
  experienceId: string;
  button: ExperienceActionLink;
}) => {
  const href = useHref(button.to);
  const location = useLocation();
  const actionButtonClicked = useLinkClickHandler(href, {
    state: { experienceId, returnUrl: location.pathname + location.search },
  });

  return (
    <LinkButton
      href={href}
      variant={button.variant}
      size="medium"
      style={{ color: button.textColor ?? undefined }}
      onClick={(e) => {
        button.trackingFn?.();
        actionButtonClicked(e);
      }}
      data-intercom-target={button['data-intercom-target']}
    >
      {button.label}
    </LinkButton>
  );
};

const ExperienceDetails = ({
  title,
  subtitle,
  status,
}: Pick<ExperienceDetailsHeaderProps, 'title' | 'subtitle' | 'status'>) => {
  const navigate = useNavigate();
  const handleBack = () => navigate('/experiences');

  return (
    <Stack sx={{ flexDirection: { md: 'row' }, gap: { xs: 2, md: 1 } }}>
      <StyledBackButton fontSize="small" onClick={handleBack} />
      <Stack gap={0.5}>
        <Stack direction="row" gap={2.5} alignItems="center">
          <Text variant="medium" fontSize="large">
            {title}
          </Text>
          <StatusBadge type="experience" size="small" state={status} />
        </Stack>
        <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
          {subtitle}
        </Text>
      </Stack>
    </Stack>
  );
};

const StyledBackButton = styled(ArrowBackOutlined)({
  cursor: 'pointer',
  borderRadius: '50%',
  transition: 'background-color 0.1s',
  '&:hover': {
    backgroundColor: lightTheme.palette.neutral.n100,
  },
  '&:active': {
    backgroundColor: lightTheme.palette.neutral.n200,
  },
});

export const StatusChip = ({
  status,
  fontSize = 'small',
  variant = 'pill',
}: {
  status: ExperienceStatus | EventStatus | 'done';
  fontSize?: 'small' | 'xsmall';
  variant?: 'indicator' | 'pill';
}) => {
  const { t } = useTranslate('utils.stateChips');
  const { backgroundColor, textColor } = getChipStyle(status);

  const isPill = variant === 'pill';

  return (
    <Stack
      sx={{
        paddingY: 0.5,
        paddingX: isPill ? 1 : 0.5,
        backgroundColor: isPill ? backgroundColor : textColor,
        borderRadius: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {variant === 'pill' && (
        <Text
          variant={fontSize === 'small' ? 'medium' : 'normal'}
          fontSize={fontSize}
          color={textColor}
        >
          {t(status)}
        </Text>
      )}
    </Stack>
  );
};

const getChipStyle = (status: ExperienceStatus | EventStatus | 'done') => {
  if (status.includes('cancel')) {
    return {
      backgroundColor: lightTheme.palette.error.e100,
      textColor: lightTheme.palette.error.e400,
    };
  }

  switch (status) {
    case 'active':
      return {
        backgroundColor: lightTheme.palette.success.s100,
        textColor: lightTheme.palette.success.s400,
      };
    case 'inactive':
      return {
        backgroundColor: lightTheme.palette.warning.w100,
        textColor: lightTheme.palette.warning.w400,
      };
    case 'draft':
    case 'done':
      return {
        backgroundColor: lightTheme.palette.neutral.n100,
        textColor: lightTheme.palette.neutral.n400,
      };

    default:
      return {
        backgroundColor: lightTheme.palette.neutral.n100,
        textColor: lightTheme.palette.neutral.n400,
      };
  }
};
