import { lightTheme } from '@holdbar-com/pixel';
import { AutoAwesome } from '@mui/icons-material';
import { Badge } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { useSuggestions } from '../../Hooks/data/useSuggestions';
import { MenuItem as MenuItemType } from '../../Hooks/useMenu';
import { MenuItem } from './MenuItem';

const menuItem: MenuItemType = {
  icon: <AutoAwesome />,
  path: 'suggestions',
  allowedOnMobile: true,
  scopes: ['company'],
};

export const SuggestionsMenuItem = () => {
  const suggestions = useSuggestions();
  const { t } = useTranslation();

  const {
    params: { id: locationKey },
  } = useMatch(':id/*') ?? { params: {} };

  return (
    <MenuItem
      item={menuItem}
      locationKey={locationKey}
      title={t('suggestions.title')}
      endNode={
        <Badge
          badgeContent={suggestions.data?.length}
          sx={{
            '& .MuiBadge-badge': {
              color: lightTheme.palette.contrast.white,
              backgroundColor: lightTheme.palette.action.a300,
            },
          }}
        />
      }
    />
  );
};
