import { Outlet } from 'react-router-dom';

import { ampli } from '../Ampli';
import { PageTabs } from '../Components/Page/PageTabs';
import { SimpleSkeleton } from '../Components/SimpleSkeleton/SimpleSkeleton';
import { useProfile } from '../Hooks/useProfile';
import { useTranslate } from '../Hooks/useTranslate';
import { trackStorefrontCustomizeViewed } from '../tracking/storefront/customize-events';
import { trackStorefrontImplementationViewed } from '../tracking/storefront/implementation-events';
import { trackStorefrontLanguagesViewed } from '../tracking/storefront/languages-events';
import { trackStorefrontTagManagementViewed } from '../tracking/storefront/tags-events';

export const StorefrontPage = () => {
  const { t } = useTranslate('storefront');

  const { company } = useProfile();

  const tabs = [
    {
      key: 'customize',
      scopes: ['company.write'],
      trackingFn: trackStorefrontCustomizeViewed,
    },
    {
      key: 'features',
      scopes: ['company.write'],
      trackingFn: () => ampli.storefrontFeaturesViewed(),
    },
    {
      key: 'bookingflow',
      scopes: ['company.write'],
      trackingFn: trackStorefrontImplementationViewed,
    },
    {
      key: 'language',
      scopes: ['company.write'],
      trackingFn: trackStorefrontLanguagesViewed,
    },
    {
      key: 'tags',
      scopes: ['company.write'],
      allowedOnMobile: true,
      trackingFn: trackStorefrontTagManagementViewed,
    },
  ];

  return (
    <PageTabs tabs={tabs} title={t('title')}>
      {!company.data ? <SimpleSkeleton /> : <Outlet />}
    </PageTabs>
  );
};
