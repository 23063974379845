import styled from '@emotion/styled';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { GiftCardStatus } from '@holdbar-com/utils-types';
import { ArrowBackOutlined, MoreVert } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { Popover } from '@radix-ui/themes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ShowForScope } from '../../../Components/AllowForScope/AllowForScope';
import { StatusBadge } from '../../../Components/badge/status-badge';
import { RouterLinkButton } from '../../../Components/button/router-link-button';
import {
  StyledContextMenuItem,
  StyledPopoverContent,
} from '../../../Components/Popover/radix_popover_styles';
import { useAuth } from '../../../Hooks/useAuth';

type GiftCardDetailsHeaderProps = {
  title: string;
  subtitle: string;
  status: GiftCardStatus;
  actions: GiftCardAction[];
};

type ActionSetting = {
  href: string;
  variant: 'primary' | 'secondary';
  textColor?: string;
  scopes?: string[];
};

const ACTION_CONFIG: Record<string, ActionSetting> = {
  deactivate: {
    href: `deactivate`,
    variant: 'secondary',
    textColor: lightTheme.palette.error.e300,
    scopes: ['giftCard.write'],
  },
  refund: {
    href: `refund`,
    variant: 'secondary',
    scopes: ['giftCard.write'],
  },
  adjustValue: {
    href: `adjust-value`,
    variant: 'primary',
    scopes: ['giftCard.write'],
  },
};

export type GiftCardAction = keyof typeof ACTION_CONFIG;

export const GiftCardDetailsHeader = ({
  title,
  subtitle,
  status,
  actions,
}: GiftCardDetailsHeaderProps) => {
  const { t } = useTranslation();

  const { canAccess } = useAuth();
  const hasActions = actions.some((key) =>
    ACTION_CONFIG[key].scopes?.every(canAccess)
  );

  return (
    <Stack direction="row" justifyContent="space-between">
      <GiftCardDetails title={title} subtitle={subtitle} status={status} />
      {hasActions && (
        <Stack
          sx={{
            flexDirection: 'row',
            gap: 1,
            display: { xs: 'none', md: 'flex' },
          }}
        >
          {actions.map((key) => {
            const { scopes, href, variant, textColor } = ACTION_CONFIG[key];
            return (
              <ShowForScope key={key} scopes={scopes ?? []}>
                <RouterLinkButton
                  href={href}
                  variant={variant}
                  textColor={textColor}
                >
                  {t(`giftCard.action.${key}`)}
                </RouterLinkButton>
              </ShowForScope>
            );
          })}
        </Stack>
      )}
      <ContextMenu actions={actions} />
    </Stack>
  );
};

const GiftCardDetails = ({
  title,
  subtitle,
  status,
}: Pick<GiftCardDetailsHeaderProps, 'title' | 'subtitle' | 'status'>) => {
  const navigate = useNavigate();
  const handleBack = () => navigate('/giftcards');

  return (
    <Stack sx={{ flexDirection: { md: 'row' }, gap: { xs: 2, md: 1 } }}>
      <StyledBackButton fontSize="small" onClick={handleBack} />
      <Stack gap={0.5}>
        <Stack direction="row" gap={2.5} alignItems="center">
          <Text variant="medium" fontSize="large">
            {title}
          </Text>
          <StatusBadge type="giftcard" size="small" state={status} />
        </Stack>
        <Text fontSize="xsmall" color={lightTheme.palette.neutral.n300}>
          {subtitle}
        </Text>
      </Stack>
    </Stack>
  );
};

const ContextMenu = ({ actions }: { actions: GiftCardAction[] }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const { canAccess } = useAuth();

  const canAccessContextMenu = actions.some((key) =>
    ACTION_CONFIG[key].scopes?.every(canAccess)
  );

  if (!canAccessContextMenu) return null;

  return (
    <Box sx={{ display: { md: 'none' } }}>
      <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
        <Popover.Trigger data-intercom-target="experience-actions">
          <StyledIconButton variant="secondary" size="medium">
            <MoreVert
              fontSize="small"
              sx={{
                color: 'black',
                display: 'flex',
              }}
            />
          </StyledIconButton>
        </Popover.Trigger>
        <StyledPopoverContent sideOffset={5} align="end">
          <Stack gap={1} width="100%" py={1}>
            {actions.map((key) => {
              const action = ACTION_CONFIG[key];
              return (
                <ShowForScope key={key} scopes={action.scopes ?? []}>
                  <StyledContextMenuItem
                    type="button"
                    size="medium"
                    variant="text"
                    style={{ color: action.textColor ?? 'unset' }}
                    onClick={() => navigate(action.href)}
                  >
                    <Text
                      fontSize="small"
                      style={{ color: action.textColor ?? 'unset' }}
                    >
                      {t(`giftCard.action.${key}`)}
                    </Text>
                  </StyledContextMenuItem>
                </ShowForScope>
              );
            })}
          </Stack>
        </StyledPopoverContent>
      </Popover.Root>
    </Box>
  );
};

const StyledIconButton = styled(Button)({
  width: '32px',
  height: '32px',
  padding: 'unset',
});

const StyledBackButton = styled(ArrowBackOutlined)({
  cursor: 'pointer',
  borderRadius: '50%',
  transition: 'background-color 0.1s',
  '&:hover': {
    backgroundColor: lightTheme.palette.neutral.n100,
  },
  '&:active': {
    backgroundColor: lightTheme.palette.neutral.n200,
  },
});
