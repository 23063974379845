import { lightTheme } from '@holdbar-com/pixel';
import { Divider, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

export const ExperienceDetailsCard = ({
  children,
  showDivider,
}: { showDivider?: boolean } & PropsWithChildren) => {
  return (
    <Stack
      sx={{
        paddingY: { sm: 3 },
        paddingX: { sm: 4 },
        backgroundColor: { sm: 'white' },
        borderRadius: { sm: 1 },
        borderColor: { sm: lightTheme.palette.neutral.n100 },
        borderWidth: { sm: '1px' },
        borderStyle: { sm: 'solid' },
        gap: { sm: 2 },
        height: { sm: '100%' },
      }}
      divider={
        showDivider && <Divider sx={{ display: { xs: 'none', md: 'block' } }} />
      }
    >
      {children}
    </Stack>
  );
};
