import { Box } from '@mui/material';
import { FC } from 'react';

import { DraggableVideoPreview } from '../../../Components/DraggableGallery/DraggableVideoPreview/DraggableVideoPreview';
import { getURLFromMediaItem } from '../../../Components/DraggableGallery/Media';
import { useLazyImage } from '../../../Hooks/useLazyImage';
import { LocalMediaItem } from '../preview-experience/media-preview';

type Props = {
  mediaItem: LocalMediaItem;
};

export const MediaPreview: FC<Props> = ({ mediaItem }) => {
  const url = getURLFromMediaItem(mediaItem);

  const { state } = useLazyImage({ source: url });

  if (mediaItem.type === 'video' && mediaItem.provider === 'holdbar') {
    return (
      <Box
        sx={{
          width: '130px',
          height: '130px',
          borderRadius: '12px',
          aspectRatio: '1 / 1',
        }}
      >
        <DraggableVideoPreview
          styles={videoJSStyles}
          options={{
            autoplay: true,
            loop: true,
            muted: true,
            sources: [
              {
                src: mediaItem.playback.hls,
                type: 'application/x-mpegURL',
              },
            ],
          }}
        />
      </Box>
    );
  }
  return (
    <Box
      component="img"
      src={state === 'loading' ? mediaItem.localUrl : url}
      sx={{
        display: 'block',
        width: '130px',
        height: '130px',
        borderRadius: '12px',
        aspectRatio: '1 / 1',
        objectFit: 'cover',
      }}
    />
  );
};

const videoJSStyles = {
  parent: {
    cursor: 'pointer',
    'background-color': '#F6F6F6',
    width: '100%',
    height: '100%',
    outline: 'none',
    'border-radius': '12px',
  },
  video: {
    'object-fit': 'cover',
    'border-radius': '12px',
  },
};
