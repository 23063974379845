import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Text } from '@holdbar-com/pixel';
import {
  Box,
  Button,
  Dialog,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { messageParticipants } from '../Api';
import { Loading } from '../Components/Loading/Loading';
import { useTranslate } from '../Hooks/useTranslate';
import { SendIcon } from '../Icons';
import { Header } from './Header';

export const WriteParticipantsDialog = NiceModal.create<{
  startDateTime: string;
  headline: string;
  eventId: string;
  participantCount: number;
  id: any;
}>(({ eventId, headline, participantCount, startDateTime, id }) => {
  const { t } = useTranslate('dialogs.writeParticipants');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const modal = useModal();

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleClose = () => {
    modal.remove();
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    messageParticipants(eventId, id, message)
      .then(
        (data: {
          message: string;
          warning?: { message?: string; errors: { message: string }[] };
        }) => {
          if (data.warning?.errors) {
            toast.warning(
              <div
                dangerouslySetInnerHTML={{
                  __html: t('warning', 'dialogs.writeParticipants.toast', {
                    warnings: data.warning.errors
                      .map(
                        (error) =>
                          '- ' + t(error.message, 'utils.generic.errors')
                      )
                      .join('<br/>'),
                  }).replaceAll('\n', '<br/>'),
                }}
              ></div>
            );
          } else {
            toast.success(t('success', 'dialogs.writeParticipants.toast'));
          }
          modal.resolve();
        }
      )
      .catch(() => {
        toast.error(t('error', 'dialogs.writeParticipants.toast'));
        modal.reject('WriteParticipantsDialog');
      })
      .finally(() => {
        setIsLoading(false);
        handleClose();
      });
  };

  return (
    <Dialog
      sx={{ justifyContent: 'flex-end' }}
      fullWidth
      maxWidth={'md'}
      fullScreen={isMobile}
      open={modal.visible}
      onClose={handleClose}
      disableRestoreFocus
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
          py: 4,
          px: { xs: 3, md: 4 },
        }}
      >
        <Box>
          <Header title={t('title')} onClose={handleClose} />
          <Box>
            <Typography mt={2} variant={'body1'}>
              {t('subtitle', { participantCount })}
            </Typography>
            <Typography variant={'body1'} display={'block'} mt={3}>
              {headline}
            </Typography>

            <TextField
              fullWidth
              sx={{
                mt: 4,
                '& fieldset': {
                  borderColor: 'transparent',
                },
              }}
              InputLabelProps={{
                sx: {
                  color: {
                    xs: '#8E8E93',
                    md: '3A3A3C',
                  },
                  fontWeight: {
                    xs: 500,
                    md: 600,
                  },
                },
              }}
              InputProps={{
                autoFocus: true,
                sx: {
                  border: {
                    xs: '1px solid #c7c7cc',
                    md: 'none',
                  },
                  backgroundColor: {
                    xs: '#fff',
                    md: '#F2F2F7',
                  },
                },
              }}
              multiline
              minRows={isMobile ? 6 : 10}
              onChange={(evt) => setMessage(evt.target.value)}
              label={t('placeholder')}
            />
          </Box>
        </Box>

        <Stack
          direction={'row'}
          spacing={1.5}
          sx={{ mt: { xs: 0, md: 12 } }}
          justifyContent={'flex-end'}
          alignItems="center"
          gap={2}
        >
          {(participantCount < 1 || !message) && (
            <Text color="#8E8E93">
              {participantCount < 1
                ? t('noParticipants')
                : !message
                  ? t('writeMessage')
                  : null}
            </Text>
          )}
          <Button
            sx={{ py: 1.5, mt: { xs: 2, md: 0 } }}
            variant={'contained'}
            onClick={handleSubmit}
            startIcon={isMobile ? '' : <SendIcon />}
            disabled={participantCount < 1 || !message}
          >
            {t('actions.primary')}
          </Button>
        </Stack>
      </Box>
      <Loading isLoading={isLoading} />
    </Dialog>
  );
});
