import 'react-toastify/dist/ReactToastify.min.css';
import './i18n/config';

import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import NiceModal from '@ebay/nice-modal-react';
import { StorefrontFeatures } from '@holdbar-com/utils-types';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router';
import { createBrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { ampli } from './Ampli';
import { InvitePage } from './features/auth/invite/invite-page';
import { InviteView } from './features/auth/invite/invite-view';
import { SignupView } from './features/auth/signup/signup-view';
import { useReturnUrls } from './features/auth/use-return-urls';
import {
  AcceptConnectionDialog,
  action as acceptConnectionAction,
  loader as acceptConnectionLoader,
} from './features/connect/accept/accept-dialog';
import {
  action as addConnectionAction,
  AddConnectionDialog,
  loader as addConnectionLoader,
} from './features/connect/add/add-connection-dialog';
import { ErrorElement as ConnectErrorElement } from './features/connect/components/error-dialog';
import {
  action as declineConnectionAction,
  DeclineConnectionDialog,
  loader as declineConnectionLoader,
} from './features/connect/decline/decline-dialog';
import {
  ConnectionDetailsPage,
  loader as connectionDetailsLoader,
} from './features/connect/details/connection-details-page';
import {
  action as disconnectConnectionAction,
  DisconnectConnectionDialog,
  loader as disconnectConnectionLoader,
} from './features/connect/details/disconnect-dialog';
import {
  action as editConnectionAction,
  EditConnectionDialog,
  loader as editConnectionLoader,
} from './features/connect/details/edit-dialog';
import {
  action as connectInvitationDialogAction,
  InvitationDialog,
  loader as connectInvitationDialogLoader,
} from './features/connect/invitation/invitation-dialog';
import {
  ConnectSettingsList,
  loader as connectSettingsLoader,
} from './features/connect/list/connect-settings-list';
import { ErrorElement as ConnectSettingsErrorElement } from './features/connect/list/error-element';
import { DashboardPage } from './features/dashboard/dashboard-page';
import { EventCreate } from './features/events/event_create/ui/EventCreate';
import { EventEdit } from './features/events/event_edit/ui/EventEdit';
import { EventUpsertContextProvider } from './features/events/event_upsert/domain/event_upsert_context';
import {
  editCapacityAction,
  editCapacityLoader,
} from './features/experiences/details/event-list/edit-capacity';
import {
  updateEventStatusAction,
  updateEventStatusLoader,
} from './features/experiences/details/event-list/event-list-item-desktop';
import {
  ExperienceDetailsErrorPage,
  ExperienceDetailsPage,
  loader as experienceDetailsLoader,
  updateStatusAction,
  updateStatusLoader,
} from './features/experiences/details/experience-details-page';
import AdjustValueDialog, {
  action as adjustValueAction,
} from './features/giftcards/details/adjust-value/adjust-value-dialog';
import DeactivateGiftCardDialog, {
  action as deactivateGiftCardAction,
} from './features/giftcards/details/deactivate/deactivate-gift-card-dialog';
import { ErrorDialog as GiftCardErrorDialog } from './features/giftcards/details/error-dialog';
import GiftCardDetailsPage, {
  GiftCardDetailsErrorPage,
  loader as giftCardDetailsLoader,
} from './features/giftcards/details/gift-card-details-page';
import RefundGiftCarddialog, {
  action as refundGiftCardAction,
} from './features/giftcards/details/refund/refund-gift-card-dialog';
import { GiftCardsView } from './features/giftcards/ui/GiftCardsView';
import { GeneratingPreview } from './features/preview/generating-preview/generating-preview';
import { PreviewView } from './features/preview/ui/PreviewView';
import { useIntercom } from './Hooks/use-intercom';
import { useGlobalShortcuts } from './Hooks/useGlobalShortcuts';
import { HistoryContextProvider } from './Hooks/useHistory';
import { useLocale } from './Hooks/useLocale';
import { useMenu } from './Hooks/useMenu';
import { useProfile } from './Hooks/useProfile';
import { useToastStyling } from './Hooks/useToastStyling';
import { useUsers } from './Hooks/useUsers';
import { AuthPage } from './Pages/Auth/AuthPage';
import { CreateAccountPage } from './Pages/Auth/CreateAccountPage';
import { ForgotPasswordPage } from './Pages/Auth/ForgotPasswordPage';
import { LoginPage } from './Pages/Auth/LoginPage';
import { ResetPasswordPage } from './Pages/Auth/ResetPasswordPage';
import { BookingPage } from './Pages/BookingPage';
import { BookingsPage } from './Pages/BookingsPage';
import { CalendarPage } from './Pages/CalendarPage';
import { DiscountPage } from './Pages/DiscountPage';
import { EventPage } from './Pages/EventPage';
import { ExperiencePage } from './Pages/ExperiencePage';
import { ExperiencesPage } from './Pages/ExperiencesPage';
import { GrowthPage } from './Pages/GrowthPage';
import { OnBoardingCompanySection } from './Pages/OnBoarding/OnBoardingCompanySection';
import { OnBoardingLocaleSection } from './Pages/OnBoarding/OnBoardingLocaleSection';
import { OnBoardingPage } from './Pages/OnBoarding/OnBoardingPage';
import { OnBoardingProfileSection } from './Pages/OnBoarding/OnBoardingProfileSection';
import { OnBoardingStartSection } from './Pages/OnBoarding/OnBoardingStartSection';
import { OnBoardingSuccessSection } from './Pages/OnBoarding/OnBoardingSuccessSection';
import { OnBoardingTeamSection } from './Pages/OnBoarding/OnBoardingTeamSection';
import { EconomicConfigurationPage } from './Pages/settings/economic-configuration-page';
import { EconomicDetailsPage } from './Pages/settings/economic-details-page';
import { EconomicFinalizeConnectionPage } from './Pages/settings/economic-finalize-connection-page';
import { QuickpayDetailsPage } from './Pages/settings/quickpay-details-page';
import { SettingsPage } from './Pages/SettingsPage';
import { StorefrontPage } from './Pages/StorefrontPage';
import { SuggestionsPage } from './Pages/SuggestionsPage';
import { DiscountSyiSectionDetails } from './Sections/DiscountSyiSections/DiscountSyiSectionDetails';
import { EventSyiSectionDetails } from './Sections/EventSyiSections/EventSyiSectionDetails';
import { EventSyiSectionGuides } from './Sections/EventSyiSections/EventSyiSectionGuides';
import { EventSyiSectionPracticalities } from './Sections/EventSyiSections/EventSyiSectionPracticalitites';
import { ExperienceSyiSectionCustomerInfo } from './Sections/ExperienceSyiSections/ExperienceSyiSectionCustomerInfo';
import { ExperienceSyiSectionDescription } from './Sections/ExperienceSyiSections/ExperienceSyiSectionDescription';
import { ExperienceSyiSectionPictures } from './Sections/ExperienceSyiSections/ExperienceSyiSectionPictures';
import { ExperienceSyiSectionPracticalities } from './Sections/ExperienceSyiSections/ExperienceSyiSectionPracticalities';
import { ExperienceSyiSectionPrice } from './Sections/ExperienceSyiSections/ExperienceSyiSectionPrice';
import { ExperienceSyiSectionSeats } from './Sections/ExperienceSyiSections/ExperienceSyiSectionSeats';
import { GrowthDiscountsSection } from './Sections/GrowthSections/GrowthDiscountsSection';
import { GrowthEmailsSection } from './Sections/GrowthSections/GrowthEmailSection';
import { GrowthTrackingSection } from './Sections/GrowthSections/GrowthTrackingSection';
import { GrowthOpportunitiesSection } from './Sections/GrowthSections/Opportunities/ui/growth-opportunities-section';
import { SettingsCompanySection } from './Sections/SettingsSections/Company/SettingsCompanySection';
import { SettingsIntegrationsSection } from './Sections/SettingsSections/Integrations/SettingsIntegrationsSection';
import { SettingsProfileSection } from './Sections/SettingsSections/Profile/ui/SettingsProfileSection';
import { SettingsNotificationsSection } from './Sections/SettingsSections/SettingsNotificationsSection';
import { SettingsUsersSection } from './Sections/SettingsSections/SettingsUsersSection';
import { SettingsTermsSection } from './Sections/SettingsSections/Terms/SettingsTermsSection';
import { StorefrontBookingFlowSection } from './Sections/StorefrontSections/BookingFlowSection/ui/StorefrontBookingFlowSection';
import { CustomizeSection } from './Sections/StorefrontSections/customize-section/customize-section';
import { StorefrontFeaturesSection } from './Sections/StorefrontSections/FeaturesSection/StorefrontFeaturesSection';
import { StorefrontLanguageSection } from './Sections/StorefrontSections/language-section/storefront-language-section';
import { TagManagementSection } from './Sections/StorefrontSections/tag-management/tag-management-section';
import { environment } from './Utils/environment';
import { PrivateRoute } from './Utils/PrivateRoute';

ampli.load({
  environment: environment,
});
const sessionReplayTracking = sessionReplayPlugin({
  sampleRate: 1,
});
ampli.client?.add(sessionReplayTracking);

const Root = () => {
  const { t } = useTranslation();
  const ldClient = useLDClient();
  const { me, company, userinfo } = useProfile();
  const { frontPageBasedOnScopes } = useMenu();

  const flags = useFlags();

  // Prefetch users
  useUsers();

  useEffect(() => {
    if (!ldClient) {
      return;
    }

    let ldCtx = {} as
      | {
          kind: string;
          organization?: { key: string | undefined; name: string | undefined };
          user?: {
            key: string | undefined;
            name: string | undefined;
            [key: string]: string | undefined;
          };
        }
      | {
          kind: 'user' | 'organization';
          name: string | undefined;
          key: string | undefined;
          email: string | undefined;
        };

    const ampliProps = {} as {
      userId: string | undefined;
      role: string | undefined;
      companyId: string | undefined;
      companyName: string | undefined;
      userEmail: string | undefined;
    };

    if (me.isSuccess) {
      // Ampli
      ampliProps.userId = me?.data?.id;
      ampliProps.userEmail = me?.data?.email;
    }

    if (userinfo.isSuccess) {
      // Ampli
      ampliProps.role = userinfo.data?.role;
    }

    if (company.isSuccess) {
      // Ampli
      ampliProps.companyId = company?.data?.id;
      ampliProps.companyName = company?.data?.name;
    }

    if (me.isSuccess && company.isSuccess) {
      ldCtx = {
        kind: 'multi',
        organization: {
          key: company?.data?.id,
          name: company?.data?.name,
        },
        user: {
          key: me?.data?.id,
          name: me?.data?.name,
          email: me?.data?.email,
          organization: company?.data?.id,
        },
      };
    }

    if (me.isSuccess && !company.isSuccess) {
      ldCtx = {
        kind: 'user',
        key: me?.data?.id,
        name: me?.data?.name,
        email: me?.data?.email,
      };
    }

    if (!me.isSuccess && company.isSuccess) {
      ldCtx = {
        kind: 'organization',
        key: company?.data?.id,
        name: company?.data?.name,
      };
    }

    // Identify with LaunchDarkly
    if (ldCtx.kind) {
      ldClient?.identify(ldCtx);
    }

    // Identify with Ampli
    if (ampliProps.userId) {
      const { features } = company.data ?? {};
      const enabledFeatures = Object.keys(features ?? {}).filter(
        (key) => features?.[key as keyof StorefrontFeatures]?.enabled
      );

      ampli.identify(ampliProps.userId, {
        Role: ampliProps.role,
        'Company Id': ampliProps.companyId,
        'Company Name': ampliProps.companyName,
        'Booking Reminder Enabled': !!features?.bookingReminder?.enabled,
        'Booking Reminder Time':
          features?.bookingReminder?.params?.hoursBeforeEvent,
        'Gift Card Purchase Enabled': !!features?.giftCards?.enabled,
        'Move Booking Enabled': !!features?.allowGuestsMoveBooking?.enabled,
        'Cancel Booking Enabled': !!features?.allowGuestsCancelBooking?.enabled,
        'Upcoming Events Enabled':
          !!features?.showUpcomingEventsInStorefront?.enabled,
        'Event Requests Enabled': !!features?.requests?.enabled,
        '# Features': enabledFeatures.length,
        'Feature List': enabledFeatures,
        'Is Internal': ampliProps.userEmail?.includes('@holdbar.com'),
        'Onboarding Path': me.data?.metadata?.signupType ?? undefined,
      });

      if (company.data?.id) {
        ampli.client?.setGroup('company', [company.data.id, company.data.name]);
      }
    }
  }, [
    me.isSuccess,
    me.data,
    company.isSuccess,
    company.data,
    ldClient,
    userinfo.data?.role,
    userinfo.isSuccess,
  ]);

  return (
    <HistoryContextProvider>
      <Routes>
        <Route
          path={'login'}
          element={
            <AuthPage title={t('utils.primaryNavigation.login')}>
              <LoginPage />
            </AuthPage>
          }
        />
        <Route
          path={'create'}
          element={
            <AuthPage title={t('auth.titles.createAccount')}>
              <CreateAccountPage />
            </AuthPage>
          }
        />
        <Route
          path={'preview'}
          element={
            <AuthPage title={t('auth.preview.titles.start')}>
              <PreviewView />
            </AuthPage>
          }
        />
        <Route
          path={'generating-preview/:id'}
          element={
            <AuthPage title={t('auth.preview.titles.start')}>
              <GeneratingPreview />
            </AuthPage>
          }
        />
        <Route
          path="signup"
          element={
            <AuthPage title={t('auth.titles.createAccount')}>
              <SignupView />
            </AuthPage>
          }
        />
        <Route
          path="invite"
          element={
            <AuthPage title={t('auth.titles.invite')}>
              <InvitePage>
                <InviteView />
              </InvitePage>
            </AuthPage>
          }
        />
        <Route
          path={'forgot'}
          element={
            <AuthPage title={t('auth.titles.forgotPassword')}>
              <ForgotPasswordPage />
            </AuthPage>
          }
        />
        <Route
          path={'reset'}
          element={
            <AuthPage title={t('auth.titles.resetPassword')}>
              <ResetPasswordPage />
            </AuthPage>
          }
        />
        <Route
          path={'bookings'}
          element={
            <PrivateRoute
              title={t('utils.primaryNavigation.bookings')}
              scopes={['booking']}
            >
              <BookingsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={'calendar'}
          element={
            <PrivateRoute
              title={t('utils.primaryNavigation.calendar')}
              scopes={['event']}
            >
              <CalendarPage />
            </PrivateRoute>
          }
        />
        <Route
          path={'experiences'}
          element={
            <PrivateRoute
              title={t('utils.primaryNavigation.experiences')}
              scopes={['experience']}
            >
              <ExperiencesPage />
            </PrivateRoute>
          }
        />
        <Route
          path={'giftcards'}
          element={
            <PrivateRoute
              title={t('utils.primaryNavigation.giftcards')}
              scopes={['giftCard']}
            >
              <GiftCardsView />
            </PrivateRoute>
          }
        />
        <Route
          path="discount/:id"
          element={
            <PrivateRoute showAppShell={false} scopes={['marketing']}>
              <DiscountPage />
            </PrivateRoute>
          }
        >
          <Route path={'edit'}>
            <Route index element={<Navigate to={'details'} />} />
            <Route path="details" element={<DiscountSyiSectionDetails />} />
          </Route>
          <Route path="details" element={<DiscountSyiSectionDetails />} />
          <Route index element={<Navigate to={'details'} />} />
        </Route>
        {flags.featureEventV2Create && (
          <Route
            path="event/create"
            element={
              <PrivateRoute showAppShell={false} scopes={['event.write']}>
                <EventUpsertContextProvider>
                  <EventCreate />
                </EventUpsertContextProvider>
              </PrivateRoute>
            }
          />
        )}
        {flags.featureEventV2Edit && (
          <Route
            path="event/:id/edit"
            element={
              <PrivateRoute showAppShell={false} scopes={['event.write']}>
                <EventUpsertContextProvider>
                  <EventEdit />
                </EventUpsertContextProvider>
              </PrivateRoute>
            }
          />
        )}
        <Route
          path="event/:id"
          element={
            <PrivateRoute
              changeTitle={false}
              hideContainerPadding
              scopes={['event']}
            >
              <EventPage />
            </PrivateRoute>
          }
        >
          {!flags.featureEventV2Edit && (
            <Route path={'edit'}>
              <Route index element={<Navigate to={'details'} />} />
              <Route path="details" element={<EventSyiSectionDetails />} />
              <Route path="guides" element={<EventSyiSectionGuides />} />
              <Route
                path="practicalities"
                element={<EventSyiSectionPracticalities />}
              />
            </Route>
          )}
          <Route path="details" element={<EventSyiSectionDetails />} />
          <Route path="guides" element={<EventSyiSectionGuides />} />
          <Route
            path="practicalities"
            element={<EventSyiSectionPracticalities />}
          />
          <Route index element={<></>} />
        </Route>

        <Route
          path="experience/:id"
          element={
            <PrivateRoute
              showAppShell={false}
              changeTitle={false}
              scopes={['experience']}
            >
              <ExperiencePage />
            </PrivateRoute>
          }
        >
          <Route path={'edit'}>
            <Route index element={<Navigate to={'description'} />} />
            <Route
              path="description"
              element={<ExperienceSyiSectionDescription />}
            />
            <Route path="media" element={<ExperienceSyiSectionPictures />} />
            <Route
              path="practicalities"
              element={<ExperienceSyiSectionPracticalities />}
            />
            <Route path="seats" element={<ExperienceSyiSectionSeats />} />
            <Route path="price" element={<ExperienceSyiSectionPrice />} />
            <Route
              path="customer-info"
              element={<ExperienceSyiSectionCustomerInfo />}
            />
          </Route>
          <Route
            path="description"
            element={<ExperienceSyiSectionDescription />}
          />
          <Route path="media" element={<ExperienceSyiSectionPictures />} />
          <Route
            path="practicalities"
            element={<ExperienceSyiSectionPracticalities />}
          />
          <Route path="seats" element={<ExperienceSyiSectionSeats />} />
          <Route path="price" element={<ExperienceSyiSectionPrice />} />
          <Route
            path="customer-info"
            element={<ExperienceSyiSectionCustomerInfo />}
          />
          <Route index element={<></>} />
        </Route>
        <Route
          path="booking/:id"
          element={
            <PrivateRoute
              title={t('utils.primaryNavigation.bookings')}
              hideContainerPadding
              scopes={['booking']}
            >
              <BookingPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="growth"
          element={
            <PrivateRoute
              title={t('utils.primaryNavigation.growth')}
              scopes={['marketing']}
            >
              <GrowthPage />
            </PrivateRoute>
          }
        >
          <Route index element={<Navigate to={'opportunities'} />} />
          <Route
            path="opportunities"
            element={<GrowthOpportunitiesSection />}
          />
          <Route path="discounts" element={<GrowthDiscountsSection />} />
          <Route path="emails" element={<GrowthEmailsSection />} />
          <Route path="tracking" element={<GrowthTrackingSection />} />
        </Route>
        <Route
          path="storefront"
          element={
            <PrivateRoute
              title={t('utils.primaryNavigation.storefront')}
              scopes={['company.write']}
            >
              <StorefrontPage />
            </PrivateRoute>
          }
        >
          <Route index element={<Navigate to={'customize'} />} />
          <Route path="customize" element={<CustomizeSection />} />
          <Route path="features" element={<StorefrontFeaturesSection />} />
          <Route
            path="bookingflow"
            element={<StorefrontBookingFlowSection />}
          />
          <Route path="language" element={<StorefrontLanguageSection />} />
          <Route path="tags" element={<TagManagementSection />} />
        </Route>
        <Route
          path="settings/integrations/economic"
          element={
            <PrivateRoute showAppShell={false} scopes={['company.write']}>
              <EconomicDetailsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="settings/integrations/economic/edit"
          element={
            <PrivateRoute showAppShell={false} scopes={['company.write']}>
              <EconomicConfigurationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="settings/integrations/economic/finalize"
          element={
            <PrivateRoute showAppShell={false} scopes={['company.write']}>
              <EconomicFinalizeConnectionPage />
            </PrivateRoute>
          }
        />
        <Route
          path="settings/integrations/quickpay"
          element={
            <PrivateRoute showAppShell={false} scopes={['company.write']}>
              <QuickpayDetailsPage />
            </PrivateRoute>
          }
        />

        <Route
          path="welcome"
          element={
            <PrivateRoute showAppShell={false} scopes={[]}>
              <OnBoardingPage />
            </PrivateRoute>
          }
        >
          <Route path="start" element={<OnBoardingStartSection />} />
          <Route path="profile" element={<OnBoardingProfileSection />} />
          <Route path="company" element={<OnBoardingCompanySection />} />
          <Route path="locale" element={<OnBoardingLocaleSection />} />
          <Route path="team" element={<OnBoardingTeamSection />} />
          <Route path="success" element={<OnBoardingSuccessSection />} />
          <Route index element={<Navigate to={'start'} />} />
        </Route>
        <Route
          path={'dashboard'}
          element={
            <PrivateRoute
              title={t('utils.primaryNavigation.dashboard')}
              scopes={['company']}
            >
              <DashboardPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/suggestions"
          element={
            <PrivateRoute title={t('suggestions.title')} scopes={[]}>
              <SuggestionsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={'*'}
          element={
            <Navigate to={`/${frontPageBasedOnScopes ?? 'dashboard'}`} />
          }
        />
      </Routes>
    </HistoryContextProvider>
  );
};

const router = createBrowserRouter([
  {
    // This path handles all requests that do _not_ have an entry below in the new router.
    path: '*',
    element: (
      <NiceModal.Provider>
        <Root />
      </NiceModal.Provider>
    ),
  },
  {
    path: 'settings',
    element: (
      <NiceModal.Provider>
        <PrivateRoute
          titleTranslationsKey="utils.primaryNavigation.settings"
          scopes={['profile']}
        >
          <SettingsPage />
        </PrivateRoute>
      </NiceModal.Provider>
    ),
    children: [
      { path: '', element: <Navigate to={'profile'} /> },
      { path: 'profile', element: <SettingsProfileSection /> },
      { path: 'company', element: <SettingsCompanySection /> },
      { path: 'users', element: <SettingsUsersSection /> },
      { path: 'integrations', element: <SettingsIntegrationsSection /> },
      {
        path: 'notifications',
        element: <SettingsNotificationsSection />,
      },
      { path: 'terms', element: <SettingsTermsSection /> },
      {
        path: 'connect',
        element: <ConnectSettingsList />,
        loader: connectSettingsLoader,
        errorElement: <ConnectSettingsErrorElement />,
        children: [
          {
            path: 'create',
            element: <AddConnectionDialog />,
            action: addConnectionAction,
            loader: addConnectionLoader,
            errorElement: <ConnectErrorElement />,
          },
          {
            path: 'accept/:token',
            element: <AcceptConnectionDialog />,
            loader: acceptConnectionLoader,
            action: acceptConnectionAction,
            errorElement: <ConnectErrorElement />,
          },
          {
            path: 'decline/:token',
            element: <DeclineConnectionDialog />,
            action: declineConnectionAction,
            loader: declineConnectionLoader,
          },
          {
            path: 'invitation/:token',
            element: <InvitationDialog />,
            loader: connectInvitationDialogLoader,
            action: connectInvitationDialogAction,
          },
        ],
      },
    ],
  },
  {
    path: 'settings/connect/details/:id',
    id: 'connection-details',
    element: (
      <>
        <ConnectionDetailsPage />
      </>
    ),
    loader: connectionDetailsLoader,
    children: [
      {
        path: 'disconnect',
        element: <DisconnectConnectionDialog />,
        action: disconnectConnectionAction,
        loader: disconnectConnectionLoader,
      },
      {
        path: 'edit',
        element: <EditConnectionDialog />,
        action: editConnectionAction,
        loader: editConnectionLoader,
      },
    ],
  },
  {
    path: 'experience/:id',
    element: (
      <NiceModal.Provider>
        <PrivateRoute
          changeTitle={false}
          scopes={['experience']}
          hideContainerPadding
        >
          <ExperienceDetailsPage />
        </PrivateRoute>
      </NiceModal.Provider>
    ),
    errorElement: <ExperienceDetailsErrorPage />,
    loader: experienceDetailsLoader,
    children: [
      {
        path: 'update-status',
        loader: updateStatusLoader,
        action: updateStatusAction,
      },
      {
        path: 'edit-capacity',
        loader: editCapacityLoader,
        action: editCapacityAction,
      },
      {
        path: 'update-event-status',
        loader: updateEventStatusLoader,
        action: updateEventStatusAction,
      },
    ],
  },
  {
    path: 'giftcard/:id',
    id: 'gift-card-details',
    element: (
      <NiceModal.Provider>
        <PrivateRoute
          changeTitle={false}
          scopes={['giftCard']}
          hideContainerPadding
        >
          <GiftCardDetailsPage />
        </PrivateRoute>
      </NiceModal.Provider>
    ),
    errorElement: <GiftCardDetailsErrorPage />,
    loader: giftCardDetailsLoader,
    children: [
      {
        path: 'adjust-value',
        element: <AdjustValueDialog />,
        errorElement: <GiftCardErrorDialog />,
        action: adjustValueAction,
      },
      {
        path: 'deactivate',
        element: <DeactivateGiftCardDialog />,
        errorElement: <GiftCardErrorDialog />,
        action: deactivateGiftCardAction,
      },
      {
        path: 'refund',
        element: <RefundGiftCarddialog />,
        errorElement: <GiftCardErrorDialog />,
        action: refundGiftCardAction,
      },
    ],
  },
]);

export default function App() {
  useReturnUrls();
  useToastStyling();
  useGlobalShortcuts();
  useIntercom();
  const { _locale: locale } = useLocale();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <ToastContainer />
      <RouterProvider router={router} />
    </LocalizationProvider>
  );
}
