import { renderDateTime } from '@holdbar-com/utils-date';
import {
  ConnectedItem,
  Event,
  ExperienceStatus,
  Location,
} from '@holdbar-com/utils-types';
import { Stack } from '@mui/material';
import { captureException } from '@sentry/react';
import { AxiosError } from 'axios';
import { secondsToMinutes } from 'date-fns';
import { TFunction } from 'i18next';
import isEqual from 'lodash.isequal';
import randomBytes from 'randombytes';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  redirect,
  useLoaderData,
  useRouteError,
} from 'react-router';
import { toast } from 'react-toastify';

import {
  getCompanyUsers,
  getConnections,
  getEventsInExperience,
  getExperience,
  updateExperience,
} from '../../../Api';
import { getCompanyDomain } from '../../../Api/Domains';
import { getLocationsByExperienceId } from '../../../Api/Locations';
import { getPublicCompanyProfile, getUserinfo } from '../../../Api/Profiles';
import { AppShell } from '../../../Components/AppShell/AppShell';
import { PageBreadcrumb } from '../../../Components/Page/page_breadcrumb';
import { PageBreadcrumbBreadcrumbsExperience } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/experience/PageBreadcrumbBreadcrumbsExperience';
import { PageBreadcrumbBreadcrumbsExperienceDetails } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/experience/PageBreadcrumbBreadcrumbsExperienceDetails';
import { PageBreadcrumbBreadcrumbs } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/PageBreadcrumbBreadcrumbs';
import { getLocalized } from '../../../Hooks/useBookings';
import { IExperience } from '../../../Hooks/useExperience';
import { t } from '../../../i18n/config';
import { ErrorPage } from '../../../Pages/error-page';
import { trackConnectLinkClicked } from '../../../tracking/connect/connect-events';
import { trackExperienceBookingFlowLinkCopied } from '../../../tracking/experiences/details/card/trackExperienceBookingFlowLinkCopied';
import { trackExperienceBookingFlowLinkOpened } from '../../../tracking/experiences/details/card/trackExperienceBookingFlowLinkOpened';
import { trackExperienceDetailsPageOpened } from '../../../tracking/experiences/details/card/trackExperienceDetailsPageOpened';
import {
  isEventState,
  isEventStatus,
  transformEvents,
} from '../../../Utils/eventHelpers';
import { renderDurationString } from '../../onboarding/preview-experience/experience-preview';
import { isSharedExperience } from '../utils/is-shared-experience';
import { EventListCard } from './event-list/even-list-card';
import { EventListFormInput } from './event-list/event-list-form';
import { ExperienceDetails, ExperienceDetailsItem } from './experience-details';
import {
  ExperienceAction,
  getExperienceActions,
} from './experience-details-actions';
import { ExperienceDetailsHeader } from './experience-details-header';

export type User = {
  id: string;
  name?: string;
  email?: string;
};

type LoaderData = {
  experience: IExperience;
  experienceDetails: ExperienceDetailsItem;
  experienceOtherDetails: ExperienceDetailsItem;
  eventFilters: EventListFormInput;
  events: Event[];
  maxPage: number;
  users: User[];
  actions: ExperienceAction[];
  subtitle: string;
  locations: Location[];
};

const EVENT_LIMIT = 50;

const DEFAULT_FILTER: EventListFormInput = {
  state: 'future',
  status: 'anyStatus',
  page: 1,
};

export async function loader({ params, request }: LoaderFunctionArgs) {
  const id = params.id;

  if (!id) {
    throw new Response('Invalid id', { status: 404 });
  }

  try {
    const user = await getUserinfo();
    if (!user) return redirect('/login');

    const url = new URL(request.url);
    const searchParams = url.searchParams;
    const locationIdParam = searchParams.get('locationId');
    const stateParam = searchParams.get('state');
    const statusParam = searchParams.get('status');
    const pageParam = searchParams.get('page');
    const pageNumber = pageParam ? parseInt(pageParam, 10) : null;

    const eventFilters: EventListFormInput = {
      state:
        stateParam && isEventState(stateParam)
          ? stateParam
          : DEFAULT_FILTER['state'],
      status:
        statusParam && isEventStatus(statusParam)
          ? statusParam
          : DEFAULT_FILTER['status'],
      page: pageNumber ? Math.max(pageNumber, 1) : DEFAULT_FILTER['page'],
      ...(locationIdParam && { locationId: locationIdParam }),
    };

    const experience = await getExperience(id);

    const isShared = isSharedExperience(experience);

    // Ideally, make this check in api-experiences
    if (isShared && !experience.isSharedByOwner) {
      throw new Response('Experience not found', { status: 404 });
    }

    const [
      { events: upcomingEvents, totalCount: upcomingEventsCount },
      { events: filteredEvents, totalCount: totalFilteredEvents },
      locations,
      users,
      connections,
      experienceOwnerCompanyProfile,
      experienceDetailsBaseURL,
      experienceBookingFlowBaseURL,
    ] = await Promise.all([
      getEventsInExperience(experience.ownerExperienceId, 0, 1000, {
        state: 'future',
        status: 'statusIsActive',
      }),
      getEventsInExperience(
        experience.ownerExperienceId,
        (eventFilters.page - 1) * EVENT_LIMIT,
        EVENT_LIMIT,
        {
          state: eventFilters.state,
          status: eventFilters.status,
          locationId: eventFilters.locationId,
        }
      ),
      getLocationsByExperienceId(experience.ownerExperienceId),
      getCompanyUsers(),
      getConnections(),
      isShared ? getPublicCompanyProfile(experience.ownerCompanyId) : null,
      getCompanyDomain(experience.companyId),
      isShared ? getCompanyDomain(experience.ownerCompanyId) : null,
    ]);

    const distributedToCompanies = isShared
      ? []
      : connections
          .filter(
            (connection) =>
              connection.state === 'connected' &&
              (connection.sharedExperiences === 'all' ||
                connection.sharedExperiences.includes(id))
          )
          .map(
            (connection) => (connection as ConnectedItem).connectedCompanyId
          );

    const distributorCompanyProfiles = await Promise.all(
      distributedToCompanies.map((id) => getPublicCompanyProfile(id))
    );
    const distributorCompanyNames = distributorCompanyProfiles
      .map(({ name }) => name)
      .join(', ');

    const maxPage = Math.max(Math.ceil(totalFilteredEvents / EVENT_LIMIT), 1);

    // If the page from query params is bigger than the max page
    // we redirect to the max page instead of showing an empty page
    if (eventFilters.page > maxPage) {
      url.searchParams.set('page', maxPage.toString());
      return redirect(url.href);
    }

    const { guestCount, slotsCount } = upcomingEvents.reduce(
      (total, event) => {
        const eventGuests =
          event.bookings?.reduce(
            (total, booking) => (total += booking.slots),
            0
          ) ?? 0;

        return {
          guestCount: total.guestCount + eventGuests,
          slotsCount: total.slotsCount + parseInt(event.seatCount.value, 10),
        };
      },
      { guestCount: 0, slotsCount: 0 }
    );

    const experienceDetails = [
      {
        key: 'events',
        label: t('experience.details.label.events'),
        value: t('experience.details.value.events', {
          count: upcomingEventsCount,
        }),
      },
      {
        key: 'guests',
        label: t('experience.details.label.guests'),
        value: t('experience.details.value.guests', {
          bookedCount: guestCount,
          totalCount: slotsCount,
        }),
      },
      experience.cutoffTimeSeconds && {
        key: 'cutoffTime',
        label: t('experience.details.label.cutoffTime'),
        value: t('experience.details.value.cutoffTime', {
          time: renderDurationString(
            t as TFunction,
            secondsToMinutes(experience.cutoffTimeSeconds)
          ),
        }),
      },
      {
        key: 'language',
        label: t('experience.details.label.language'),
        value: experience.languages
          .map((langCode) => t(`utils.languages.${langCode}`))
          .join(', '),
      },
      isShared && {
        key: 'owner',
        label: t('experience.details.label.owner'),
        value: experienceOwnerCompanyProfile?.name,
        onClickTrackingFn: () => trackConnectLinkClicked('experience'),
      },
      distributedToCompanies.length && {
        key: 'distributor',
        label: t('experience.details.label.distributor'),
        value: distributorCompanyNames,
        onClickTrackingFn: () => trackConnectLinkClicked('experience'),
      },
    ].filter(Boolean);

    // The tracking events use the slots property on TEvent,
    // which is done in useGetEvents
    const mappedEvents = transformEvents(upcomingEvents);

    const experienceOtherDetails: ExperienceDetailsItem = [
      {
        key: 'creationDate',
        label: t('experience.details.label.creationDate'),
        value: renderDateTime(experience.dates.created),
      },
      {
        key: 'detailsLink',
        label: t('experience.details.label.detailsPage'),
        value: t('experience.details.value.detailsPage'),
        link: `https://${experienceDetailsBaseURL}/experience/${experience.id}`,
      },
      {
        key: 'bookingLink',
        label: t('experience.details.label.bookingLink'),
        value: t('experience.details.value.bookingLink'),
        // Booking flow base URL only exists if the experience is shared,
        // so we simple use the details base URL instead if it does not exist.
        // If the experience is shared, we also add the current company's id
        // as the distributor id.
        link: `https://${experienceBookingFlowBaseURL ?? experienceDetailsBaseURL}/booking/${experience.ownerExperienceId}${isShared ? `?distributorId=${experience.companyId}` : ''}`,
        onCopyTrackingFn: () =>
          trackExperienceBookingFlowLinkCopied(mappedEvents, experience),
        onClickTrackingFn: () =>
          trackExperienceBookingFlowLinkOpened(mappedEvents, experience),
      },
    ];

    const usedFiltersURL = new URL(request.url);
    usedFiltersURL.searchParams.set('state', eventFilters.state);
    usedFiltersURL.searchParams.set('status', eventFilters.status);
    usedFiltersURL.searchParams.set('page', eventFilters.status);
    if (eventFilters.locationId) {
      usedFiltersURL.searchParams.set('page', eventFilters.locationId);
    }
    const hasChangedFilters = !isEqual(eventFilters, DEFAULT_FILTER);

    const actions = getExperienceActions(
      experience,
      mappedEvents,
      hasChangedFilters ? usedFiltersURL.search : ''
    );

    const subtitle = isShared
      ? t('experience.details.header.subtitle.sharedBy', {
          companyName: experienceOwnerCompanyProfile?.name,
        })
      : t(`experience.details.header.subtitle.${experience.visibility}`);

    trackExperienceDetailsPageOpened(experience, mappedEvents);

    return {
      experience,
      experienceDetails,
      experienceOtherDetails,
      eventFilters,
      events: filteredEvents,
      maxPage,
      users: users ?? [],
      actions,
      subtitle,
      locations,
    };
  } catch (error) {
    captureException(error);

    if ((error as AxiosError).isAxiosError) {
      throw error;
    }
    throw new Response(error as string);
  }
}

export const ExperienceDetailsPage = () => {
  const { i18n } = useTranslation();

  const {
    experience,
    experienceDetails,
    experienceOtherDetails,
    eventFilters,
    events,
    maxPage,
    users,
    actions,
    subtitle,
    locations,
  } = useLoaderData() as LoaderData;

  const queryClient = useQueryClient();

  useEffect(() => {
    // Updating the status using react router actions does not invalidate
    // the experience query, so going back to the overview would show the
    // incorrect status. Running this invalidation on mount since posting
    // to the actions will refresh the page, and re-run this invalidation
    queryClient.invalidateQueries('experiences');
  }, [queryClient]);

  return (
    <>
      <PageBreadcrumbBreadcrumbs>
        <PageBreadcrumbBreadcrumbsExperience />
        <PageBreadcrumbBreadcrumbsExperienceDetails
          experienceId={experience.id}
        />
      </PageBreadcrumbBreadcrumbs>
      <PageBreadcrumb>
        <Stack width="100%" maxWidth={1200} minWidth={350} gap={4}>
          <ExperienceDetailsHeader
            title={getLocalized(experience.headline, i18n.language) ?? ''}
            subtitle={subtitle}
            status={experience.status}
            actions={actions}
            experienceId={experience.id}
          />
          <Stack gap={2}>
            <ExperienceDetails
              experienceDetails={experienceDetails}
              experienceOtherDetails={experienceOtherDetails}
              experienceId={experience.id}
            />
            <EventListCard
              eventFilters={eventFilters}
              events={events}
              maxPage={maxPage}
              users={users}
              experience={experience}
              locations={locations}
            />
          </Stack>
        </Stack>
      </PageBreadcrumb>
    </>
  );
};

export const ExperienceDetailsErrorPage = () => {
  const error = useRouteError();

  const isAxiosError = (error as AxiosError).isAxiosError;

  return (
    <AppShell hideContainerPadding>
      <PageBreadcrumbBreadcrumbs>
        <PageBreadcrumbBreadcrumbsExperience />
      </PageBreadcrumbBreadcrumbs>
      <PageBreadcrumb>
        <ErrorPage
          resource="experience"
          error={
            isAxiosError
              ? (error as AxiosError)
              : ({ request: { status: 0 } } as AxiosError)
          }
        />
      </PageBreadcrumb>
    </AppShell>
  );
};

export async function updateStatusLoader({ params }: LoaderFunctionArgs) {
  const id = params.id;

  if (!id) {
    return redirect('/experiences');
  }

  return redirect(`/experience/${id}`);
}

export async function updateStatusAction({
  request,
  params,
}: ActionFunctionArgs) {
  const loadingToastId = randomBytes(16).toString('hex');
  toast.loading(t('experience.details.updateStatus.toast.loading'), {
    toastId: loadingToastId,
  });

  try {
    const id = params.id;
    if (!id) throw new Error('Missing id');

    const experience = await getExperience(id);

    const formData = await request.formData();
    const action = formData.get('action');
    if (!action) throw new Error('Missing action');
    if (action !== 'activate' && action !== 'deactivate') {
      throw new Error('Invalid action');
    }

    const newStatus: ExperienceStatus =
      action === 'activate' ? 'active' : 'inactive';

    await updateExperience(id, { ...experience, status: newStatus });

    toast.dismiss(loadingToastId);
    toast.success(t('experience.details.updateStatus.toast.success'), {
      delay: 500,
      autoClose: 5000,
    });

    const url = new URL(request.url);

    return redirect(`/experience/${id}${url.search}`);
  } catch (error) {
    console.error(error);
    toast.dismiss(loadingToastId);
    toast.error(t('experience.details.updateStatus.toast.error'), {
      delay: 500,
    });
    const url = new URL(request.url);

    return redirect(url.toString().replace('/update-status', ''));
  }
}
