import { Experience, SharedExperience } from '@holdbar-com/utils-types';
import { captureException } from '@sentry/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as api from '../Api';
import { ExperienceQueryOptions } from '../Api/Experience';
import { experienceSchema } from '../Pages/SyiPage/config';
import { useOnBoarding } from './useOnBoarding';
import { useProfile } from './useProfile';

export type Localized = Record<string, string>;

/** This is just an alias for now to limit number of files changed here */
export type IExperience = Experience | SharedExperience;

export type Variant = IExperience['price']['variants'][number];

export const useExperience = (
  id?: string,
  options?: ExperienceQueryOptions
) => {
  const queryClient = useQueryClient();

  const {
    company: { data: company },
  } = useProfile();
  const { updateStep } = useOnBoarding();

  const ExperienceQueryKey = ['experience', company?.id, id];
  const ExperiencesQueryKey = ['experiences', company?.id, options];

  const experience = useQuery<IExperience>(
    ExperienceQueryKey,
    async () => {
      await queryClient.cancelQueries(ExperienceQueryKey);
      return await api.getExperience(id!);
    },
    {
      enabled: !!id,
    }
  );

  const experiences = useQuery<IExperience[]>(
    ExperiencesQueryKey,
    async () => {
      await queryClient.cancelQueries(ExperienceQueryKey);
      return await api.getExperiences(options);
    },
    {
      enabled: Boolean(company?.id),
      retry: false,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      onSuccess: (data) => {
        data?.forEach((el) => {
          queryClient.setQueryData(['experience', company?.id, el.id], () => ({
            ...el,
          }));
        });
      },
    }
  );

  const updateExperience = useMutation(
    (data: IExperience) => api.updateExperience(id!, data),
    {
      onMutate: async (data) => {
        await queryClient.cancelQueries(ExperienceQueryKey);

        const previous =
          queryClient.getQueryData<IExperience>(ExperienceQueryKey);

        queryClient.setQueryData<IExperience[]>(ExperiencesQueryKey, (prev) => {
          if (!id) return prev ?? [];

          const mapped =
            prev?.map((el) => {
              return el.id === data.id ? { ...data } : el;
            }) ?? [];
          return [
            ...mapped,
            ...(data.id
              ? []
              : [
                  {
                    ...data,
                    id,
                  },
                ]),
          ];
        });

        queryClient.setQueryData<IExperience>(ExperienceQueryKey, (prev) => {
          return { ...prev!, ...data };
        });

        return { previous };
      },
      onError: (err, variables, context: any) => {
        if (context?.previous) {
          queryClient.setQueryData<IExperience>(
            ExperienceQueryKey,
            context.previous
          );
        }

        captureException(err, (scope) => {
          scope.setTransactionName('Failed to update experience');
          return scope;
        });
      },
      onSuccess: async (data, variables) => {
        await updateStep(
          { ...variables, id },
          experienceSchema(),
          'experience',
          'create'
        );
      },
      onSettled: async () => {
        queryClient.invalidateQueries(['getLocations', id]);
        queryClient.invalidateQueries('experiences');
        queryClient.invalidateQueries(['events', 'bookings']);
      },
    }
  );

  const deleteExperience = useMutation(
    (id: string) => api.deleteExperience(id),
    {
      onMutate: async (data) => {
        await queryClient.cancelQueries(ExperienceQueryKey);

        queryClient.setQueryData<IExperience[]>(ExperiencesQueryKey, (prev) => {
          return prev?.filter((el) => el.id !== id) ?? [];
        });
      },
      onSuccess: () => {
        experience.remove();
        experiences.refetch();
      },
      onSettled: () => {
        queryClient.invalidateQueries('experiences');
        queryClient.invalidateQueries(['events', 'bookings']);
      },
    }
  );

  return {
    experience,
    experiences,
    updateExperience,
    deleteExperience,
  };
};
