import styled from '@emotion/styled';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { Stack } from '@mui/material';
import { useState } from 'react';

import useResponsive from '../../Hooks/layout/useResponsive';

type BannerProps = {
  title: string;
  cta: string;
  subtitle?: string;
  icon?: string;
  highlight?: string;
  disabled?: boolean;
  onClick: () => void;
};

export const Banner = ({
  title,
  cta,
  subtitle,
  icon,
  highlight,
  disabled,
  onClick,
}: BannerProps) => {
  const { isSm } = useResponsive();
  const [hovering, setHovering] = useState(false);

  const titleParts = highlight
    ? title.split(new RegExp(`(${highlight})`, 'gi'))
    : [title];

  return (
    <StyledStack $hovering={hovering} $icon={icon}>
      <Stack gap={1}>
        <Text variant="medium" fontSize="xlarge">
          {titleParts.map((part, index) =>
            part.toLowerCase() === highlight?.toLowerCase() ? (
              <span
                key={index}
                style={{ color: lightTheme.palette.action.a500 }}
              >
                {part}
              </span>
            ) : (
              part
            )
          )}
        </Text>
        <Text fontSize="small">{subtitle}</Text>
      </Stack>
      <Button
        variant="secondary"
        size="medium"
        onClick={onClick}
        disabled={disabled}
        style={{ width: isSm ? '100%' : 'auto' }}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        {cta}
      </Button>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)<{ $hovering: boolean; $icon?: string }>`
  align-items: flex-start;
  gap: 24px;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0px 0px 24px rgba(20, 20, 20, 0.05);
  user-select: none;

  transform: scale(${({ $hovering }) => ($hovering ? 1.01 : 1)});
  -webkit-transform: scale(${({ $hovering }) => ($hovering ? 1.01 : 1)});

  box-shadow: ${({ $hovering }) =>
    $hovering
      ? '0px 4px 24px rgba(20, 20, 20, 0.1)'
      : '0px 0px 24px rgba(20, 20, 20, 0.05)'};
  background: ${({ $icon }) =>
    `${$icon ? `url(${$icon}), ` : ''}linear-gradient(to right, #ffffff, #edf0fd)`};
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;

  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
  -webkit-transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
`;
