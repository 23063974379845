import { lightTheme, Text } from '@holdbar-com/pixel';
import { Box, Button, Stack, TextField, Tooltip } from '@mui/material';
import { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Controller, useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import {
  trackStorefrontCustomizeStylingColorFieldChanged,
  trackStorefrontCustomizeStylingColorPickerOpened,
} from '../../../../../tracking/storefront/customize-events';
import { StorefrontCustomizeFormData } from '../storefront-styling-validation';

export const PrimaryColorSelect = () => {
  const { t } = useTranslate('storefront.customize');

  const [pickerOpened, setPickerOpened] = useState(false);

  const {
    control,
    formState: { errors },
  } = useFormContext<Pick<StorefrontCustomizeFormData, 'primaryColor'>>();

  return (
    <Controller
      name="primaryColor"
      control={control}
      render={({ field }) => (
        <Stack gap={1}>
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <TextField
              {...field}
              label={t('primaryColor')}
              error={!!errors.primaryColor}
              inputProps={{
                maxLength: 7,
                pattern: '^#[0-9A-Fa-f]{6}$',
              }}
              sx={{
                width: 155,
              }}
              onChange={(e) => {
                let value = e.target.value;
                if (value[0] !== '#') {
                  value = '#' + value;
                }
                field.onChange(value);
                trackStorefrontCustomizeStylingColorFieldChanged(value);
              }}
            />
            <Stack>
              <Button
                onClick={() => {
                  setPickerOpened((prev) => !prev);
                  trackStorefrontCustomizeStylingColorPickerOpened();
                }}
                sx={{
                  minWidth: 0,
                  p: 1,
                  borderRadius: 1,
                  backgroundColor: '#FFFFFF',
                  border: pickerOpened
                    ? '2px solid #1841E9'
                    : '1px solid rgba(0,0,0,0.23)',
                  width: '53px',
                  height: '53px',
                  transition: '0s',
                  '&:hover': {
                    backgroundColor: '#FFFFFF',
                    borderColor: pickerOpened ? '#1841E9' : 'rgb(0,0,0)',
                  },
                }}
              >
                <Stack
                  sx={{
                    backgroundColor: field.value,
                    width: '32px',
                    height: '32px',
                    borderRadius: 4,
                    cursor: 'pointer',
                    border: '1px solid rgba(0,0,0,0.23)',
                  }}
                />
              </Button>
              <Tooltip
                title={
                  <HexColorPicker
                    color={field.value}
                    onChange={(newColor) => field.onChange(newColor)}
                    style={{ padding: 12 }}
                  />
                }
                open={pickerOpened}
                onClose={() => setPickerOpened(false)}
              >
                <Box />
              </Tooltip>
            </Stack>
          </Stack>
          {errors.primaryColor && (
            <Text
              fontSize={'xsmall'}
              style={{
                color: lightTheme.palette.error.e300,
              }}
            >
              {errors.primaryColor.message}
            </Text>
          )}
        </Stack>
      )}
    />
  );
};
