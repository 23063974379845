import { useQuery, useQueryClient } from 'react-query';

import { getExperienceWaitlist } from '../Api/waitlist';

export const useExperienceWaitlist = (experienceId: string) => {
  const queryClient = useQueryClient();

  const ExperienceWaitlistQueryKey = ['waitlist', experienceId];

  const waitlist = useQuery(
    ExperienceWaitlistQueryKey,
    async () => {
      await queryClient.cancelQueries(ExperienceWaitlistQueryKey);
      return getExperienceWaitlist(experienceId);
    },
    {
      enabled: !!experienceId,
    }
  );

  return { waitlist };
};
