import { Button } from '@holdbar-com/pixel';
import { ArrowOutwardRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { DashboardRequestReport } from './dashboard-request-report';
import { useDashboardHeaderActions } from './use-dashboard-header-actions';

type DashboardHeaderActionsProps = {
  isNavDrawer?: boolean;
};

export const DashboardHeaderActions = ({
  isNavDrawer,
}: DashboardHeaderActionsProps) => {
  const { t } = useTranslate('dashboard');
  const { isSm } = useResponsive();

  const { handleStripeClick, handlePageClick } = useDashboardHeaderActions();

  if (isSm && !isNavDrawer) {
    return <DashboardRequestReport />;
  }

  return (
    <Stack direction={isSm ? 'column-reverse' : 'row'} gap={isSm ? 1 : 2}>
      <Button
        rightIcon={<ArrowOutwardRounded sx={{ ml: 1 }} />}
        variant="secondary"
        size="medium"
        onClick={handleStripeClick}
      >
        {t('buttons.goToStripe')}
      </Button>
      <Button
        rightIcon={<ArrowOutwardRounded sx={{ ml: 1 }} />}
        variant="primary"
        size="medium"
        onClick={handlePageClick}
      >
        {t('buttons.goToHoldbar')}
      </Button>
    </Stack>
  );
};
