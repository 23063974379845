import styled from '@emotion/styled';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import {
  ChatOutlined,
  LocationOnOutlined,
  ScheduleOutlined,
} from '@mui/icons-material';
import { Box, Stack, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { addDays, differenceInMinutes, getDate } from 'date-fns';
import { TFunction } from 'i18next';
import { useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../Hooks/useTranslate';
import { languageOptions } from '../../../Utils/config';
import { useOnboardingFlow } from '../onboarding-context';
import { DateWithTime, OnboardingFormInputs } from '../onboarding-flow-form';
import { DescriptionMarkDown } from './description-markdown';
import { MediaPreview } from './media-preview';

export const ExperiencePreview = () => {
  const { t, i18n } = useTranslate('utils.generic');

  const { watch } = useFormContext<OnboardingFormInputs>();

  const name =
    watch('experienceName') ||
    t('placeholder', 'onboarding.dialog.step.name.input')
      // Remove "E.g.", "F.eks", etc. from preview
      .split(' ')
      .slice(1)
      .join(' ');
  const location =
    watch('experienceLocation') ||
    t('placeholder', 'onboarding.dialog.step.location.input')
      .split(' ')
      .slice(1)
      .join(' ');
  const price = watch('tickets.0.price') || 349;
  const description =
    watch('experienceDescription') ||
    'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Assumenda eveniet, nesciunt quidem voluptatum sequi, cumque quae labore distinctio veritatis ducimus odit numquam esse exercitationem minima autem error aperiam doloremque ipsum.';
  const media = watch('experienceMedia');

  const watchedStartDate = watch('eventStartDate');
  const eventStartDate = watchedStartDate
    ? new Date(watchedStartDate)
    : addDays(new Date(), 1);
  const watchedEndDate = watch('eventEndDate');
  const eventEndDate = watchedEndDate
    ? new Date(watchedEndDate)
    : addDays(new Date(), 1);
  const watchedStartTime = watch('eventStartTime') || '!2:00';
  const watchedEndTime = watch('eventEndTime') || '!3:00';

  const startDate = DateWithTime(watchedStartTime, eventStartDate);
  const endDate = DateWithTime(watchedEndTime, eventEndDate);

  const duration = renderDurationString(
    t,
    Math.max(0, differenceInMinutes(endDate, startDate))
  );

  const { currentStepKey } = useOnboardingFlow();

  return (
    <Stack
      sx={{
        padding: '32px',
        overflow: 'hidden',
        height: '100%',
        gap: 2,
      }}
    >
      <MediaPreview media={media} />
      <Stack
        direction="row"
        gap={4}
        maxHeight="calc(50% - 16px)"
        position="relative"
      >
        <Stack
          gap={1}
          flexGrow={1}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          // We need overflow hidden for ellipsed text, but this cuts off the highlighting
          // Which is fixed by adding padding and negative margin
          padding="16px"
          margin="-16px"
          maxHeight="calc(100% + 32px)"
        >
          <EllipsisText
            variant="medium"
            fontSize="small"
            style={currentStepKey === 'name' ? highlughtStyling : undefined}
          >
            {name}
          </EllipsisText>
          <Stack alignSelf="flex-start" maxWidth="100%">
            <ListItem
              Icon={LocationOnOutlined}
              text={location}
              currentStep={currentStepKey === 'location'}
            />
            <ListItem
              Icon={ChatOutlined}
              text={languageOptions(i18n.language).label}
            />
            <ListItem
              Icon={ScheduleOutlined}
              text={duration.toLocaleLowerCase()}
              currentStep={currentStepKey === 'time'}
            />
          </Stack>
          <Box
            sx={currentStepKey === 'description' ? highlughtStyling : undefined}
            maxWidth="100%"
            overflow="hidden"
            maxHeight="40%"
          >
            <DescriptionMarkDown>{description}</DescriptionMarkDown>
          </Box>
        </Stack>
        <Stack
          boxShadow={lightTheme.shadows.small}
          padding={1}
          width={125}
          borderRadius={1}
          flexShrink={0}
        >
          <Stack
            gap={1}
            sx={currentStepKey === 'time' ? highlughtStyling : undefined}
          >
            <Stack
              direction="row"
              whiteSpace="nowrap"
              gap={0.5}
              maxWidth="100%"
            >
              <EllipsisText
                variant="medium"
                fontSize="tiny"
                style={
                  currentStepKey === 'price' ? highlughtStyling : undefined
                }
              >{`${t('from')} ${price}`}</EllipsisText>
              <Text
                fontSize="tiny"
                color={lightTheme.palette.neutral.n300}
              >{`/ ${t('person')}`}</Text>
            </Stack>
            <CalendarGrid start={getDate(eventStartDate)} />
            <Stack gap={0.5}>
              <StyledButton type="button" size="small" variant="primary">
                {t('book', 'storefront.customize.preview')}
              </StyledButton>
              <StyledButton type="button" size="small" variant="secondary">
                {t('buyGiftcard', 'settings.widget.buttons')}
              </StyledButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const EllipsisText = styled(Text)({
  overflow: 'hidden',
  maxWidth: '100%',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const highlughtStyling = {
  outline: '2px solid blue',
  outlineOffset: 5,
  MozOutlineRadius: 0,
};

const ListItem = ({
  Icon,
  text,
  currentStep,
}: {
  Icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
  text: string;
  currentStep?: boolean;
}) => {
  return (
    <Stack
      direction="row"
      gap={1}
      fontSize="10px"
      alignItems="center"
      alignSelf="flex-start"
      color={lightTheme.palette.neutral.n300}
      sx={currentStep ? highlughtStyling : undefined}
      maxWidth="100%"
      overflow="hidden"
    >
      <Icon fontSize="inherit" />
      <EllipsisText fontSize="tiny" color="inherit">
        {text}
      </EllipsisText>
    </Stack>
  );
};

const CalendarGrid = ({ start }: { start: number }) => {
  return (
    <StyledGrid>
      {Array(31)
        .fill(undefined)
        .map((_, i) => {
          const isActive = start === i + 1;

          return (
            <Stack
              key={i}
              justifyContent="center"
              alignItems="center"
              sx={{
                aspectRatio: 1,
                borderRadius: '100%',
                color: isActive
                  ? lightTheme.palette.action.a300
                  : lightTheme.palette.neutral.n300,
                background: isActive
                  ? lightTheme.palette.action.a100
                  : undefined,
                border: isActive
                  ? `1px solid ${lightTheme.palette.action.a300}`
                  : undefined,
              }}
            >
              <Typography fontSize="8px">{i + 1}</Typography>
            </Stack>
          );
        })}
    </StyledGrid>
  );
};

const StyledGrid = styled.div({
  display: 'grid',
  gridTemplateColumns: `repeat(7, 1fr)`,
});

const StyledButton = styled(Button)({
  fontSize: '8px',
  height: 'unset',
  padding: '2px',
  borderRadius: '4px',
});

const renderDuration = (t: TFunction, ...params: string[]) => {
  const last = params.pop();
  if (params.length === 0) {
    return last ?? '';
  }
  return params.join(', ') + ` ${t('and')} ` + last;
};

export const renderDurationString = (
  t: TFunction,
  mins: number | undefined
) => {
  if (typeof mins !== 'number') {
    return '';
  }
  const days = Math.floor(mins / (60 * 24));
  const hours = Math.floor((mins % (60 * 24)) / 60);
  const minutes = mins % 60;

  const units = [
    { keySingular: t('day'), keyPlural: t('days'), value: days },
    { keySingular: t('hour'), keyPlural: t('hours'), value: hours },
    { keySingular: t('minute'), keyPlural: t('minutes'), value: minutes },
  ]
    .filter((unit) => unit.value > 0)
    .map(({ keySingular, keyPlural, value }) => {
      const plural = value > 1;
      return `${value} ${t(plural ? keyPlural : keySingular)}`;
    });

  return renderDuration(t, ...units);
};
