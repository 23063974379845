import { Text } from '@holdbar-com/pixel';
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from '@mui/icons-material';
import { ButtonBase, Stack } from '@mui/material';
import { Popover } from '@radix-ui/themes';
import { forwardRef, PropsWithChildren, ReactNode, useState } from 'react';

import {
  StyledContextMenuItem,
  StyledPopoverContent,
} from '../Popover/radix_popover_styles';
import { Badge, BadgeProps } from './badge';

export type BadgeOptionType = {
  label: string;
  onClick: () => void;
  icon?: ReactNode;
  textColor?: string;
};

type OptionsBadgeProps = BadgeProps &
  PropsWithChildren & { options: BadgeOptionType[] };

export const OptionsBadge = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ children, options, ...props }: OptionsBadgeProps, _ref) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
        <Popover.Trigger
          onClick={(e) => {
            e.preventDefault();
            setIsOpen((prev) => !prev);
          }}
        >
          <ButtonBase
            disableRipple
            sx={{
              ':focus-visible': {
                outline: 1,
              },
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Badge {...props}>
              {children}
              {isOpen ? (
                <KeyboardArrowUpRounded fontSize="small" />
              ) : (
                <KeyboardArrowDownRounded fontSize="small" />
              )}
            </Badge>
          </ButtonBase>
        </Popover.Trigger>
        <StyledPopoverContent sideOffset={5} align="end">
          <Stack gap={1} width="100%" py={1}>
            {options.map(({ label, icon, onClick, textColor }) => {
              return (
                <StyledContextMenuItem
                  key={label}
                  leftIcon={icon}
                  size="medium"
                  variant="text"
                  onClick={(e) => {
                    e?.preventDefault();
                    e?.stopPropagation();
                    onClick();
                    setIsOpen(false);
                  }}
                  style={{ color: textColor }}
                >
                  <Text fontSize="small" style={{ color: textColor }}>
                    {label}
                  </Text>
                </StyledContextMenuItem>
              );
            })}
          </Stack>
        </StyledPopoverContent>
      </Popover.Root>
    );
  }
);

OptionsBadge.displayName = 'OptionsBadge';
