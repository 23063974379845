import { Stack } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { BookingDetailsSummaryBooking } from './ui/booking/BookingDetailsSummaryBooking';
import { BookingDetailsSummaryCustomer } from './ui/customer/BookingDetailsSummaryCustomer';
import { BookingDetailsSummaryNotes } from './ui/notes/BookingDetailsSummaryNotes';
import { BookingDetailsSummaryPayment } from './ui/payment/BookingDetailsSummaryPayment';
import { BookingDetailsSummaryNotifications } from './ui/notifications/BookingDetailsSummaryNotifications';
import { BookingDetailsSummaryMobile } from './ui/BookingDetailsSummaryMobile';
import { BookingDetailsSummaryDocuments } from './ui/documents/BookingDetailsSummaryDocuments';

export const BookingDetailsSummary = () => {
  const { isLg } = useResponsive();
  const { featureNotificationsLog } = useFlags();

  if (isLg) {
    return <BookingDetailsSummaryMobile />;
  }

  return (
    <Stack direction={'row'} width="100%" gap={2}>
      <Stack gap={2} width={'60%'}>
        <BookingDetailsSummaryBooking />
        <BookingDetailsSummaryPayment />
        {featureNotificationsLog && <BookingDetailsSummaryNotifications />}
      </Stack>
      <Stack gap={2} width={'40%'}>
        <BookingDetailsSummaryCustomer />
        <BookingDetailsSummaryNotes />
        <BookingDetailsSummaryDocuments />
      </Stack>
    </Stack>
  );
};
