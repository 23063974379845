import { Text } from '@holdbar-com/pixel';
import { DownloadRounded } from '@mui/icons-material';
import { Stack, Tooltip } from '@mui/material';
import { useQuery, useQueryClient } from 'react-query';

import { getPermissions } from '../../Api';
import { ProgressButton } from '../../Components/ProgressButton/ProgressButton';
import useResponsive from '../../Hooks/layout/useResponsive';
import { useProfile } from '../../Hooks/useProfile';
import { useTranslate } from '../../Hooks/useTranslate';
import { trackGrowthEmailsPermissionsDownloaded } from '../../tracking/growth/growth-events';

export const GrowthEmailsSection = () => {
  const { t } = useTranslate('marketing.emails');
  const { isSm } = useResponsive();

  const {
    me: { data: me },
  } = useProfile();

  const queryClient = useQueryClient();

  const PermissionsQueryKey = ['permissions'];

  const { data: permissions, isFetched } = useQuery(
    PermissionsQueryKey,
    async () => {
      await queryClient.cancelQueries(PermissionsQueryKey);
      const permissions = await getPermissions();
      return permissions;
    },
    { enabled: true }
  );

  const handleDownload = async () => {
    if (!permissions) return;
    import('xlsx').then(({ utils, writeFileXLSX }) => {
      const ws = utils.json_to_sheet(permissions);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'Data');
      writeFileXLSX(wb, `EmailPermissions.xlsx`);
    });
    trackGrowthEmailsPermissionsDownloaded(me?.id);
  };

  const isPermissionsEmpty = !permissions || !permissions.length;
  const isDownloadDisabled = !isFetched || isPermissionsEmpty;

  const tooltipTitle = !isFetched
    ? t('tooltip.listLoading')
    : isPermissionsEmpty
      ? t('tooltip.listEmpty')
      : t('tooltip.downloadList');

  return (
    <Stack
      direction={isSm ? 'column' : 'row'}
      gap={2}
      justifyContent={'space-between'}
      alignItems={'flex-start'}
    >
      <Stack maxWidth={400}>
        <Text variant={'medium'} fontSize={'large'}>
          {t('title')}
        </Text>
        <Text>{t('description')}</Text>
      </Stack>
      <Tooltip title={tooltipTitle} placement="top" arrow>
        <span>
          <ProgressButton
            size={'medium'}
            variant={'contained'}
            onClick={handleDownload}
            startIcon={<DownloadRounded />}
            label={'Download list'}
            disabled={isDownloadDisabled}
            sx={{ ml: 'unset', maxWidth: 'unset' }}
          />
        </span>
      </Tooltip>
    </Stack>
  );
};
