import { Text } from '@holdbar-com/pixel';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from '@mui/material';
import { useEffect } from 'react';

import { useTranslate } from '../../../../../Hooks/useTranslate';
import {
  trackStorefrontImplementationLanguageOpened,
  trackStorefrontImplementationLanguageSelected,
} from '../../../../../tracking/storefront/implementation-events';
import { ActionTypeEnum } from '../../domain/types';
import { useBookingFlowSectionContext } from '../../domain/use_booking_flow_section_context';

interface IntegrationLinksDropdownLanguageProps {
  languages?: string[];
}

export const IntegrationLinksDropdownLanguage = ({
  languages,
}: IntegrationLinksDropdownLanguageProps) => {
  const { t } = useTranslate('storefront.bookingflow');

  const { selectedLanguage, dispatch } = useBookingFlowSectionContext();

  useEffect(() => {
    if (languages) {
      dispatch({
        type: ActionTypeEnum.SetLanguage,
        payload: languages[0],
      });
    }
  }, [languages]);

  return (
    <FormControl disabled={!languages?.length}>
      <InputLabel sx={{ fontWeight: '600' }}>
        {t('language', 'utils.generic')}
      </InputLabel>
      <Select
        name={'language'}
        label={t('language', 'utils.generic')}
        aria-label={t('language', 'utils.generic')}
        value={selectedLanguage}
        defaultValue={languages?.[0]}
        onOpen={trackStorefrontImplementationLanguageOpened}
        onChange={(e) => {
          trackStorefrontImplementationLanguageSelected(e.target.value);
          dispatch({
            type: ActionTypeEnum.SetLanguage,
            payload: e.target.value,
          });
        }}
        sx={{ width: 160 }}
      >
        {languages?.map((language) => (
          <MenuItem key={language} value={language}>
            <StyledText fontSize="small">
              {t(`languages.${language}`, 'utils')}
            </StyledText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
`;
