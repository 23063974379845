import NiceModal from '@ebay/nice-modal-react';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  StackProps,
  Typography,
} from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { ampli } from '../../Ampli';
import { TranslatableGroup } from '../../Components/TranslatableGroup/translatable-group';
import { useStorefrontLanguages } from '../../Hooks/translatable/use-storefront-languages';
import { useTranslate } from '../../Hooks/useTranslate';
import { GenerationDialog } from '../../Modals/GenerationDialog';
import { useErrors } from '../../Pages/SyiPage/useErrors';
import { delay } from '../../Utils/delay';
import { ExperienceTagsSection } from './experience-tags-section/experience-tags-section';
import { useProfile } from '../../Hooks/useProfile';

export const SOURCE_LANG = 'da';

export const ExperienceSyiSectionDescription = () => {
  const { t } = useTranslation();
  const { clearError, getError } = useErrors();

  const { control } = useFormContext();

  const storefrontLanguages = useStorefrontLanguages();

  return (
    <Stack spacing={2}>
      <Typography variant={'h4'}>
        {t('experience.create.description.title')}
      </Typography>

      <Grid container gap={2}>
        <Grid item xs={12} md={7}>
          <TranslatableGroup
            mt={0}
            langs={storefrontLanguages ?? []}
            id={'headline'}
            label={t(`experience.create.description.headline`)}
            input={{
              type: 'input',
              maxLength: 50,
              placeholder: t(
                `experience.create.description.headlinePlaceholder`
              ),
              props: {
                InputLabelProps: {
                  shrink: true,
                },
              },
            }}
            clearError={clearError}
            error={getError('headline')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel shrink id="language-select-label">
              {t('experience.create.languages.spokenLanguages')}
            </InputLabel>
            <Controller
              name="languages"
              control={control}
              defaultValue={[]}
              render={({ field: { onChange, value } }) => {
                const controlledValue = Array.isArray(value) ? value : [];
                return (
                  <Select
                    labelId="language-select-label"
                    displayEmpty
                    multiple
                    value={controlledValue}
                    input={
                      <OutlinedInput
                        notched
                        label={t('experience.create.languages.spokenLanguages')}
                      />
                    }
                    onChange={(event) => {
                      if (event.target.value.includes('')) {
                        onChange([]);
                      } else {
                        onChange(event.target.value);
                      }
                    }}
                    renderValue={(selected) =>
                      selected.length === 0
                        ? t('experience.create.languages.notRelevant')
                        : selected
                            .map((l) => t(`utils.languages.${l}`))
                            .join(', ')
                    }
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                  >
                    <MenuItem value={''}>
                      <Checkbox checked={controlledValue.length === 0} />
                      {t('experience.create.languages.notRelevant')}
                    </MenuItem>
                    <Divider />
                    {['da', 'en', 'de', 'no', 'sv'].map((language) => (
                      <MenuItem key={language} value={language} disableRipple>
                        <Checkbox
                          checked={controlledValue.includes(language)}
                        />
                        {t(`utils.languages.${language}`)}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <div>
        <Divider sx={{ mt: 2, mb: 2 }} />
      </div>

      <TranslatableGroup
        mt={0}
        langs={storefrontLanguages ?? []}
        tip={t(`experience.create.description.practicalInfoHelpText`)}
        id={'practicalInfo'}
        title={t(`experience.create.description.practicalInfo`)}
        input={{
          type: 'editor',
          placeholder: t(
            `experience.create.description.practicalInfoPlaceholder`
          ),
        }}
        renderControls={[
          {
            position: 'above',
            render: (langCode, onUpdate) => (
              <GenerateControl
                key={langCode}
                langCode={langCode}
                onUpdate={onUpdate}
              />
            ),
          },
        ]}
        clearError={clearError}
        error={getError('practicalInfo')}
      />

      <div>
        <Divider sx={{ mt: 2, mb: 2 }} />
      </div>
      <TranslatableGroup
        mt={0}
        langs={storefrontLanguages ?? []}
        tip={t(`experience.create.description.whatsIncludedHelpText`)}
        id={'whatsIncluded'}
        title={t(`experience.create.description.whatsIncluded`)}
        input={{
          type: 'editor',
          placeholder: t(
            `experience.create.description.whatsIncludedPlaceholder`
          ),
        }}
        clearError={clearError}
        error={getError('whatsIncluded')}
      />

      <div>
        <Divider sx={{ mt: 2, mb: 2 }} />
      </div>
      <ExperienceTagsSection />
    </Stack>
  );
};

const GenerateControl = ({
  langCode,
  onUpdate,
}: StackProps & {
  langCode: string;
  onUpdate: (value: string, done: boolean) => void;
}) => {
  const {
    company: { data: companyProfile },
  } = useProfile();
  const { t } = useTranslate('utils.generic');
  const useCaret = false;
  const { id: experienceId } = useOutletContext<{ id: string }>();
  const caretDelay = new delay(625);
  const typeSpeedDelay = new delay(0);

  const headlineWatch = useWatch({ name: `headline` });
  const headline = headlineWatch?.[langCode] ?? '';
  const generatingText = t('aiGeneratingText');

  const handleClick = () => {
    ampli.generateDescriptionOpen();
    let isCancelled = false;

    if (useCaret) {
      caretDelay.close = () => {
        isCancelled = true;
      };
    }

    NiceModal.show<string>(GenerationDialog, {
      experienceId,
      experienceName: headline,
      language: langCode,
      instantReturn: useCaret,
      onClose: useCaret
        ? async () => {
            let i = 0;
            while (!isCancelled) {
              i++;
              await caretDelay.getPromise();
              onUpdate(generatingText + (i % 2 === 0 ? '▮' : ''), isCancelled);
            }
          }
        : () => {},
    }).then(async (value) => {
      if (useCaret) {
        caretDelay.cancel();
      }
      const textArr = value.trim().split(' ');
      let text = '';

      for (let i = 0; i < textArr.length; i++) {
        await typeSpeedDelay.getPromise();
        text += ' ' + textArr[i];
        onUpdate(text, i === textArr.length - 1);
      }
    });
  };

  // Company description is required to generate description.
  // If none available, we don't show the button.
  if (
    Object.values(companyProfile?.description ?? {}).every((x) => x.length <= 0)
  ) {
    return null;
  }

  const hasExperienceName = headline.length > 0;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Button
        variant={'outlined'}
        color={'secondary'}
        onClick={handleClick}
        className="toolbar-item spaced emphasized right-align"
        disabled={!hasExperienceName}
        aria-label="Generate using AI"
      >
        <i className="format magic-wand" />
        <span className={'text'}>{t('aiDescription')}</span>
      </Button>
      {hasExperienceName ? (
        ''
      ) : (
        <Typography variant={'body2'} fontStyle={'italic'}>
          {t('aiMissingExperienceName')}
        </Typography>
      )}
    </Stack>
  );
};
