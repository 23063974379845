import { Text } from '@holdbar-com/pixel';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  styled,
} from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { IExperience, useExperience } from '../../Hooks/useExperience';
import { useLocalizedStringFormatter } from '../../Hooks/useLocalizedStringFormatter';
import { trackEventFlowExperienceSelected } from '../../tracking/events/flow/details/form/trackEventFlowExperienceSelected';

type ExperienceSelectProps = {
  experienceId?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  errorMessage?: string;
  name?: string;
  onChange?: (experience: IExperience) => void;
  onOpen?: () => void;
};

export const ExperienceSelect = ({
  experienceId,
  isRequired,
  isDisabled,
  errorMessage,
  name,
  onChange,
  onOpen,
}: ExperienceSelectProps) => {
  const { t, i18n } = useTranslation();

  const {
    experiences: { data: experiences, isLoading, isError },
  } = useExperience(undefined, { type: 'owned' });

  const experienceSelected = experiences?.find(
    (experience) => experience.id === experienceId
  );

  useEffect(() => {
    if (experienceSelected) onChange?.(experienceSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceSelected, experienceId]);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const experience = experiences?.find(
        (exp) => exp.id === event.target.value
      );
      if (experience) {
        onChange?.(experience);
        trackEventFlowExperienceSelected(experience);
      }
    },
    [experiences, onChange]
  );

  const getLocalizedHeadline = useLocalizedStringFormatter();

  if (isLoading && !experiences) {
    return <Skeleton height={56} width="100%" variant="rounded" />;
  }

  if (isError) {
    return null;
  }

  const label = experiences?.length
    ? t('components.experienceSelect.label.experience')
    : t('components.experienceSelect.label.noExperiencesFound');

  return (
    <FormControl
      fullWidth
      error={!!errorMessage}
      disabled={isDisabled ?? !experiences?.length}
    >
      <InputLabel id="experience-select-label" required={isRequired}>
        {label}
      </InputLabel>
      <Select
        name={name}
        label={label}
        value={experienceSelected?.id ?? ''}
        required={isRequired}
        onChange={handleChange}
        onOpen={onOpen}
        renderValue={() =>
          getLocalizedHeadline(experienceSelected?.headline, '-')
        }
        aria-label={label}
        aria-required={isRequired}
        aria-disabled={isDisabled}
        labelId="experience-select-label"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 600,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {experiences?.map((experience) => (
          <MenuItem key={experience.id} value={experience.id}>
            <StyledText fontSize="small">
              {getLocalizedHeadline(experience.headline, '-')}
            </StyledText>
          </MenuItem>
        ))}
      </Select>
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
`;
