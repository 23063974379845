import NiceModal from '@ebay/nice-modal-react';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { isVirtualId } from '@holdbar-com/utils-events';
import {
  AddOutlined,
  ChatBubbleOutlineRounded,
  DeleteOutlined,
  DoDisturbOutlined,
  FileDownloadOutlined,
  MoreVert,
} from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import { Stack, styled, Tooltip } from '@mui/material';
import { Popover } from '@radix-ui/themes';
import { ReactNode, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ampli } from '../../../Ampli';
import { states } from '../../../Components/CustomCard/CustomCard';
import { StyledPopoverContent } from '../../../Components/Popover/radix_popover_styles';
import { useDeleteEvent } from '../../../Hooks/events/useDeleteEvent';
import { useUpdateEvent } from '../../../Hooks/events/useUpdateEvent';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useErrorRetry } from '../../../Hooks/useErrorRetry';
import { useEventAccess } from '../../../Hooks/useEventAccess';
import { useTranslate } from '../../../Hooks/useTranslate';
import {
  ConfirmDialog,
  showConfirmDialogProps,
} from '../../../Modals/ConfirmDialog';
import { OptionsDialog } from '../../../Modals/OptionsDialog';
import { getEventTitle, TEventWithTitle } from '../../../Utils/eventHelpers';
import { useEventActions } from '../event_actions/useEventActions';
import {
  EventDeletionError,
  isEventDeletionError,
} from '../event_delete/domain/errors';
import { showEventDeletionErrorDialog } from '../event_delete/ui/dialogs';
import { EventDetailsContext } from './EventDetailsView';

export const EventDetailsActions = () => {
  const { t, i18n } = useTranslate('events.detailsPage.card');
  const { isMd } = useResponsive();

  const { event, experience } = useContext(EventDetailsContext);

  const {
    showCancelDialog,
    isCancellationDisabled,
    goToEditEvent,
    isEditEventDisabled,
  } = useEventActions(
    'Event Details Actions',
    event
      ? ({
          ...event,
          title: getEventTitle(experience, i18n.language),
        } as TEventWithTitle)
      : undefined
  );

  if (!event || !experience) return null;

  return (
    <Stack
      direction="row"
      gap={1}
      data-intercom-target="event-actions"
      alignSelf="start"
    >
      {!isMd && (
        <>
          {!isCancellationDisabled && (
            <Button
              variant="secondary"
              size="medium"
              onClick={showCancelDialog}
              style={{ color: 'red' }}
            >
              {t('options.cancel')}
            </Button>
          )}
          {!isEditEventDisabled && (
            <Button variant="secondary" size="medium" onClick={goToEditEvent}>
              {t('options.edit')}
            </Button>
          )}
        </>
      )}
      <ContextMenu />
    </Stack>
  );
};

type ContextMenuItemType = {
  key: string;
  icon: ReactNode;
  action: () => void;
  textColor?: string;
};

const ContextMenu = () => {
  const { t, i18n } = useTranslate('events.detailsPage.card');

  const { event, experience, bookingsForEvent } =
    useContext(EventDetailsContext);

  const { hasWriteAccess } = useEventAccess({ event });

  const { isMd } = useResponsive();

  const navigate = useNavigate();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const { updateEvent } = useUpdateEvent();
  const { deleteEvent } = useDeleteEvent();

  const { showErrorRetryDialog } = useErrorRetry();

  const toggleStatusTo = (newStatus: keyof typeof states) => {
    ampli.eventButtonClicked({
      action: 'updateEventStatus',
      location: 'contextMenu',
    });

    updateEvent.mutateAsync({ ...event, status: newStatus });

    setIsPopoverOpen(false);
  };

  const {
    showAddBookingDialog,
    isAddBookingDisabled,
    showMessageParticipantsDialog,
    isMessageParticipantsDisabled,
    handlePrintParticipants,
    isPrintParticipantsDisabled,
    goToEditEvent,
    isEditEventDisabled,
    showCancelDialog,
    isCancellationDisabled,
  } = useEventActions('Event Details Context Menu', {
    ...event,
    title: getEventTitle(experience, i18n.language),
  } as TEventWithTitle);

  if (!bookingsForEvent || !experience) return null;

  const handleBack = () =>
    navigate(`/experience/${event?.experienceId}`, { replace: true });

  const showConfirmDeletionDialog = ({
    dialogProps,
    successMessage,
    onConfirm,
  }: showConfirmDialogProps) => {
    NiceModal.show(ConfirmDialog, dialogProps).then(async () => {
      try {
        await onConfirm().then(() => {
          handleBack();
          toast.success(successMessage);
        });
      } catch (error) {
        if (
          isEventDeletionError(error, EventDeletionError.EVENT_HAS_BOOKINGS)
        ) {
          showEventDeletionErrorDialog(t);
          return;
        }

        const retry = await showErrorRetryDialog();
        if (retry) {
          showConfirmDeletionDialog({ dialogProps, successMessage, onConfirm });
        }
      }
    });
  };

  const handleDeleteEvent = () => {
    if (!event) return;
    if (event.slots?.booked && event.slots.booked > 0) {
      showEventDeletionErrorDialog(t);
    } else if (isVirtualId(event.id)) {
      NiceModal.show<string>(OptionsDialog, {
        title: t('title', 'dialogs.deleteRecurringEvent'),
        description: t(
          'descriptionWithOptions',
          'dialogs.deleteRecurringEvent'
        ),
        buttons: [
          {
            key: 'wholeSeries',
            label: t('actions.secondary', 'dialogs.confirmDeleteRecurring'),
            props: {
              variant: 'outlined',
              color: 'error',
            },
          },
          {
            key: 'onlyThis',
            label: t('actions.primary', 'dialogs.confirmDeleteRecurring'),
            props: {
              variant: 'contained',
            },
          },
        ],
      }).then((choice: string) => {
        const id =
          choice === 'wholeSeries' ? event.parentId ?? event.id : event.id;

        deleteEvent
          .mutateAsync(id)
          .then(() => {
            handleBack();
            toast.success(
              choice === 'wholeSeries'
                ? t('toast.deletingSeriesSuccess')
                : t('toast.deletingEventSuccess')
            );
          })
          .catch(() => {
            toast.error(t('toast.deletingError'));
          })
          .finally(() => {});
      });
    } else if (event.recurring?.selectedOptionKey === 'yes') {
      showConfirmDeletionDialog({
        dialogProps: {
          title: t('title', 'dialogs.deleteRecurringEvent'),
          description: t('description', 'dialogs.deleteRecurringEvent'),
          confirmLabel: t('confirmLabel', 'dialogs.deleteRecurringEvent'),
          important: true,
        },
        successMessage: t('toast.deletingSeriesSuccess'),
        onConfirm: () => deleteEvent.mutateAsync(event.id),
      });
    } else {
      showConfirmDeletionDialog({
        dialogProps: {
          title: t('title', 'dialogs.deleteEvent'),
          description: t('description', 'dialogs.deleteEvent'),
          confirmLabel: t('confirmLabel', 'dialogs.deleteEvent'),
        },
        successMessage: t('toast.deletingEventSuccess'),
        onConfirm: () => deleteEvent.mutateAsync(event.id),
      });
    }
  };

  const isCompleted =
    event?.startDateTime && new Date(event.startDateTime) < new Date();

  const desktopActions = [
    !isAddBookingDisabled && {
      key: 'addBooking',
      icon: <AddOutlined fontSize="medium" />,
      action: async () => {
        const newEventId = await showAddBookingDialog();
        if (newEventId !== event?.id) {
          navigate(`/event/${newEventId}`);
        }
      },
    },
    !isMessageParticipantsDisabled && {
      key: 'writeParticipants',
      icon: <ChatBubbleOutlineRounded fontSize="medium" />,
      action: showMessageParticipantsDialog,
    },
    !isPrintParticipantsDisabled && {
      key: 'printList',
      icon: <FileDownloadOutlined fontSize="medium" />,
      action: () =>
        handlePrintParticipants(bookingsForEvent, experience, () =>
          setIsPopoverOpen(false)
        ),
    },
  ].filter(Boolean) as ContextMenuItemType[];

  const mobileActions = [
    !isEditEventDisabled && {
      key: 'edit',
      icon: <EditOutlinedIcon fontSize="medium" />,
      action: goToEditEvent,
    },
    !isCancellationDisabled && {
      key: 'cancel',
      icon: <DoDisturbOutlined fontSize="medium" />,
      action: showCancelDialog,
      textColor: 'red',
    },
  ] as ContextMenuItemType[];

  const isStatusChangeable = hasWriteAccess
    ? !isCompleted && event?.status !== 'cancelled'
    : false;

  const isDeletable = hasWriteAccess
    ? !bookingsForEvent || (bookingsForEvent && bookingsForEvent.length === 0)
    : false;

  const actions = [
    isStatusChangeable
      ? event?.status === 'active'
        ? {
            key: 'deactivate',
            icon: <PauseCircleOutlineOutlinedIcon fontSize="medium" />,
            action: () => toggleStatusTo('inactive'),
          }
        : {
            key: 'activate',
            icon: <PlayCircleFilledWhiteOutlinedIcon fontSize="medium" />,
            action: () => toggleStatusTo('active'),
          }
      : undefined,
    ...(isMd ? mobileActions : desktopActions),
    isDeletable && {
      key: 'delete',
      icon: <DeleteOutlined fontSize="medium" />,
      action: () => handleDeleteEvent(),
      textColor: 'red',
    },
  ].filter(Boolean) as ContextMenuItemType[];

  if (actions.length === 0) return null;

  return (
    <>
      <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <Popover.Trigger>
          <StyledIconButton
            variant="secondary"
            size="medium"
            onClick={() => {}}
          >
            <MoreVert
              fontSize="small"
              sx={{
                color: 'black',
                display: 'flex',
              }}
            />
          </StyledIconButton>
        </Popover.Trigger>
        <StyledPopoverContent sideOffset={5} align="end">
          <Stack gap={1} width="100%" py={1}>
            {actions.map((item) => {
              return (
                <StyledContextMenuItem
                  key={item.key}
                  leftIcon={item.icon}
                  size="medium"
                  variant="text"
                  onClick={item.action}
                  style={{ color: item.textColor ?? 'unset' }}
                >
                  <Text
                    fontSize="small"
                    style={{ color: item.textColor ?? 'unset' }}
                  >
                    {t(`options.${item.key}`)}
                  </Text>
                </StyledContextMenuItem>
              );
            })}
          </Stack>
        </StyledPopoverContent>
      </Popover.Root>
    </>
  );
};

const StyledContextMenuItem = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 8,
  padding: 8,
  borderRadius: 0,
  minWidth: 200,
  ':hover': { backgroundColor: lightTheme.palette.neutral.n50 },
});

const StyledIconButton = styled(Button)({
  padding: 8,
  aspectRatio: 1,
});

export const BookingListActions = () => {
  const { t } = useTranslate('events.detailsPage.card');

  const navigate = useNavigate();

  const { event, experience, bookingsForEvent } =
    useContext(EventDetailsContext);

  const { i18n } = useTranslate('utils.tables.header');

  const {
    showMessageParticipantsDialog,
    isMessageParticipantsDisabled,
    handlePrintParticipants,
    isPrintParticipantsDisabled,
    showAddBookingDialog,
    isAddBookingDisabled,
  } = useEventActions('Event Details Bookings List', {
    ...event,
    title: getEventTitle(experience, i18n.language),
  } as TEventWithTitle);

  if (!event || !experience || !bookingsForEvent) return null;

  const actions = [
    {
      key: 'printList',
      icon: (
        <FileDownloadOutlined
          fontSize="small"
          sx={{
            color: 'black',
            display: 'flex',
          }}
        />
      ),
      action: () => handlePrintParticipants(bookingsForEvent, experience),
      isDisabled: isPrintParticipantsDisabled,
    },
    {
      key: 'writeParticipants',
      icon: (
        <ChatBubbleOutlineRounded
          fontSize="small"
          sx={{
            color: 'black',
            display: 'flex',
          }}
        />
      ),
      action: showMessageParticipantsDialog,
      isDisabled: isMessageParticipantsDisabled,
    },
    {
      key: 'addBooking',
      icon: (
        <AddOutlined
          fontSize="small"
          sx={{
            color: 'black',
            display: 'flex',
          }}
        />
      ),
      action: async () => {
        const newEventId = await showAddBookingDialog();
        if (newEventId !== event?.id) {
          navigate(`/event/${newEventId}`);
        }
      },
      isDisabled: isAddBookingDisabled,
    },
  ];

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      data-intercom-target="booking-list-actions"
    >
      {actions.map((a) => {
        return (
          <Tooltip key={a.key} title={t(`options.${a.key}`)} placement="top">
            <StyledIconButton
              variant="secondary"
              size="medium"
              onClick={() => a.action()}
              disabled={a.isDisabled}
            >
              {a.icon}
            </StyledIconButton>
          </Tooltip>
        );
      })}
    </Stack>
  );
};
