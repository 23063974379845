import { lightTheme } from '@holdbar-com/pixel';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { MenuItem as MenuItemType } from '../../Hooks/useMenu';

type MenuItemProps = {
  item: MenuItemType;
  locationKey: string | undefined;
  title?: string;
  endNode?: ReactNode;
  onClick?: () => void;
};

export const MenuItem = ({
  item,
  locationKey,
  title,
  endNode,
  onClick,
}: MenuItemProps) => {
  const { t } = useTranslation();

  return (
    <Link to={`/${item.path}`} onClick={onClick}>
      <ListItem
        data-intercom-target={`navbar-${item.path}`}
        sx={{
          p: 0,
          borderRadius: 1,
          color: lightTheme.palette.neutral.n500,
        }}
      >
        <ListItemButton
          sx={{
            '&:active': {
              color: `${lightTheme.palette.action.a500} !important`,
              backgroundColor: lightTheme.palette.action.a100 + ' !important',
              '& .MuiSvgIcon-root': {
                color: `${lightTheme.palette.action.a500} !important`,
              },
            },
            py: 1.1,
            px: 2,
            borderRadius: 1,
            color: lightTheme.palette.neutral.n500,
            border: 'none',
            '&.Mui-selected': {
              borderRadius: 1,
              color: lightTheme.palette.action.a500,
              backgroundColor: lightTheme.palette.action.a100,
              '& .MuiSvgIcon-root': {
                color: lightTheme.palette.action.a500,
              },
            },
            '&:hover': {
              backgroundColor: lightTheme.palette.action.a100,
              '&.Mui-selected': {
                backgroundColor: lightTheme.palette.action.a100,
              },
              '&:not(.Mui-selected)': {
                color: lightTheme.palette.neutral.n500,
                backgroundColor: lightTheme.palette.neutral.n50,
              },
            },
          }}
          selected={
            locationKey === item.path ||
            item?.matches?.includes(locationKey as string)
          }
        >
          <ListItemIcon
            sx={{
              minWidth: '30px',
              color: lightTheme.palette.neutral.n500,
              '& .MuiSvgIcon-root': {
                fontSize: '20px',
                color: lightTheme.palette.neutral.n500,
              },
            }}
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              fontSize: lightTheme.typography.small.normal.fontSize,
            }}
            primary={title ?? t(`utils.primaryNavigation.${item.path}`)}
          />
          {endNode}
        </ListItemButton>
      </ListItem>
    </Link>
  );
};
