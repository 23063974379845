import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button } from '@holdbar-com/pixel';
import {
  Experience,
  ExperienceVariant,
  ExperienceVariantAddon,
} from '@holdbar-com/utils-types';
import {
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { captureException } from '@sentry/react';
import axios from 'axios';
import isEqual from 'lodash.isequal';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { initiatePartialRefund } from '../../../../Api/Receipt';
import { VariantSelect } from '../../../../Components/VariantSelect/VariantSelect';
import { useGetEvent } from '../../../../Hooks/events/useGetEvent';
import useResponsive from '../../../../Hooks/layout/useResponsive';
import {
  TBooking,
  TCreateBookingPayload,
  useBookings,
} from '../../../../Hooks/useBookings';
import { IExperience } from '../../../../Hooks/useExperience';
import { useReceipt } from '../../../../Hooks/useReceipt';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { DialogHeader } from '../../../../Modals/create-booking/dialog-header';

export const EditBookingDialog = NiceModal.create<{
  booking: TBooking;
  eventId: string;
  experience: IExperience;
}>(({ booking, experience }) => {
  const { t } = useTranslate('dialogs.editBooking');
  const { isSm } = useResponsive();
  const modal = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const [newGuests, setNewGuests] = useState(booking.items);
  const [sendPayment, setSendPayment] = useState('sendPayment');

  const [shouldRefund, setShouldRefund] = useState('shouldRefund');

  const { event } = useGetEvent(booking.eventId);
  const { receipt } = useReceipt(booking.receiptId);

  const { updateBooking } = useBookings();

  const handleClose = (shouldRemove = true) => {
    if (shouldRemove) {
      modal.remove();
    } else {
      modal.hide();
    }
  };

  // Creating a map of variants and addons to reduce amount of
  // calculations to be done when changing item count
  const experienceItems = useMemo(
    () => getExperienceItemsMap(experience.price.variants),
    [experience.price.variants]
  );

  // Amount that has already been paid
  const amountPaid = useMemo(
    () =>
      (receipt.data?.transactions.reduce(
        (prev, curr) => prev + curr.totalAmountChargedCents,
        0
      ) ?? 0) -
      (receipt.data?.refunds.reduce(
        (prev, curr) => prev + curr.amountRefundedCents,
        0
      ) ?? 0),
    [receipt.data?.refunds, receipt.data?.transactions]
  );

  // New total value of selected items
  const newBookingTotal = useMemo(
    () => calculatePrice(newGuests, experienceItems),
    [newGuests, experienceItems]
  );

  // Selected items have changed
  const hasItemsChanged = useMemo(
    () => !isEqual(booking.items, newGuests),
    [booking.items, newGuests]
  );

  // Total value has changed
  const hasValueChanged = useMemo(
    () => amountPaid !== newBookingTotal,
    [newBookingTotal, amountPaid]
  );

  // If the selected items has changed, but the total value is unchanged,
  // we do not offer the option to send a payment link or issue a refund
  // If the booking has changed we show the send payment link or
  // issue refund radio buttons
  const hasBookingChanged = useMemo(
    () => hasItemsChanged && hasValueChanged,
    [hasItemsChanged, hasValueChanged]
  );

  // Controls whether or not send payment link or issue refund is shown
  const hasValueIncreased = useMemo(
    () => newBookingTotal > amountPaid,
    [newBookingTotal, amountPaid]
  );

  // Show error if no items are selected, the booking should be cancelled instead
  const hasItems = useMemo(
    () => Boolean(Object.entries(newGuests).length),
    [newGuests]
  );

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      await updateBooking.mutateAsync({
        id: booking.id,
        items: newGuests,
        shouldSendConfirmation: sendPayment === 'sendPayment',
      });

      const amountDiff = (newBookingTotal - amountPaid) * -1;
      const hasPayments = receipt.data?.transactions.some(
        (x) => x.status === 'captured' || x.status === 'refunded-partially'
      );
      if (shouldRefund && hasPayments && booking.receiptId && amountDiff > 0) {
        try {
          await initiatePartialRefund(booking.receiptId, amountDiff);
          receipt.refetch();
        } catch (error) {
          // Throw if not HTTP 400. This is not an error to show a toast for.
          if (!axios.isAxiosError(error) || error.response?.status !== 400) {
            throw error;
          }
        }
      }

      toast.success(t('toast.success'));
      handleClose();
    } catch (error) {
      captureException(error);
      toast.error(t('toast.error'));
    } finally {
      setIsLoading(false);
    }
  };

  const bookingGuestCount = useMemo(
    () =>
      Object.entries(booking.items).reduce((total, [itemId, amount]) => {
        if (itemId.includes('variant')) {
          return total + amount;
        }
        return total;
      }, 0),
    [booking.items]
  );

  if (!event.data) return null;

  const maxAvailableSeats =
    event.data.slots.total - (event.data.slots.booked ?? 0) + bookingGuestCount;

  return (
    <Dialog
      sx={{ justifyContent: 'flex-end' }}
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={modal.visible}
      onClose={() => handleClose(true)}
    >
      <DialogHeader title={t('title')} handleClose={handleClose} />
      <Stack paddingX={isSm ? 2 : 4} gap={2}>
        <VariantSelect
          defaultValue={newGuests}
          experienceId={experience.id}
          maxGuestCount={maxAvailableSeats}
          onChange={setNewGuests}
          errorMessage={!hasItems ? t('noItemsError') : undefined}
          required
        />
        {hasBookingChanged &&
          hasItems &&
          (hasValueIncreased ? (
            <FormControl>
              <RadioGroup
                value={sendPayment}
                onChange={(_, value) => setSendPayment(value)}
              >
                <FormControlLabel
                  value="sendPayment"
                  label={t('sendPayment')}
                  control={<Radio />}
                />
                <FormControlLabel
                  value="skipPayment"
                  label={t('skipPayment')}
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
          ) : (
            <FormControl>
              <RadioGroup
                value={shouldRefund}
                onChange={(_, value) => setShouldRefund(value)}
              >
                <FormControlLabel
                  value="shouldRefund"
                  label={t('shouldRefund')}
                  control={<Radio />}
                />
                <FormControlLabel
                  value="skipRefund"
                  label={t('skipRefund')}
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
          ))}
      </Stack>
      <Stack
        padding={isSm ? 2 : 4}
        direction="row"
        justifyContent="end"
        gap={1}
      >
        <Button
          variant="primary"
          size="large"
          type="submit"
          disabled={isLoading || !hasItems}
          onClick={handleSubmit}
          rightIcon={
            isLoading && <CircularProgress sx={{ marginLeft: 1 }} size="1em" />
          }
        >
          {t('buttonLabel')}
        </Button>
      </Stack>
    </Dialog>
  );
});

const getExperienceItemsMap = (variants: Experience['price']['variants']) => {
  return variants.reduce(
    (items, variant) => {
      const addons = variant.addons?.reduce(
        (addonItems, addon) => {
          return { ...addonItems, [addon.id]: addon };
        },
        {} as Record<string, ExperienceVariantAddon>
      );

      return {
        ...items,
        [variant.id]: variant,
        ...addons,
      };
    },
    {} as Record<string, ExperienceVariant | ExperienceVariantAddon>
  );
};

const calculatePrice = (
  items: TCreateBookingPayload['items'],
  variantItems: Record<string, ExperienceVariant | ExperienceVariantAddon>
) => {
  return Object.entries(items).reduce((total, [id, quantity]) => {
    const item = variantItems[id.split('/')[1]];
    const itemPrice = item?.priceBreakdown.vatInclusivePriceCents ?? 0;
    const itemTotal = itemPrice * quantity;

    return (total += itemTotal);
  }, 0);
};
