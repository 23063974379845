import styled from '@emotion/styled';
import { lightTheme, Text } from '@holdbar-com/pixel';
import { ExperiencesGetWaitlistResponse } from '@holdbar-com/utils-types';
import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { Divider, Grid, Stack } from '@mui/material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';
import { Link } from 'react-router-dom';

import { CopyLink } from '../../../Components/CopyLink/CopyLink';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useExperienceWaitlist } from '../../../Hooks/useExperienceWaitlist';
import { ExperienceDetailsCard } from './experience-details-card';
import { WaitlistAction } from './waitlist/waitlist-action';

export type ExperienceDetailsItem = {
  key: string;
  label: string;
  value: string;
  link?: string;
  onClickTrackingFn?: () => void;
  onCopyTrackingFn?: () => void;
}[];

type ExperienceDEtailsProps = {
  experienceDetails: ExperienceDetailsItem;
  experienceOtherDetails: ExperienceDetailsItem;
  experienceId: string;
};

export const ExperienceDetails = ({
  experienceDetails,
  experienceOtherDetails,
  experienceId,
}: ExperienceDEtailsProps) => {
  const { t } = useTranslation();
  const { isSm, isMd } = useResponsive();
  const [open, setOpen] = useState(false);

  const { waitlist } = useExperienceWaitlist(experienceId);

  return (
    <Stack>
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 1,
          display: { sm: 'none' },
          paddingY: 1,
        }}
        onClick={() => setOpen((prev) => !prev)}
      >
        <Text variant="medium" fontSize="small">
          {t('experience.details.section.label.details')}
        </Text>
        {open ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
      </Stack>
      {(!isSm || open) && (
        <Grid
          container
          columns={isMd ? 1 : 2}
          columnSpacing={2}
          rowSpacing={1.5}
        >
          <DetailsGrid
            label={t('experience.details.section.label.details')}
            items={experienceDetails}
            waitlist={waitlist}
          />
          <DetailsGrid
            label={t('experience.details.section.label.otherDetails')}
            items={experienceOtherDetails}
          />
        </Grid>
      )}
      <Divider sx={{ display: { sm: 'none', marginTop: 8 } }} />
    </Stack>
  );
};

const DetailsGrid = ({
  label,
  items,
  waitlist,
}: {
  label: string;
  items: ExperienceDetailsItem;
  waitlist?: UseQueryResult<ExperiencesGetWaitlistResponse>;
}) => {
  const { t } = useTranslation();
  const { isSm } = useResponsive();

  return (
    <Grid item xs={1}>
      <ExperienceDetailsCard showDivider>
        {!isSm && (
          <Text variant="medium" fontSize="small">
            {label}
          </Text>
        )}
        <Grid container columns={isSm ? 3 : 2} spacing={1.5}>
          {items.map(
            ({
              key,
              label,
              value,
              link,
              onClickTrackingFn,
              onCopyTrackingFn,
            }) => (
              <Fragment key={key}>
                <Grid item xs={1}>
                  <Text fontSize="xsmall">{label}</Text>
                </Grid>
                <Grid item xs={2} sm={1}>
                  <Stack direction="row" gap={1} alignItems="top">
                    <Text
                      fontSize="xsmall"
                      variant="medium"
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                      }}
                      title={value}
                    >
                      {link ? (
                        <CopyLink
                          href={link}
                          label={value}
                          onClickText={() => onClickTrackingFn?.()}
                          onClickCopy={() => onCopyTrackingFn?.()}
                        />
                      ) : (
                        value
                      )}
                    </Text>
                    {(key === 'owner' || key === 'distributor') && (
                      <StyledLink
                        to="/settings/connect"
                        onClick={() => onClickTrackingFn?.()}
                      >
                        {t('experience.details.value.connectLink')}
                      </StyledLink>
                    )}
                    {key === 'guests' && <WaitlistAction waitlist={waitlist} />}
                  </Stack>
                </Grid>
              </Fragment>
            )
          )}
        </Grid>
      </ExperienceDetailsCard>
    </Grid>
  );
};

const StyledLink = styled(Link)({
  fontSize: '12px',
  color: lightTheme.palette.action.a200,
  textDecoration: 'underline',
  whiteSpace: 'nowrap',
});
