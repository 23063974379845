import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { AddRounded } from '@mui/icons-material';
import { Box, Dialog, Stack, Typography } from '@mui/material';
import { Button } from '@holdbar-com/pixel';
import { useState } from 'react';

import { IInvitation } from '../Api/Invitation';
import { UserItem } from '../Components/UserItem/UserItem';
import { useRoles } from '../Hooks/data/useRoles';
import { useTranslate } from '../Hooks/useTranslate';
import { SendIcon } from '../Icons';
import { validEmail } from '../Utils/helpers';
import { Header } from './Header';
import { InputDialog } from './InputDialog';

export const InviteUsersDialog = NiceModal.create(() => {
  const { t } = useTranslate('dialogs.inviteUser');

  const modal = useModal();

  const handleClose = () => {
    modal.reject('InviteUsersDialog');
    modal.hide();
  };

  const [users, setUsers] = useState<IInvitation[]>([]);
  const { roles } = useRoles();

  const handleDelete = (index: number) => () =>
    setUsers((p) => p.filter((_, i) => i !== index));
  const handleUpdate = (index: number) => (key: 'email' | 'role', value: any) =>
    setUsers((p) =>
      p.map((el, i) => {
        return i === index
          ? { ...el, claims: { ...el.claims, [key]: value } }
          : el;
      })
    );

  const handleSubmit = () => {
    modal.resolve(users);
    modal.hide();
  };

  const handleCreate = async () => {
    try {
      const { email, role, name } = await NiceModal.show<{
        email: string;
        role: string;
        name: string;
      }>(InputDialog, {
        title: t('createUserDialogTitle'),
        inputs: [
          {
            key: 'name',
            name: t('name', 'utils.generic'),
            props: {
              fullWidth: true,
            },
            rules: {
              required: true,
            },
          },
          {
            key: 'email',
            name: t('email', 'utils.generic'),
            props: {
              fullWidth: true,
              helperText: t('emailHelpText'),
            },
            rules: {
              required: true,
              validate: validEmail,
            },
          },
          {
            key: 'role',
            name: t('role', 'utils.generic'),
            props: {
              fullWidth: true,
              options: roles.data!.map(({ name, id }) => ({
                label: name,
                key: id,
              })),
              type: 'select',
              helperText: t('roleHelpText'),
            },
            rules: {
              required: true,
            },
          },
        ],
      });

      setUsers((p) => [
        ...p,
        {
          shouldNotify: true,
          claims: {
            email,
            role,
            name,
          },
        },
      ]);
    } catch (err) {
      return;
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      open={modal.visible}
      onClose={handleClose}
    >
      <Box
        sx={{
          fontSize: { xs: '12px', md: '16px' },
          display: 'block',
          width: '100%',
          height: '100%',
          py: 4,
          px: { xs: 3, md: 4 },
        }}
      >
        <Header title={t('title')} onClose={handleClose} />
        <Typography mb={2} mt={3} fontWeight={'600'}>
          {t('description')}
        </Typography>
        <Stack pl={0} pr={11} pb={4} spacing={2}>
          {users.map((el, i) => {
            return (
              <UserItem
                name={el.claims.name ?? ''}
                email={el.claims.email}
                role={el.claims.role}
                id={`user-item-${i}`}
                onUpdate={handleUpdate(i)}
                onDelete={handleDelete(i)}
              />
            );
          })}
          <Stack spacing={4} alignItems={'start'} mt={2}>
            <Button
              size="small"
              variant="secondary"
              disabled={roles.isLoading}
              leftIcon={<AddRounded fontSize="small" sx={{ mr: 1 }} />}
              onClick={handleCreate}
            >
              {users.length > 0
                ? t('buttons.addOneMoreUser')
                : t('buttons.addUser')}
            </Button>
          </Stack>
        </Stack>

        <Box display={'flex'} justifyContent={'flex-end'} pr={4}>
          <Button
            size="medium"
            variant="primary"
            disabled={users.length === 0}
            leftIcon={<SendIcon fontSize="small" sx={{ mr: 1 }} />}
            onClick={handleSubmit}
          >
            {t('buttons.sendInvitations')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
});
