import { AxiosError } from 'axios';
import { apiServices } from './apiServices';

import api, { authApi } from './index';

export interface IInvitation {
  id?: string;
  shouldNotify: boolean;
  claims: {
    email: string;
    role: string;
    name?: string;
  };
  status?: string;
}

export interface HoldbarApiError extends AxiosError<{ error: string }> {}

export const getInvitations = async (status: string) => {
  if (typeof apiServices.accounts === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }

  const { data } = await api.get<{ items: IInvitation[] }>(
    `${apiServices.accounts}/invitations?status=${status}`
  );

  return data.items;
};

export const getInvitationData = async (invitationId: string) => {
  if (typeof apiServices.accounts === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }

  const { data } = await authApi.get<{
    email: string;
    companyName: string;
    inviterName: string;
    existingUser: boolean;
  }>(`${apiServices.accounts}/invitations/${invitationId}`);

  return data;
};

export const inviteUser = async (invitation: IInvitation) => {
  try {
    if (typeof apiServices.accounts === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
      );
    }

    const { data } = await api.post<IInvitation>(
      `${apiServices.accounts}/invitations`,
      invitation
    );

    return data;
  } catch (error) {
    const apiError = error as HoldbarApiError;
    throw new Error(apiError.response?.data.error);
  }
};

export const update = async (invitation: IInvitation) => {
  try {
    if (typeof apiServices.accounts === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
      );
    }

    const { data } = await api.put<IInvitation>(
      `${apiServices.accounts}/invitations/${invitation.id}`,
      invitation
    );

    return data;
  } catch (error) {
    const apiError = error as HoldbarApiError;
    throw new Error(apiError.response?.data.error);
  }
};

export const revoke = async (
  invitationId: string
): Promise<{ error: boolean; code?: string }> => {
  try {
    if (typeof apiServices.accounts === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
      );
    }

    await api.delete<IInvitation>(
      `${apiServices.accounts}/invitations/${invitationId}`
    );

    return {
      error: false,
    };
  } catch (error) {
    const apiError = error as HoldbarApiError;
    return {
      error: true,
      code: apiError.response?.data.error,
    };
  }
};
