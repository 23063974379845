import styled from '@emotion/styled';
import { Button, Text } from '@holdbar-com/pixel';
import { Box, CircularProgress, Stack } from '@mui/material';

import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { Opportunity } from '../../domain/types';
import { useGrowthOpportunities } from '../../domain/use-growth-opportunities';

type GrowthOpportunitiesSliderItemProps = {
  opportunity: Opportunity;
  secondary?: boolean;
};

export const GrowthOpportunitiesSliderItem = ({
  opportunity,
  secondary,
}: GrowthOpportunitiesSliderItemProps) => {
  const { t } = useTranslate('utils.generic');
  const { isNavigating, readMoreAction } = useGrowthOpportunities();
  const { isSm } = useResponsive();

  return (
    <StyledItem
      isSm={isSm}
      secondary={secondary}
      className={secondary ? 'secondary' : ''}
    >
      <Box component="img" src={opportunity.graphic} />
      <Stack gap={3}>
        <Stack gap={1}>
          <Text variant="medium" fontSize={'medium'}>
            {opportunity.title}
          </Text>
          <Text fontSize={'small'}>{opportunity.subtitle}</Text>
        </Stack>
        <Button
          variant="primary"
          size="medium"
          disabled={isNavigating}
          leftIcon={
            isNavigating && (
              <CircularProgress size={16} color="inherit" sx={{ mr: 1 }} />
            )
          }
          onClick={() => readMoreAction(opportunity)}
        >
          {t('getStarted')}
        </Button>
      </Stack>
    </StyledItem>
  );
};

const StyledItem = styled(Stack)<{ isSm?: boolean; secondary?: boolean }>`
  justify-content: space-between;
  gap: 32px;
  padding: 32px;
  background-color: #ffffff;
  border-radius: 16px;
  width: ${({ isSm, secondary }) =>
    isSm ? '350px' : secondary ? '300px' : '420px'};
  cursor: grab;
  min-height: 400px;

  opacity: ${({ secondary }) => (secondary ? 0.5 : 1)};

  transform: scale(${({ secondary }) => (secondary ? 0.7 : 1)});
  -webkit-transform: scale(${({ secondary }) => (secondary ? 0.7 : 1)});

  transition:
    opacity 0.3s ease,
    transform 0.3s ease,
    box-shadow 0.2s ease,
    width 0.3s ease;

  -webkit-transition:
    opacity 0.3s ease,
    -webkit-transform 0.3s ease,
    box-shadow 0.2s ease;

  box-shadow: ${({ secondary }) =>
    secondary ? 'none' : '0px 0px 24px rgba(20, 20, 20, 0.05)'};
  &:hover {
    box-shadow: ${({ secondary }) =>
      secondary ? 'none' : '0px 4px 24px rgba(20, 20, 20, 0.1)'};
  }
`;
