import { format } from 'date-fns';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import * as api from '../../Api';
import { useProfile } from '../../Hooks/useProfile';

const getDate = (date: Date) => format(date, 'yyyy-MM-dd');

export const useDashboardMetrics = (filters: {
  from: Date;
  to: Date;
  experience: string[];
}) => {
  const { company } = useProfile();

  // Include company id in the metrics query, to force re-query when company changes
  const METRICS_QUERY_KEY = ['metrics', company?.data?.id ?? '-'];

  const metrics = useQuery(
    [
      METRICS_QUERY_KEY,
      filters.experience,
      getDate(filters.from),
      getDate(filters.to),
    ],
    () => {
      return api.getMetrics(
        getDate(filters.from),
        getDate(filters.to),
        filters.experience
      );
    },
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const { graphSeries, categoryTotals } = useMemo(() => {
    return {
      graphSeries: metrics.data?.series,
      categoryTotals: metrics.data?.categoryTotals,
    };
  }, [metrics.data]);

  return {
    isLoading: metrics.isLoading || metrics.isPreviousData,
    isError: metrics.isError,
    graphSeries,
    categoryTotals,
    metadata: metrics.data?.metadata,
  };
};
