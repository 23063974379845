import { matchIsValidTel } from 'mui-tel-input';
import {
  type Control,
  Controller,
  type ControllerProps,
  FieldValues,
} from 'react-hook-form';

import { useTranslate } from '../../Hooks/useTranslate';
import { PhoneInput, PhoneInputProps } from './PhoneInput';

type ControlledPhoneInputProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  'rules' | 'render'
> &
  PhoneInputProps & {
    control: Control<T, unknown>;
  };

function ControlledPhoneInput<T extends FieldValues>({
  name,
  control,
  defaultValue,
  shouldUnregister,
  label,
  ...props
}: ControlledPhoneInputProps<T>) {
  const { t } = useTranslate('utils.generic');
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{
        validate: (value) => {
          if (!value || matchIsValidTel(value)) return true;
          return t('incorrectPhone');
        },
      }}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState: { error } }) => {
        return (
          <PhoneInput
            {...field}
            label={label && t('phone')}
            helperText={error?.message}
            error={Boolean(error)}
            {...props}
          />
        );
      }}
    />
  );
}

export default ControlledPhoneInput;
