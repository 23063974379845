import { IExperience } from '../../../Hooks/useExperience';
import { t } from '../../../i18n/config';
import { trackExperienceActivated } from '../../../tracking/experiences/details/card/trackExperienceActivated';
import { trackExperienceDeactivated } from '../../../tracking/experiences/details/card/trackExperienceDeactivated';
import { trackExperienceEventCreationStarted } from '../../../tracking/experiences/details/card/trackExperienceEventCreationStarted';
import { TEvent } from '../../../Utils/eventHelpers';
import { isSharedExperience } from '../utils/is-shared-experience';

interface ExperienceActionBase {
  key: string;
  label: string;
  variant: 'primary' | 'secondary';
  textColor?: string;
  scopes: string[];
  trackingFn?: () => void;
  'data-intercom-target'?: string;
}

interface ExperienceActionButton extends ExperienceActionBase {
  type: 'button';
  method?: 'post' | 'get';
  action?: string;
}

export interface ExperienceActionLink extends ExperienceActionBase {
  type: 'link';
  to: string;
}

export type ExperienceAction = ExperienceActionButton | ExperienceActionLink;

export const getExperienceActions = (
  experience: IExperience,
  events: TEvent[],
  query: string
) => {
  const actions: ExperienceAction[] = [];

  const isShared = isSharedExperience(experience);

  if (experience.status === 'active') {
    actions.push({
      key: 'deactivate',
      label: t('experience.details.action.deactivateLabel'),
      type: 'button',
      variant: 'secondary',
      textColor: 'red',
      method: 'post',
      action: `update-status${query}`,
      scopes: ['experience.write'],
      trackingFn: () => trackExperienceDeactivated(events, experience),
    });
  }

  if (experience.status === 'inactive') {
    actions.push({
      key: 'activate',
      label: t('experience.details.action.activateLabel'),
      type: 'button',
      variant: 'secondary',
      method: 'post',
      action: `update-status${query}`,
      scopes: ['experience.write'],
      trackingFn: () => trackExperienceActivated(events, experience),
    });
  }

  if (!isShared) {
    actions.push({
      key: 'edit',
      label: t('experience.details.action.editLabel'),
      type: 'link',
      variant: 'secondary',
      to: `edit`,
      scopes: ['experience.write'],
    });
  }

  if (!isShared) {
    actions.push({
      key: 'createEvent',
      label: t('experience.details.action.createEventLabel'),
      type: 'link',
      variant: 'primary',
      to: `/event/create`,
      scopes: ['event.write'],
      trackingFn: () => trackExperienceEventCreationStarted(events, experience),
      'data-intercom-target': 'experience-create-new-event',
    });
  }

  return actions;
};
